import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import "../styles/scss/Home.scss";
import AOS from 'aos';
AOS.init({
  duration: 1000,
  offset: 3000,
});

class Home extends Component {
  render() {
    return (
      <div className="Home">
        {/*<div className="container-fluid presale-timer-mobile flex-row justify-content-center align-items-center text-center">
        <Link to="/season1">
            <h2 className="text-light mb-0">BUY NEW LIMITED CAR PARTS</h2>
            <p className="mr-1">From Season 1 Crates!</p>
          </Link>
          </div>*/}
        <header className="section-header main container-fluid text-light">
          <div className="container top-h-size d-flex flex-column">
          <div className="main-logo ml-auto w-100">
              <img src={require("../images/home/br-logo.png")} className="img-fluid" alt=""/>
          </div>
          <div className="d-flex flex-column justify-content-center flex-wrap mt-auto mb-5">
              <div className="col-md-12">
                <div className="title text-center">
                  {/*<h2>Join the</h2>
                  <h1>WEEKLY DUELS</h1>*/}
                  <h1 className="mb-3">Engines off for Battle Racers</h1>
                  <a href="https://medium.com/battle-racers/announcing-the-closure-of-battle-racers-59f17b39a5ee" target="_blank" className="ml-auto mr-auto" rel="noopener noreferrer">
                    <div className="btn-cta-mid flex-row mb-2">
                        <h3>Read the announcement here.</h3>
                    </div>
                  </a>
                </div>
                {/*<div className="d-flex flex-row align-items-center flex-wrap">
                  <a href="https://opensea.io/collection/battle-racers" target="_blank" className="mr-3" rel="noopener noreferrer">
                    <div className="btn-cta-mid d-flex flex-row mb-2">
                    <div>
                      <img src={require("../images/ui/icon-opensea.png")} className="img-fluid mr-2" alt=""/>
                    </div>
                      <div>
                        <h4>Get car parts on</h4>
                        <h3>OPENSEA</h3>
                      </div>
                    </div>
                  </a>
                  <a href="https://arkane.market/search?contractName=Battle%20Racers" target="_blank" rel="noopener noreferrer" >
                    <div className="btn-cta-mid d-flex flex-row mb-2">
                      <div>
                      <img src={require("../images/ui/icon-venly.png")} className="img-fluid mr-2" alt=""/>
                    </div>
                      <div>
                        <h4>Get car parts on</h4>
                        <h3>VENLY</h3>
                      </div>
                    </div>
                  </a>
                </div>*/}
              </div>
              {/*
              <div className="col-md-12 d-flex mb-1">
              <div className="section-header-newsletter row mb-2">
                  <div className="col-8 d-flex flex-column justify-content-center field">
                    <p className="title mb-1"><strong>Sign Up for our Newsletter!</strong></p>
                    <p className="mb-3">Be the first to know about our latest announcements, contests, and more.</p>
                    <div className="d-flex">
                      <form action="https://altitude-games.us3.list-manage.com/subscribe/post?u=f537c1101cc3881c1b591d834&amp;id=be8c3e7bed" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" rel="noopener noreferrer" noValidate>
                        <div className="form-row d-flex">
                          <input type="email" name="EMAIL" className="email mr-2" id="mce-EMAIL" placeholder=" Enter e-mail address..." required />
                            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                              <input type="email" name="EMAIL" className="email mr-2" id="mce-EMAIL" placeholder=" Enter e-mail address..." required />
                            </div>
                            <div className="clear sub-button">
                              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="text-center btn btn-primary"/>
                            </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-3 discord-link pulse field">
                    <a href="https://discord.gg/gfEPSra">
                      <span className="fab fa-discord"></span>
                      <p className="h-blue"><strong>Join our Discord Channel!</strong></p>
                    </a>
                  </div>
                </div>
              </div>
          </div>
              </div>
          {/*<div className="container top-v-size">
            <div className="d-flex flex-column h-100">
              <div className="d-flex flex-row align-items-center align-content-center h-100 pt-5">
                <div>
                  <div className="h-100">
                    <img src={require("../images/bg/bg-front-car.png")} alt="" className="img-fluid"/>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-1">
                <div className="main-logo p--5">
                  <img src={require("../images/home/br-logo.png")} className="img-fluid" alt=""/>
                </div>
                <div className="section-header-newsletter row">
                  <div className="col-9 d-flex flex-column justify-content-center field">
                    <h4>Sign Up for our Newsletter!</h4>
                    <p>Be the first to know about our latest announcements, contests, and more.</p>
                    <div className="d-flex">
                      <form action="https://altitude-games.us3.list-manage.com/subscribe/post?u=f537c1101cc3881c1b591d834&amp;id=be8c3e7bed" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" rel="noopener noreferrer" noValidate>
                        <div className="form-row d-flex">
                          <input type="email" name="EMAIL" className="email mr-2" id="mce-EMAIL" placeholder=" Enter e-mail address..." required />
                            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_f537c1101cc3881c1b591d834_be8c3e7bed" tabIndex="-1" /></div>
                            <div className="clear sub-button"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="text-center btn btn-primary"/></div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-3 discord-link pulse field">
                    <a href="https://discord.gg/gfEPSra">
                      <span className="fab fa-discord discord-button"></span>
                      <h4 className="h-blue text-center">Join our Discord Channel!</h4>
                    </a>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
        </header>

        <section className="section-founders container-fluid ptpb-100px" id="devroadmap">
          <div className="container module faq-content">
          <div className="h-diamond text-center d-flex align-content-center mb-5 mt-6"><h2 className="text-center mb-5">DEVELOPMENT TIMELINE</h2></div>
          <table classname="roadmap w-100">
          <tr>
            <td className="year"><h3>2018</h3></td>
            <td className="pb-5">
                <span>• Initial Game Prototype</span>
                <span>• Announcement of Partnership with Decentraland</span>
            </td>
          </tr>
          <tr>
            <td className="year"><h3>2019</h3></td>
            <td className="pb-5">
                <span>• Minimum Viable Product (MVP) Completed</span>
                <span>• Website and Discord Launch</span>
                <span>• Team and Advisory Board Introductions</span>
                <span>• Smart Contract Development for Parts and Crates</span>
                <span>• Item Pre-sale: Prime Edition</span>
                <span>• Closed Multiplayer Beta</span>
                <span>• Cross-Game Promotions</span>
                <span>• Loom Network to Ethereum Network Bridge</span>
                <span>• Parts Trading on Ethereum via OpenSea</span>
                <span>• Standalone Web Version Early Access</span>
                <span>• Item Sale: Season 1</span>
                <span>• Migration from Loom Network to Polygon</span>
            </td>
          </tr>
          <tr>
            <td className="year"><h3>2020</h3></td>
            <td className="pb-5">
              <span>• Decentraland Public Launch</span>
              <span>• Web Support for Venly Wallet</span>
              <span>• Web Garage Prototype</span>
              <span>• Web / Decentraland Updates</span>
              <span>• Polygon to Ethereum Network </span>
              <span>• Smart Contract Development for SCRAP</span>
              <span>• Competitive Races Prototype</span>
              <span>• Mobile Version Prototype</span>
              <span>• Tournaments</span>
            </td>
          </tr>
          <tr>
            <td className="year"><h3>2021</h3></td>
            <td className="pb-5">
              <span>• Founded Playcheck Games</span>
              <span>• Initial SCRAP Airdrop</span>
              <span>• Parts Trading on Polygon via OpenSea and Venly Market</span>
              <span>• Web / Decentraland Updates</span>
              <span>• Web Support for Metaverse Chat</span>
              <span>• Web Inventory Improvements</span>
              <span>• Web Garage Development</span>
              <span>• Mobile Alpha Development</span>
              <span>• Smart Contract Redeployment for SCRAP</span>
              <span>• API Development for SCRAP</span>
              <span>• API Development for Parts Upgrading</span>
              <span>• Special Edition NFT Drops</span>
              <span>• Tournaments</span>
            </td>
          </tr>
          <tr>
            <td className="year"><h3>2022</h3></td>
            <td className="pb-5">
                <span>• Project Shutdown</span>
                <span>• Smart Contract Redeployment for Parts (Decentralized Metadata)</span>
            </td>
          </tr>
          </table>
          </div>
        </section>



        <section className="section-carousel mt-5">
          <div className="carousel">
            <div className="carousel-wip" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
              <img src={require("../images/home/wip-1.png")} alt=""/>
              <img src={require("../images/home/wip-2.png")} alt=""/>
              <img src={require("../images/home/wip-3.png")} alt=""/>
              <img src={require("../images/home/wip-4.png")} alt=""/>
              <img src={require("../images/home/wip-5.png")} alt=""/>
              <img src={require("../images/home/wip-6.png")} alt=""/>
              <img src={require("../images/home/wip-1.png")} alt=""/>
              <img src={require("../images/home/wip-2.png")} alt=""/>
              <img src={require("../images/home/wip-3.png")} alt=""/>
              <img src={require("../images/home/wip-4.png")} alt=""/>
              <img src={require("../images/home/wip-5.png")} alt=""/>
              <img src={require("../images/home/wip-6.png")} alt=""/>
            </div>
          </div>
          {/*<h1>Works in Progress</h1>*/}
        </section>

      </div>
    );
  }
}

export default Home;
