import React, { Component } from 'react'
import '../styles/scss/GatewayTest.scss'

import Web3 from 'web3'
import axios from 'axios'

import withWeb3Alert from './Web3Alert'
import PartJson from '../contracts/Part.json'

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost'
const ETH_SYSTEM_ACCOUNT = process.env.REACT_APP_ETH_SYSTEM_ACCOUNT;
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

class GatewayTest extends Component {

  constructor(props) {
    super(props)

    if (window.web3) {
      this.web3js = new Web3(window.web3.currentProvider)
    }
    
    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
    const userAccount = this.props.web3state.metamaskAccount
    // const contractAddress = '0x8B2BD942a4EAe3d54394248599C85D7De768a4d0'
    const contractAddress = '0x04aBD5eA9362A1E1a34C15C4B56BedDCd5e75023'
    this.contract = new this.web3js.eth.Contract(PartJson.abi, contractAddress, {from: userAccount})

    this.state = {
      partsOwned: [],
      totalParts: '',
      ethPartIds: [],
      approvedFlags: [],
      accountMapped: false,
      mappedFrom: '',
      mappedTo: '',
    }
  }

  componentDidMount() {
    this.updateParts()
    this.updateMapping()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevUserAccount = prevProps.web3state.metamaskAccount
    const userAccount = this.props.web3state.metamaskAccount

    if (prevUserAccount !== userAccount) {
      this.updateParts()
      this.updateMapping()
    }
  }

  loomToEthFirstTime = (tokenId) => {
    const userAccount = this.props.web3state.metamaskAccount
    let requestUrl = this.apiUrl + "/gatewayPart"

    const uri = 'https://br-staging.alto.gg/api/items/' + tokenId

    axios.get(requestUrl, {params: {
      tokenId: tokenId
    }})
    .then(async (r) => {
      const sigHex = this.web3js.utils.bytesToHex(r.data.signature.data.slice(1))
     
      // const hash = this.web3js.utils.soliditySha3(tokenId, userAccount, uri)
      // const test = await this.web3js.eth.personal.ecRecover(hash, sigHex)
      
      await this.contract.methods.mintPartSigned(userAccount, tokenId, uri, sigHex)
        .send({from: userAccount});
      })

      this.updateParts()
    }

    ethToLoom = async (tokenId) => {
      const userAccount = this.props.web3state.metamaskAccount

      // !!!Hardcoded
      // rinkeby gateway for extdev-plasma-us1, from
      // from https://loomx.io/developers/en/testnet-plasma.html
      const ethGatewayAddressStr = '0xb73C9506cb7f4139A4D6Ac81DF1e5b6756Fab7A2'
      try {
        await this.contract.methods
          .safeTransferFrom(userAccount, ethGatewayAddressStr, tokenId)
          .send({ from: userAccount })
      } catch (err) {
      }
    }

    updateMapping = async () => {
      // const ethAddress = this.props.web3state.metamaskAccount
      const resp = await axios.get(this.apiUrl + "/getAccountMapping")
      this.setState({
        accountMapped: resp.data.mappingExists,
        mappedFrom: resp.data.from || "",
        mappedTo: resp.data.to || "",
      })
    }

    updateParts = async () => {
      await this.updateOwnedParts()
      await this.updateEthOwnedParts()
    }

    updateOwnedParts = async () => {
      // const userAccount = this.props.web3state.metamaskAccount
      try {
        const resp = await axios.get(this.apiUrl + "/getParts");
        const partsOwned = resp.data.parts;
        this.setState({
          partsOwned: partsOwned,
        })
      } catch (e) {
      }
    }

    updateEthOwnedParts = async () => {
      const userAccount = this.props.web3state.metamaskAccount
      const ethPartsCount = await this.contract.methods.balanceOf(userAccount).call()

      const ethPartIds = []
      const approvedFlags = []
      for (let i = 0; i < ethPartsCount; i++) {
        const partTokenId = await this.contract.methods
          .tokenOfOwnerByIndex(userAccount, i)
          .call()
        ethPartIds.push(partTokenId)

        const approvedAddress = await this.contract.methods
          .getApproved(partTokenId)
          .call()
        const isApproved = approvedAddress === ETH_SYSTEM_ACCOUNT
        approvedFlags.push(isApproved)
      }
      this.setState({
        ethPartIds: ethPartIds,
        approvedFlags: approvedFlags
      })

    }

    addAccountMapping = async () => {
      const ethAddress = this.props.web3state.metamaskAccount

      let requestUrl = this.apiUrl + "/getLoomAddress"
      const resp = await axios.get(requestUrl)
      const loomAddress = resp.data.loomAddress

      const hash = this.web3js.utils.soliditySha3(
        { type: 'address', value: loomAddress.toString().slice(2) },
        { type: 'address', value: ethAddress.toString().slice(2) }
      )
      let sig = ''
      try {
        sig = await this.web3js.eth.sign(hash, ethAddress)
      } catch (err) {
        return
      }


      requestUrl = this.apiUrl + "/addAccountMapping"
      await axios.get(requestUrl, {params: {
        signature: sig
      }})
      .then(async (r) => {
        this.updateParts()
      })
    }

    addContractMapping = async () => {
      let requestUrl = this.apiUrl + "/addContractMapping"
      await axios.get(requestUrl, {params: {}})
    }

    render = () => {
      const { partsOwned, ethPartIds, accountMapped,
        mappedFrom, mappedTo } = this.state

      const loomParts = partsOwned.map(partInfo => (
        <div key={'loom-' + partInfo.id} className="d-flex flex-row">
          <div>{partInfo.name}</div>
          <div>({partInfo.id})</div>
          <button type="button" onClick={() => this.loomToEthFirstTime(partInfo.id)}>Transfer</button>
        </div>
      ))

      const ethParts = ethPartIds.map((ethPartTokenId, idx) => (
        <div key={'eth-' + ethPartTokenId} className="d-flex flex-row">
          <div>TokeId: {ethPartTokenId}</div>
          <button type="button" onClick={() => this.ethToLoom(ethPartTokenId)}>Transfer back</button>
        </div>
      ))

      const parts = (
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            Account mapped: {accountMapped.toString()}
          </div>
          <div className="d-flex flex-row">
            Mapped from: {mappedFrom.toString()}
          </div>
          <div className="d-flex flex-row">
            Mapped to: {mappedTo.toString()}
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex flex-row w-20">
              <button type="button" onClick={() => this.addContractMapping()}>Add contract mapping</button>
            </div>
            <div className="d-flex flex-row w-20">
              <button type="button" disabled={!!accountMapped} onClick={() => this.addAccountMapping()}>Add account mapping</button>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column w-20">
              <div>Loom: </div>
              <div>{loomParts}</div>
            </div>
            <div className="w-50"/>
            <div className="d-flex flex-column w-20">
              <div>Eth: </div>
              <div>{ethParts}</div>
            </div>
          </div>
        </div>
      )

      return (
        <section className="section-header header-main container-fluid text-center">
          <div className="container d-flex flex-column h-100">
            <div className="header-main-headline text-center d-flex flex-column h-100 justify-content-center">
              <div>
                { parts }
              </div>
            </div>
          </div>
        </section>
        )
    }
}

export default withWeb3Alert(GatewayTest);
