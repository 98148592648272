import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import br_logo from '../images/br_logo.png';

export default class Navigation extends Component {

  constructor(props) {
 super(props);
 this.toggleNavbar = this.toggleNavbar.bind(this);
  this.toggle = this.toggle.bind(this);
 this.n = 0;
 this.state = {
 collapsed: true,
 marginVal: '0px'
 };

 }
 toggleNavbar() {
 this.setState({
 collapsed: !this.state.collapsed,
 });
 }
 toggle() {
  setTimeout(() => {
     this.setState({marginVal:'0px'});
    if (this.props.props.location.pathname.indexOf('inventory') > 0 ) {
        if (!this.props.web3state.ready) {
        this.setState({marginVal: '42px'});
      }
    }
  }, 200)

 }
 componentDidMount() {
   this.setState({marginVal:'0px'});
    if (this.props.props.location.pathname.indexOf('inventory') > 0 ) {
        if (!this.props.web3state.ready) {
        this.setState({marginVal: '42px'});
      }
    }
  }
 renderComponent() {
   const collapsed = this.state.collapsed;
   const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
   const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
   var ifInCorrectPath =  (!this.props.web3state.ready && this.props.props.location.pathname.indexOf('inventory') > 0 )
   var marginTop = (ifInCorrectPath && window.web3 && window.web3.currentProvider.isMetaMask)?"42px":"0px";
    return (
      <nav className="Navigation navbar fixed-top navbar-expand-lg navbar-dark bg-br" style={{marginTop: marginTop}}>
        <div className="container p-0">
          <Link className="navbar-brand" to="/"><img src={br_logo} alt=""/></Link>
          <button onClick={this.toggleNavbar} className={`${classTwo}`} type="button" data-toggle="collapse" data-target="#navbarItems" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`${classOne}`} id="navbarItems">
            <ul className="navbar-nav ml-auto text-center flex-wrap">
              {/*<li>
                <Link to="/kitty_challenge"><div className="nav-item d-flex flex-column align-items-center justify-content-center border-blue" onClick={this.toggle}><span className="nav-link text-light">CryptoKitties</span></div></Link>
              </li>
              <li>
                <Link to="/season1"><div className="nav-item d-flex flex-column align-items-center justify-content-center" onClick={this.toggle}><span className="nav-link text-light">Season 1</span></div></Link>
              </li>*/}
              <li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center"><a className="text-light" href="https://medium.com/battle-racers" target="_blank" rel="noopener noreferrer"><span className="nav-link text-light">News</span></a></div>
              </li>
              <li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center"><a className="text-light" href="#devroadmap"><span className="nav-link text-light">Roadmap</span></a></div>
              </li>
              <li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center"><a className="text-light" href="https://opensea.io/collection/battle-racers-polygon" target="_blank" rel="noopener noreferrer"><span className="nav-link text-light">OpenSea Collection</span></a></div>
              </li>
              {/*<li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center"><a className="text-light" href="https://venly.market/search?contractName=Battle%20Racers" target="_blank" rel="noopener noreferrer"><span className="nav-link text-light">Venly Collection</span></a></div>
              </li>*/}
              <li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center"><a className="text-light" href="https://playcheck.gitbook.io/battle-racers/" target="_blank" rel="noopener noreferrer"><span className="nav-link text-light">Dev Notes</span></a></div>
              </li>





            {/*
              <li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center cta"><a className="text-light" href="https://play.battleracers.io/" target="_blank" rel="noopener noreferrer"><span className="nav-link text-light">Play on Web</span></a></div>
              </li>
              <li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center cta"><a className="text-light" href="https://play.decentraland.org/?position=67%2C-21" target="_blank" rel="noopener noreferrer"><span className="nav-link text-light">Play on DCL</span></a></div>
              </li>
              <li>
                <Link to="/inventory"><div className="nav-item d-flex flex-column align-items-center justify-content-center" onClick={this.toggle}><span className="nav-link text-light">Inventory</span></div></Link>
              </li>
              {/*<li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center"><a className="text-light" href="https://play.battleracers.io/" target="_blank" rel="noopener noreferrer"><span className="nav-link text-light">Early Access</span></a></div>
              </li>*/}
              {/*<li>
                <Link to="/gameinfo"><div className="nav-item d-flex flex-column align-items-center justify-content-center" onClick={this.toggle}><span className="nav-link text-light">Game Info</span></div></Link>
              </li>
              <li>
                <div className="nav-item d-flex flex-column align-items-center justify-content-center"><a className="text-light" href="https://link.medium.com/1w1ApeiS4bb" target="_blank" rel="noopener noreferrer"><span className="nav-link text-light">Starter Guide</span></a></div>
              </li>*/}
              {/*<li>
                <Link to="/aboutus"><div className="nav-item d-flex flex-column align-items-center justify-content-center" onClick={this.toggle}><span className="nav-link text-light">About Us</span></div></Link>
              </li>*/}
            </ul>
          </div>
        </div>
      </nav>
    );
 }
 render() {
 return (<div>{this.renderComponent()}</div>);
  }
};
