import React, {Component} from 'react';

import axios from 'axios';

const infuraKey = process.env.INFURA_KEY;
var ETH_NETWORK = process.env.ETH_NETWORK || "localhost";
const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
var address;
var msgParams;
var web3JS = window.web3;
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;


class AlpacaLogin extends Component {
  constructor(props) {
    super(props);
      this.web3LoginAsync = this.web3LoginAsync.bind(this);
      this.handleSignatureAsync = this.handleSignatureAsync.bind(this);
      var  rpcUrl;
      if (ETH_NETWORK == null) ETH_NETWORK = "rinkeby";

    this.state = {
      isLogin: true
    }
    switch (ETH_NETWORK) {
      case "mainnet":
        rpcUrl = "https://mainnet.infura.io/v3/" + infuraKey;
        break;
      case "rinkeby":
        rpcUrl = "https://rinkeby.infura.io/v3/" + infuraKey;
        break;
      case "localhost":
      default:
        rpcUrl = "http://127.0.0.1:8545/";
        break;
    }

     this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
    web3JS.setProvider(new web3JS.providers.HttpProvider(rpcUrl));
    address = window.web3.currentProvider.selectedAddress;
  }
  loginHandler() {

  }
  componentDidMount() {
  }
  async handleSignatureAsync(err, signed) {
     if (!err) {
        let bigAddress = window.web3.utils.toChecksumAddress(window.web3.currentProvider.selectedAddress)
        let result = await axios.post(this.apiUrl + '/login', { address: address, msg:msgParams, signed:signed.result, bigAddress: bigAddress});
        localStorage.token = result.data.server_sig;
        window.location = '/Alpaca/AlpacaUsers/';
      }

  }
  async web3LoginAsync() {

   let bigAddress = window.web3.utils.toChecksumAddress(window.web3.currentProvider.selectedAddress)
   var resp = await axios.get(this.apiUrl + '/isAlpaca', {params: { address: bigAddress}});
   if (resp.data.isAlpaca){
     var response = await axios.get(this.apiUrl + '/getHash', {params:{address: bigAddress}});
      address = web3JS.currentProvider.selectedAddress;
      var network = await window.web3.eth.net.getNetworkType();
      msgParams = JSON.stringify({
                    types: {
                            EIP712Domain:[
                              {name:"name",type:"string"},
                              {name:"network",type:"string"}
                            ],
                            Message: [{name:'message', type: 'string'},
                                      {name:'expiration', type: 'string'},
                                      {name:'secret', type: 'string'}]
                            },
                    primaryType: 'Message',
                    domain:{name:"Battle Racers",network:network},

                    message:{message: response.data.message,
                            expiration: response.data.date,
                            secret: response.data.hash}});
     var from = address;
     var params = [from, msgParams]
     var method = 'eth_signTypedData_v3'
    await window.web3.currentProvider.sendAsync({
    method,
    params,
    from,
  }, this.handleSignatureAsync);
       // await axios.post(this.apiUrl + '/newAlpaca', { address: bigAddress});

     } else {
      alert('not Alpaca admin');
     }


  }
  render() {
    return (<div className='Alpaca-Login-Wrapper'>
      <span>please, login.</span>
      <button onClick={this.web3LoginAsync}>Login</button>
    </div>)
  }
}

export default (AlpacaLogin);
