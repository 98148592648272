import React, {Component} from 'react'
import { Link } from "react-router-dom";

import '@fortawesome/fontawesome-free/css/all.css';

export default class Profile extends Component {
  render() {
    // if (this.state.noParts) {
    //   return (
    //     <div className="Inventory footer-height-f mb-5">
    //
    //     </div>
    //   );
    // }
    //
    // else if  {
    //   return (
    //     <div className="Inventory footer-height-f mb-5">
    //       <div>
    //         <Loader />
    //       </div>
    //
    //     </div>
    //
    //     );
    // }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <div className="Inventory">
        <section className="container main-inventory footer-height-f">
        <div className="inventory-parts-end-notif mb-3">
          <span className="small"><strong>NOTE:</strong> We are still fixing an issue that limits parts displayed to a maximum of 100. We will resolve this ASAP; in the meantime, you can still open crates and use the Total Parts counter to confirm you received the new parts.</span>
        </div>
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-center mb-5">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true"><i className="fas fa-chevron-left"></i></span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
            <li className="page-item"><Link className="page-link" to="#">1</Link></li>
            <li className="page-item"><Link className="page-link" to="#">2</Link></li>
            <li className="page-item"><Link className="page-link" to="#">3</Link></li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true"><i className="fas fa-chevron-right"></i></span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
        </section>
      {/* ------ Pagination Nav ---------*/}

        {/*  ----------- START LOAD MORE BUTTON -----------
        <div className="d-flex flex-column align-items-center justify-content-center">
          <button className="button-common button-small mb-5 mt-5 londrina-fix text-light">Load More</button>
        </div>
        {/* ------------- END LOAD MORE BUTTON -------------*/}
        <button className="back-to-top" type="button" />
      </div>
    );
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}
