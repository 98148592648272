import * as sigUtil from "eth-sig-util";
import axios from 'axios';

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || '';
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';

let API_URL = window.location.protocol + "//" + SERVER_HOST
if (SERVER_PORT) {
  API_URL = API_URL + ":" + SERVER_PORT.toString() + "/api";
} else {
  API_URL = API_URL + "/api";
}

const domainType = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "verifyingContract", type: "address" },
  { name: "salt", type: "bytes32" }
];

const metaTransactionType = [
  { name: "nonce", type: "uint256" },
  { name: "from", type: "address" },
  { name: "functionSignature", type: "bytes" }
];

async function postAgnosticTransaction(url, tx, nextFunc){
  await axios.post(url, tx)
    .then(resp => {
      console.log(`POST ${url} meta tx success`, resp);
      if(!!nextFunc) nextFunc(resp);
    }).catch(err => {
      console.log(`POST ${url} meta tx failed ${err}`);
      if(!!nextFunc) nextFunc({ data: { result: "", error: err }});
    });
}

export async function transactToSideChainPart(tx, nextFunc){
  await postAgnosticTransaction(`${API_URL}/sidechain-part/exec-tx`, tx, nextFunc);
}

export async function transactToSideChainBridge(tx, nextFunc, tokenId){
  await postAgnosticTransaction(`${API_URL}/sidechain-bridge/exec-tx/${tokenId}`, tx, nextFunc);
}

const nonceMap = {};

export async function executeMetaTx(web3, account, tokenAddress, tokenName, tokenVersion, functionSig, txSender, nextCall, errorCall = null) {
  //nonces are only obtainable on other side of the chain
  //const nonce = await axios.get(`${API_URL}/sidechain-part/get-nonces`);

  let data = await web3.eth.abi.encodeFunctionCall({
    name: 'getNonce', 
    type: 'function', 
    inputs: [{
        "name": "user",
        "type": "address"
      }]
  }, [ account ]);

  const nonce = await web3.eth.call ({
    to: tokenAddress,
    data
  });

  const nonceKey = `${tokenAddress}/${account}`;
  if(nonceKey in nonceMap){
    if(nonceMap[nonceKey] === nonce){
      console.log("Denying transaction call until nonce incremented.");
      nextCall({ data: { result: '', error: 'Last transaction not finished.' } });
      return;
    }
  }

  //nonceMap[nonceKey] = nonce;

  data = await web3.eth.abi.encodeFunctionCall({
    name: 'getChainId', 
    type: 'function', 
    inputs: []
  }, []);

  const chainId = await web3.eth.call ({
    to: tokenAddress,
    data
  });
  
  const dataToSign = getTypedData({
    name: tokenName,
    version: tokenVersion,
    salt: "0x" + web3.utils.toHex(chainId).replace("0x", "").padStart(64, "0"),
    verifyingContract: tokenAddress,
    nonce: parseInt(nonce), //parseInt(nonce.data[`eip712_${tokenAddress.toLowerCase()}`]),
    from: account,
    functionSignature: functionSig,
  });

  //console.log(JSON.stringify(dataToSign));

  const msgParams = [ account, JSON.stringify(dataToSign) ];

  await window.web3.currentProvider.sendAsync({
      method: "eth_signTypedData_v3",
      params: msgParams,
      from: account
    }, async function(error, response) {
      if(error || (response && response.error)){
        console.log(`Could not get user signature: ${ !!error ? error : response.error }`);
        if(errorCall){
          errorCall(!!error ? error : response.error);
        }

      } else {
        //console.log(`Obtained signature ${response.result}`);

        const recovered = sigUtil.recoverTypedSignature({
          data: dataToSign,
          sig: response.result
        });

        if(recovered.toLowerCase() === account.toLowerCase()){
          //console.log("Signer verified, continuing transaction.");

          const tx = {
            intent: response.result, 
            fnSig: functionSig, 
            from: account, 
            contractAddress: tokenAddress
          };

          if(txSender){
            await txSender(tx, (resp) => {
              if(resp.data.result !== "" && !resp.data.error){
                //remember last nonce used
                nonceMap[nonceKey] = nonce;
              }

              nextCall(resp);
              
            });
          }
        } else {
          console.log("Signer recovery fail -- ", recovered);
        }
      }
    });
}

export function getSignatureParameters(web3, signature) {
  if (!web3.utils.isHexStrict(signature)) {
    throw new Error(
      'Given value "'.concat(signature, '" is not a valid hex string.')
    );
  }

  const r = signature.slice(0, 66);
  const s = "0x".concat(signature.slice(66, 130));
  let v ="0x".concat(signature.slice(130, 132)); // web3.utils.toDecimal(`0x${signature.slice(130, 132)}`)  + 27;

  v = web3.utils.hexToNumber(v);

  if (![27, 28].includes(v)) v += 27;

  return { r, s, v };
}

function getTypedData({ name, version, salt, verifyingContract, nonce, from, functionSignature }) {
  const domainData = {
    name,
    version,
    verifyingContract,
    salt
  };

  return {
    types: {
      EIP712Domain: domainType,
      MetaTransaction: metaTransactionType
    },
    primaryType: 'MetaTransaction',
    domain: domainData,
    message: {
      nonce,
      from,
      functionSignature
    }
  }
}
