import  {Component} from 'react';
import axios from 'axios';
import Web3 from 'web3'

import PartJson from '../contracts/Part.json'

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
const METADATA_URL = process.env.REACT_APP_METADATA_URL;
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

export default class PendingTransfers extends Component {
  constructor(props) {
    super(props);

    if (window.web3) {
      this.web3js = new Web3(window.web3.currentProvider)
    }

    this.userAccount = this.props.web3state.metamaskAccount
    var netId = this.props.web3state.networkId;
    this.contract = new this.web3js.eth.Contract(PartJson.abi, PartJson.networks[netId] && PartJson.networks[netId].address, {from: this.userAccount})

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }

    this.urlGetPending = this.apiUrl + "/getPendingTransfers";
    this.urlMarkAsReady = this.apiUrl + "/markTokenAsReady";

    this.getPending = this.getPending.bind(this);
  }

  componentDidMount() {
    this.getPending();
  }

  getPending() {
    var url = this.urlGetPending;

    return axios.get(url)
    .then((r) => {

      if (r.data.pendingTransferApprovals) {
        this.handlePendingTransfers(r.data.pendingTransferApprovals);
      }
    })
    .catch((e) => {
    });
  }

  pollPending() {
    var url = this.urlGetPending;


    return axios.get(url)
    .then((r) => {
      var pending = r.data.pendingTransferApprovals;

      if (pending && pending.length) {
        this.handlePendingTransfers(r.data.pendingTransferApprovals);
      }
      else {
        setTimeout(()=>{this.pollPending();}, 3000);
      }
    })
    .catch((e) => {
    });
  }

  markTokenAsReady(tokenId) {
    var url = this.urlMarkAsReady;
    var params = {
      tokenId
    };

    axios.get(url, {params})
    .then(r => {
    })
    .catch(e => {
    });
  }

  async handlePendingTransfers(pendingTransfers) {
    if (!pendingTransfers || pendingTransfers.length === 0) {
      return;
    }

    var userAccount = this.props.web3state.metamaskAccount;

    for (var i = 0; i < pendingTransfers.length; i++) {
      var p = pendingTransfers[i];
      var tokenId = p.id;
      var uri = METADATA_URL + tokenId;

      // check first if token exists
      if(PartJson.networks[this.props.web3state.networkId]){
        try {
          //var owner = await this.contract.methods.ownerOf(tokenId).call();
          this.markTokenAsReady(tokenId);
        }
        catch (e) {
          // initiate minting via signature
          const sigHex = this.web3js.utils.bytesToHex(p.approvalSignature.data.slice(1));
  
          const hash = this.web3js.utils.soliditySha3(tokenId, userAccount, uri);
          var method = 'personal_ecRecover'
          var params = [hash, sigHex]
          //const test = await this.web3js.eth.personal.ecRecover(hash, sigHex);
         
          await window.web3.currentProvider.sendAsync({
            method,
            params,
            userAccount,
          }, function (err, result) {
            var recovered = result.result
            console.log('ec recover called back:')
            if (err) return console.error(err)
            if (result.error) return console.error(result.error)
  
  
            if (recovered === userAccount ) {
              console.log('Successfully ecRecovered signer as ' + userAccount)
            } else {
              console.log('Failed to verify signer when comparing ' + result + ' to ' + userAccount)
            }
  
          })
  
          this.contract.methods.mintPartSigned(userAccount, tokenId, uri, sigHex)
          .send({from: userAccount})
          .on('transactionHash', (txnHash) => {
          });
        }
      }
    }
  }

  render() {
    return '';
  }
}
