import React, { Component } from 'react'
import '../styles/scss/ckitties.scss';
import PanelKittyTrick from './PanelKittyTrick';
// import Loader from './Loader';

export default class SectionKittyTrick extends Component {
  constructor(props) {
    super(props);

    this.onLoadMore = this.onLoadMore.bind(this);
  }

  onLoadMore() {
    this.props.updateKitties();
  }

  renderKitties() {
    var kittyPanels = [];

    this.props.kitties.forEach(k => {
      kittyPanels.push(<PanelKittyTrick key={k.id} kitty={k} onConsumeTrick={this.props.onConsumeTrick} />);
    });

    return (
      <div className="row justify-content-center">
        {kittyPanels}
      </div>
    );
  }

  render() {
    return (
      <section className="container-fluid section-kitty-trick">
        <div className="container">
          <h2 className="text-center">Exchange your Kitty Trick for a free Crate!</h2>
          <p className="mb-5">These crates will also be available through playing CryptoKitties! Breeders can obtain the crate by using a Battle Racers Kitty Trick found in cats with the“Driver” Purrstige trait. A limited amount of crates will be reserved for Driver breeders. Purrstige Traits add unique features to your Kitty’s appearance, while Kitty Tricks are superpowers which let your cats unlock cool hidden features in other dapps, such as Battle Racers. </p>
          <div className="container align-content-center">
          {this.renderKitties()}
          </div>

          <button type="button" className="button-common button-small" disabled={this.props.isLoading} onClick={this.onLoadMore}>
            {this.props.isLoading ? "Loading..." : "Load More Kitties"}
          </button>
        </div>
      </section>
    );
  }
}
