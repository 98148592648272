import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { compose } from 'recompose';
import Navigation from './Navigation';
import Home from './Home';
import GameInfo from './GameInfo';
import AboutUs from './AboutUs';
import Press from './Press';
import Faq from './Faq';
import RoadMap from './Roadmap';
//import CkTest from './CkTest'
//import Presale from './Presale';
import ArkaneTest from './ArkaneTest'
import ScrapTest from './ScrapTest'

// import PresaleTest from './PresaleTest';
import Footer from './Footer';
import CrateActionShowcase from './CrateActionShowcase';
import TransferConfirm from './popups/TransferConfirm';
import BaseAlert from './popups/BaseAlert';
import Profile from './Profile';
import LoginPrompt from './LoginPrompt';
import PromoTemplate from './PromoTemplate';
//import PromoS1 from './PromoS1';
import withWeb3Alert from './Web3Alert';
import withReferralCookie from './ReferralCookie';
import withSaleInfo from './SaleInfo';
import InventoryWrapper from './InventoryWrapper';
import axios from 'axios';
import '../styles/scss/style.scss';
import '../styles/scss/Inventory.scss';
import '../styles/scss/media-queries.scss';
import Web3 from 'web3';
import BaseConfirm from './popups/BaseConfirm';

class Public extends Component {
  constructor(props) {
    super(props);
    this.inventoryRef = React.createRef();
    this.popupShowcase = React.createRef();
    this.popupGeneric = React.createRef();
    this.accountNav = React.createRef();
    this.refPendingTransfers = React.createRef();
    this.refTransferConfirm = React.createRef();
    this.baseConfirm = React.createRef();

    this.initInterceptors();
  }

  initInterceptors = async () => {
    //    const accounts = window.web3 && await window.web3.eth.getAccounts();
    //    const selectedAddress = (accounts && accounts[0]) || null;

    const scope = this
    this.requestInterceptors = axios.interceptors.request.use(request => {
      const { web3state } = scope.props
      const selectedAddress = web3state.metamaskAccount
      if (selectedAddress && localStorage.token !== undefined) {
        if (window.web3) {
          const web3js = new Web3(window.web3.currentProvider)
          request.headers = { Authorization: localStorage.token.split(" ")[0] + " " + web3js.utils.toChecksumAddress(selectedAddress) };
        }
      } else {
        request.headers = { Authorization: undefined }
      }
      return request
    })

    this.responseInterceptors = axios.interceptors.response.use(response => {
      const { web3state } = scope.props
      const selectedAddress = web3state.metamaskAccount

      if (response.data.isLogout && window.location.href.indexOf('login') === -1 && selectedAddress !== null) {
        //window.location = '/login';
        this.props.history.push('/login')
        throw new axios.Cancel('Please, Login.');
      }
      return response
    });
  }
  render() {
    return (
      <div className="Public">
        <Navigation props={this.props} web3state={this.props.web3state} />
        <Route exact path="/" render={props => (<Home web3state={this.props.web3state} saleStarted={this.props.saleStarted} saleStartDate={this.props.saleStartDate} />)} />
        {/*<Route path="/gameinfo" component={GameInfo} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/faq" component={Faq} />
        <Route path="/roadmap" component={RoadMap} />
        <Route path="/press" component={Press} />
        <Route path="/arkane" component={ArkaneTest} />
        <Route path="/promotemplate" component={PromoTemplate} />
        <Route path="/(inventory|parts)" render={props => (

          <InventoryWrapper
            props={this.props}
            web3state={this.props.web3state}
            popupShowcase={this.popupShowcase}
            popupGeneric={this.popupGeneric}
            confirmGeneric={this.baseConfirm}
            accountNav={this.accountNav}
            refPendingTransfers={this.refPendingTransfers}
            refTransferConfirm={this.refTransferConfirm}
            inventoryRef={this.inventoryRef}
            referrer={this.props.referrer}
          />

        )} />

        <Route exact path="/login" render={props => (
          <LoginPrompt
            web3state={this.props.web3state}
            {...props} />
        )} />
        {<Route path="/scrap" render={props => (
          <ScrapTest web3state={this.props.web3state} {...props} />
        )} />}
        <Route path="/profile" component={Profile} />*/}
        <Footer referrer={this.props.referrer} />
        {/*<CrateActionShowcase ref={this.popupShowcase} />
        <BaseAlert ref={this.popupGeneric} />
        <TransferConfirm
          ref={this.refTransferConfirm}
          refPendingTransfers={this.refPendingTransfers}
          web3state={this.props.web3state}
        />
        <BaseConfirm
          ref={this.baseConfirm}
        />*/}
      </div>
    );
  }
}

export default compose(
  withSaleInfo,
  withWeb3Alert,
  withReferralCookie
)(Public);
