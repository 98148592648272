import React, { Component } from 'react'
import '../styles/scss/Loader.scss';

export default class Loader extends Component {
  render() {
    return (
			<div className="Loader d-flex align-items-center justify-content-center p-5">
        <div className="loader"></div>
      </div>
    );
  }
}
