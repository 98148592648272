import React, {Component} from 'react'
// import axios from 'axios';
import ItemInventoryParts from './ItemInventoryParts';
import Loader from './Loader';
import '@fortawesome/fontawesome-free/css/all.css';
const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

// var address;

export default class Inventory extends Component {
  constructor(props) {
    super(props);


    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
    this.canLoadMore = true;

    this.state = {
      partsClaiming: [],
      partsReadyToClaim: [],
      partsAwaitingClaim: [],
      /* partsClaimIds: [], */

      partsOwned: [],
      totalParts: "",
      noParts: false,
      isLoading: false
    }

    this.getPartsOwned = this.getPartsOwned.bind(this);

    this.onScroll = this.onScroll.bind(this);
  }
  componentDidMount() {
    this.props.history.loginReturn = '/inventory'

    this.getPartsOwned();
    // Bind on scroll listener
    window.addEventListener('scroll', this.onScroll);

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var userAccount = this.props.web3state.metamaskAccount;

    if (prevProps.web3state.metamaskAccount !== userAccount) {
      this.getPartsOwned();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  getLastTokenId() {
    var parts = this.state.partsOwned;
    var len = parts.length;

    if (len === 0) {
      return null;
    }

    return parts[len-1].id;
  }

  onScroll(e) {
    var limit = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    ) - window.innerHeight;
    var threshold = 0;

    // try to load if scroll is (almost) at the bottom
    if ((window.scrollY >= (limit - threshold)) && this.canLoadMore) {
      this.getPartsOwned();
    }
  }

  async getPartsOwned() {
    if (this.state.isLoading) {
      return;
    }

    this.setState({isLoading: true});

    var userAccount = this.props.web3state.metamaskAccount;
    if (!userAccount) {
      return;
    }

    try {
      /* var resp = await axios.get(this.apiUrl + "/getParts", {params: {
        chain: this.props.chain,
        address: userAccount,
        lastTokenId: this.getLastTokenId(),
        pageSize: 100
      }}, {withCredentials: true}); */

      await this.props.getParts(this.props.chain, userAccount, this.getLastTokenId(), (resp) => {
        var partsOwned = resp.data.parts;
        // var totalParts = resp.data.total;

        const partsClaiming = this.state.partsClaiming.concat(resp.data.claiming || []);
        const partsReadyToClaim = this.state.partsReadyToClaim.concat(resp.data.readyToClaim || []);
        const partsAwaitingClaim = this.state.partsAwaitingClaim.concat(resp.data.pendingClaim || []);
        /* const partsClaimIds = []; */

        /* [ partsClaiming, partsReadyToClaim, partsAwaitingClaim ].forEach(metadataSet => {
          metadataSet.forEach(metadata => {
            partsClaimIds.push(metadata.id);
          });
        }); */

        this.canLoadMore = partsOwned.length !== 0;

        this.setState({
          partsClaiming,
          partsReadyToClaim,
          partsAwaitingClaim,
          /* partsClaimIds, */
          partsOwned: this.state.partsOwned.concat(partsOwned), //this.state.partsOwned.concat(partsOwned).filter(part => partsClaimIds.indexOf(part.id) < 0), 
          totalParts: resp.data.total, 
          noParts: resp.data.total === 0,
          isLoading: false
        });
      });

    } catch (e) {
      this.setState({isLoading: false});
    } finally {
      
    }
  }

  renderParts() {
    if (this.state.noParts) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center" >
          <h5>You do not have any parts.</h5>
        </div>
      );
    }

    const claiming = this.state.partsClaiming.map((part, index) => <ItemInventoryParts key={index} part={part} status="claiming" />);
    const readyToClaim = this.state.partsReadyToClaim.map((part, index) => <ItemInventoryParts key={claiming.length + index} part={part} status="claim" />);
    const awaitingClaim = this.state.partsAwaitingClaim.map((part, index) => <ItemInventoryParts key={readyToClaim.length + claiming.length + index} part={part} status="queued" />);
    const parts = this.state.partsOwned.map((part, index) => <ItemInventoryParts key={awaitingClaim.length + readyToClaim.length + claiming.length + index} part={part} status="" />);

    /* for (var i = 0; i < this.state.partsOwned.length; i++) {
      parts.push(<ItemInventoryParts key={i} part={this.state.partsOwned[i]} />);
    } */

    return (
      <div className="list-inventory parts d-flex flex-row flex-wrap">
        {claiming}
        {readyToClaim}
        {awaitingClaim}
        {parts}
      </div>
    );
  }

  render() {

    // if (this.state.noParts) {
    //   return (
    //     <div className="Inventory footer-height-f mb-5">
    //
    //     </div>
    //   );
    // }
    //
    // else if  {
    //   return (
    //     <div className="Inventory footer-height-f mb-5">
    //       <div>
    //         <Loader />
    //       </div>
    //
    //     </div>
    //
    //     );
    // }
    return (

      <div className="Inventory mb-5">
        <section className="container main-inventory footer-height-f" >
          {/* ------ START TOTAL PARTS --------*/}
          <span className="mt-3 mb-3">Total Parts: <span className="h-blue">{this.state.totalParts} </span></span>
          {/*<div className="inventory-parts-end-notif mb-3">
            <span className="small"><strong>NOTE:</strong> We are still fixing an issue that limits parts displayed to a maximum of 100. We will resolve this ASAP; in the meantime, you can still open crates and use the Total Parts counter to confirm you received the new parts.</span>
          </div>*/}
          {/* ------ END TOTAL PARTS --------*/}
          {this.renderParts()}
          {(!this.props.web3state.metamaskAccount || this.state.isLoading) ? <Loader /> : ""}

          {/* ------ Pagination Nav ---------*/}
          {/*<nav aria-label="Page navigation">
            <ul className="pagination justify-content-center mb-5">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true"><i className="fas fa-chevron-left"></i></span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              <li className="page-item"><Link className="page-link" to="#">1</Link></li>
              <li className="page-item"><Link className="page-link" to="#">2</Link></li>
              <li className="page-item"><Link className="page-link" to="#">3</Link></li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true"><i className="fas fa-chevron-right"></i></span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>*/}
          {/* ------ End Pagination Nav ---------*/}
        </section>


        {/*  ----------- START LOAD MORE BUTTON -----------
        <div className="d-flex flex-column align-items-center justify-content-center">
          <button className="button-common button-small mb-5 mt-5 londrina-fix text-light">Load More</button>
        </div>
        {/* ------------- END LOAD MORE BUTTON -------------*/}
        <button className="back-to-top" type="button" />
      </div>
    );
  }
}
