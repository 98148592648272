import React, { Component } from 'react'
import '../styles/scss/Press.scss';

export default class Press extends Component {
  render() {
    return (
      <div className="Press">
        <title>Battle Racers - Press</title>
        <section className="section-header header-main container-fluid text-center">
          <div className="container d-flex flex-column justify-content-center align-items-center h-100">
            <div className="header-main-headline text-light text-center d-flex flex-column h-100 justify-content-center">
              <div>
                <h1 className="h-blue">Press</h1>
                <p>For press coverage and interviews, please contact us via @mod on our <a href="https://discord.gg/gfEPSra" target="_blank" rel="noopener noreferrer">Discord</a> channel.</p>
                <p>You can download our press kit <a href="https://drive.google.com/drive/folders/19eHlXpD8u8loMUDP75bDbjtGHVtvmgn7?usp=sharing">here.</a></p>
                <p>Check our <a href="https://medium.com/battle-racers" target="_blank" rel="noopener noreferrer">Medium</a> publication for the latest game news.</p>
              </div>
            </div>
          </div>
        </section>
        <button className="back-to-top" type="button" />
      </div>
    );
  }
}
