import React, { Component } from 'react'
import { Link } from "react-router-dom";

import Loader from './Loader';

export default class ItemShowcase extends Component {
  getRarityClass() {
    if (!("rarity" in this.props.part.details)) {
      return "";
    }

    return this.props.part.details.rarity.toLowerCase();
  }

  render() {
    var metadata = this.props.part;
    var isElite = metadata.details && metadata.details.isElite;
    var renderElite;
    var renderImage;

    if (isElite) {
      renderElite = <div className="text-center d-flex parts-tag elite"><span>ELITE</span></div>;
    }

    if (metadata.image) {
      renderImage = <img className="card-img-top" id="partsImage" src={metadata.image} alt={metadata.name || ""} />
    }
    else {
      renderImage = <Loader />
    }

    return (
			<div className="text-center col-6 col-lg-3">
        <Link to={"/parts/" + metadata.id } className="InventoryPart col-md-6 col-lg-3 p-5px text-deco-none">
          <div className={"card list-inventory-item w-100 p-2 " + this.getRarityClass()} id="">
            <div className="d-flex flex-row flex-wrap">
              <h5 id="partNumber" className="mb-0">{metadata.id}</h5>
              <div className="d-flex flex-row parts-tag-group align-items-center justify-content-center ml-auto">
                {renderElite}
                <div className="text-center d-flex parts-tag prime"><span>{metadata.details.edition}</span></div>
              </div>
            </div>
            {renderImage}
            <div className="card-body text-center d-flex flex-column align-items-center border-top-1">
              <h5 className="card-title font-30px d-inline mb-2">{metadata.name || ""}</h5>
              <div className={"text-center d-flex rarity-tag " + this.getRarityClass() }><span>{metadata.details.rarity}</span></div>
            </div>
          </div>
        </Link>
      </div>
      );
  }
}
