import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';

import Public from './components/Public';
import AlpacaWrapper from './components/alpaca/AlpacaWrapper';
import GatewayTest from './components/GatewayTest';

class App extends Component {
  render() {
    return (
        <Router>
          <Switch>

          {process.env.REACT_APP_ADMIN_ENABLED ? <Route path="/Alpaca" component={AlpacaWrapper} /> : ""}
          {process.env.REACT_APP_ADMIN_ENABLED ? <Route path="/gateway" component={GatewayTest} /> : ""}

          <Route path="/" component={Public} />

          </Switch>
        </Router>
      );
  }
}

export default App;
