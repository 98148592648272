import React, {Component} from 'react';
import axios from 'axios';

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

class AlpacaLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputAddress: '',
      isAlpaca: 'disabled',
      response: '',
      processed: false,
      addBtnStatus: 'disabled',
      removeBtnStatus: 'disabled',
      isChecking: ''
    }
    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
    this.onCheck = this.onCheck.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount() {
  }
  onInputChange(e) {
    this.setState({inputAddress: e.target.value});
  }
  async onAdd() {
    if (!window.web3.utils.checkAddressChecksum(this.state.inputAddress)) {
      alert('invalid address');
      return;
    }
     this.setState({isChecking: 'disabled', addBtnStatus: 'disabled', removeBtnStatus: 'disabled', response: 'proccessing....'});
    let bigAddress = window.web3.utils.toChecksumAddress(this.state.inputAddress)
    await axios.post(this.apiUrl + '/newAlpaca', { address: bigAddress});
    this.setState({addBtnStatus: 'disabled', removeBtnStatus: 'disabled', isChecking: '', response: 'new alpaca admin added'});
  }
  async onRemove() {
    if (!window.web3.utils.checkAddressChecksum(this.state.inputAddress)) {
      alert('invalid address');
      return;
    }
    this.setState({isChecking: 'disabled', addBtnStatus: 'disabled', removeBtnStatus: 'disabled', response: 'proccessing....'});
    let bigAddress = window.web3.utils.toChecksumAddress(this.state.inputAddress)
    await axios.post(this.apiUrl + '/removeAlpaca', { address: bigAddress});
     this.setState({addBtnStatus: 'disabled', removeBtnStatus: 'disabled', isChecking: '', response: 'alpaca admin removed'});
  }
  async onCheck() {
    if (!window.web3.utils.checkAddressChecksum(this.state.inputAddress)) {
      alert('invalid address');
      return;
    }
    this.setState({isChecking: 'disabled', addBtnStatus: 'disabled', removeBtnStatus: 'disabled', response: 'proccessing....'});
    let bigAddress = window.web3.utils.toChecksumAddress(this.state.inputAddress)
    var resp = await axios.get(this.apiUrl + '/isAlpaca', {params: { address: bigAddress}});
    if (resp.data.isAlpaca) {
      this.setState({isAlpaca: '', response: 'this address is an alpaca admin', addBtnStatus: 'disabled', removeBtnStatus: ''});
    } else {
      this.setState({isAlpaca: 'disabled', addBtnStatus: '', removeBtnStatus: 'disabled', response: 'this address is not an alpaca admin', processed: true});
    }
    this.setState({isChecking: ''});
  }
  render() {
    return (<div className='Alpaca-Users-Wrapper'>
      <span> check if alpaca admin</span>
      <input value={this.state.inputAddress} onChange={this.onInputChange} />
      <div className="button-list">
        <button disabled={this.state.isChecking} onClick={this.onCheck}>check</button>
        <button disabled={this.state.addBtnStatus} onClick={this.onAdd}>add</button>
        <button disabled={this.state.removeBtnStatus} onClick={this.onRemove}>remove</button>
      </div>
      <span>{this.state.response}</span>
    </div>)
  }
}

export default (AlpacaLogin);
