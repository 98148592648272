import React, { Component } from 'react';
import Cookies from 'js-cookie';

function withReferralCookie(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        referrer: "0x0000000000000000000000000000000000000000"
      }
    }

    componentDidMount() {
        // check if there's a cookie saved
        this.getAccount();
        console.log('this', this.state.referrer)
        
    }

    async getAccount() {
      const { web3 } = window;
      if (web3 && !web3.__isMetaMaskShim__) {
        let address =  web3.eth && await web3.eth.getAccounts();
        console.log('withReferralCookie address: ', address);
        if (!!address) {
          this.setState({
            referrer: address[0]
          })
        }
      }

      var referrer = new URLSearchParams(window.location.search).get('r');

      if (referrer !== null) {
        Cookies.set('r', referrer, { expires: 365 });
      }

      referrer = Cookies.get('r');

      if (referrer !== undefined) {
        this.setState({
          referrer: referrer
        })
      }
    }
    
    render() {
      return (
          <WrappedComponent referrer={this.state.referrer} {...this.props} />
      );
    }
  }
};

export default withReferralCookie;
