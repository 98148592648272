import React, { Component } from 'react'
import '../styles/scss/FAQ.scss'
import '../styles/scss/ScrapTest.scss'

import Web3 from 'web3'
import axios from 'axios'

import ScrapJson from '../contracts/Scrap.json'
import PartJson from '../contracts/Part.json'
import RaceTokenJson from '../contracts/RaceToken.json'

const ETH_SYSTEM_ACCOUNT = process.env.REACT_APP_ETH_SYSTEM_ACCOUNT
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT || 3006
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost'

const TEST_ORDER_PRICE = 1
const EVENT_PASS_PRICE = 1

const RINKEBY_NET_ID = 4
const MATIC_TESTNET_ID = 80001

const scrapNetwork = MATIC_TESTNET_ID
const partNetwork = MATIC_TESTNET_ID
const raceTokenNetwork = MATIC_TESTNET_ID

const Loader = () => {
  return (
    <div
      className="Loader d-flex align-items-center justify-content-center"
      style={{width: '32px', height: '32px'}}
    >
      <div
        className="loader"
        style={{width: '20px', height: '20px', border: '4px solid #f3f3f3',
          borderTop: '4px solid orange' }}
      />
    </div>
  )
}

class BalanceDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      balance: 0,
      allowance: 0,
      raceTokenBalance: 0,
      raceTokenAllowance: 0
    }
  }

  componentDidMount = () => {
    this.interval = setInterval(this.update, 5000)
  }

  update = async () => {
    const { userAccount, scrapContract, raceTokenContract } = this.props
    if (!userAccount) {
      return
    }

    let balance = 0
    let allowance = 0
    let raceTokenBalance = 0
    let raceTokenAllowance = 0
    try {
      balance = await scrapContract.methods.balanceOf(userAccount).call({
        chainId: scrapNetwork
      })
      allowance = await scrapContract.methods.allowance(userAccount, ETH_SYSTEM_ACCOUNT).call({
        chainId: scrapNetwork
      })
      raceTokenBalance = await raceTokenContract.methods.balanceOf(userAccount).call({
        chainId: scrapNetwork
      })
      raceTokenAllowance = await raceTokenContract.methods.allowance(userAccount, ETH_SYSTEM_ACCOUNT).call({
        chainId: scrapNetwork
      })
    } catch (error) {
      console.error(error)
      return
    }
    this.setState({
      balance,
      allowance,
      raceTokenBalance,
      raceTokenAllowance
    })
  }

  render = () => {
    const { isCorrectScrapNet, isCorrectRaceTokenNet } = this.props
    const { balance, allowance,raceTokenBalance, raceTokenAllowance } = this.state

    const balanceTxt = isCorrectScrapNet ? `${balance} SCRP`: 'Wrong net'
    const allowanceTxt = isCorrectScrapNet ? `${allowance} SCRP`: 'Wrong net'
    const raceTokenBalanceTxt = isCorrectRaceTokenNet ? `${raceTokenBalance} BRRACE`: 'Wrong net'
    const raceTokenAllowanceTxt = isCorrectRaceTokenNet ? `${raceTokenAllowance} BRRACE`: 'Wrong net'

    return (
      <div className="bg balance">
        <div>Scrap Balance: <h5>{balanceTxt}</h5></div>
        <div>Scrap Allowance: <h5>{allowanceTxt}</h5></div>
        <div>Race Token Balance: <h5>{raceTokenBalanceTxt}</h5></div>
        <div>Race Token Allowance: <h5>{raceTokenAllowanceTxt}</h5></div>
      </div>)
  }
}

class Reward extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  onClaim = async () => {
    const { reward, onClaimReward } = this.props
    this.setState({ loading: true })
    await onClaimReward(reward.rewardId)
    this.setState({ loading: false })
  }

  render = () => {
    const { reward } = this.props
    const { loading } = this.state
    return (
      <div className="d-flex flex-row" style={{padding: '10px'}}>
        <div style={{margin: 'auto 10px auto 0px'}}>Amount: {reward.amount}</div>
        { loading && <Loader />}
        { !loading && (<button className="button-common button-smaller" onClick={this.onClaim}>
          Claim
        </button>)}
      </div>
    )
  }
}

class Order extends Component {
  constructor(props) {
    super(props)

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }

    this.state = {
      order: null,
      loading: false,
    }
  }

  componentDidMount = () => {
    // this.interval = setInterval(this.updateOrder, 4000)
    this.interval = setTimeout(this.updateOrder, 500 + 3000 * Math.random())
  }

  updateOrder = async () => {
    /*
    const { order } = this.state
    if (order && (order.status === 'completed' || order.status === 'error')) {
      return
    }
    */
    const { orderId } = this.props
    let requestUrl = this.apiUrl + "/getSingleOrder"
    this.setState({loading: true})
    const response = await axios.get(requestUrl, { params: { orderId }})
    this.setState({
      order: response.data.result,
      loading: false
    })
  }

  render = () => {
    const { order, loading } = this.state
    if (!order) { return loading ? <Loader /> : <div /> }

    return (
    <div className="d-flex flex-row" style={{padding: '10px'}}>
      <div style={{margin: 'auto 10px auto 0px'}}>Price: {order.price}</div>
      <div style={{margin: 'auto 10px auto 0px'}}>Status: {order.status}</div>
      { !loading && (
        <button className="button-common button-smaller" onClick={this.updateOrder}>
          Refresh
        </button>
      )}
      { loading && (<Loader />)}
    </div>)
  }
}

class Part extends Component {
  constructor(props) {
    super(props)

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }

    this.state = {
      upgradeLoading: false,
      dismantleLoading: false,
      level: -1,
      maxLevel: -1,
      rarity: '',
      speed: -1,
      steering: -1,
      upgradePrice: 0,
      dismantleReward: 0
    }

    this.updatePartInfo()
  }

  updatePartInfo = async () => {
    const { partId } = this.props
    const prevLevel = this.state.level
    let requestUrl = this.apiUrl + "/getPartData"
    let response = await axios.get(requestUrl, { params: { partId }})
    const newLevel = response.data.part.level
    this.setState({
      name: response.data.part.name,
      level: newLevel,
      rarity: response.data.part.details.rarity,
      speed: response.data.part.details.speed,
      steering: response.data.part.details.steering,
      upgradePrice: response.data.part.upgradePrice,
      maxLevel: response.data.part.maxLevel,
      dismantleReward: response.data.part.dismantleReward,
    })
    if (prevLevel === newLevel) {
      setTimeout(this.updatePartInfo, 3000)
    } else {
      this.setState({ upgradeLoading: false })
    }
  }

  upgrade = async () => {
    const { partId, onUpgrade } = this.props
    const { level, upgradePrice } = this.state
    this.setState({ upgradeLoading: true })
    const started = await onUpgrade(partId, level + 1, upgradePrice)
    if (started) {
      setTimeout(this.updatePartInfo, 3000)
    } else {
      this.setState({ upgradeLoading: false })
    }
  }

  dismantle = async () => {
    const { partId, onDismantle } = this.props
    this.setState({ dismantleLoading: true })
    await onDismantle(partId)
    this.setState({ dismantleLoading: false })
  }

  render = () => {
    const { upgradeLoading, dismantleLoading, name,
      level, rarity, maxLevel, upgradePrice, dismantleReward,
      speed, steering } = this.state
    const { partId } = this.props
    const isMaxLevel = level >= maxLevel
    return (
      <div className="d-flex flex-column" style={{padding: '10px', width: '300px'}}>
        <div style={{margin: 'auto 10px auto 0px'}}>Id: {partId}</div>
        <div style={{margin: 'auto 10px auto 0px'}}>Name: {name}</div>
        <div style={{margin: 'auto 10px auto 0px'}}>{rarity}</div>
        <div style={{margin: 'auto 10px auto 0px'}}>speed: {speed}</div>
        <div style={{margin: 'auto 10px auto 0px'}}>steering: {steering}</div>
        <div style={{margin: 'auto 10px auto 0px'}}>Level: {level} { isMaxLevel && <span> (Max)</span>}</div>
        <div className="d-flex flex-row">
          { !upgradeLoading && !isMaxLevel && (
            <button className="button-common button-smaller" onClick={this.upgrade}>
              Upgrade ({upgradePrice} SCRP)
            </button>
          )}
          { upgradeLoading && <Loader /> }
          { !dismantleLoading && (
            <button className="button-common button-smaller" onClick={this.dismantle}>
              Dismantle ({dismantleReward} SCRP)
            </button>
          )}
          { dismantleLoading && <Loader /> }
        </div>
      </div>
    )
  }
}

class EventPass extends Component {
  constructor(props) {
    super(props)

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }

    this.state = {
      pass: false,
      count: 0,
      ownLoading: false,
      paying: false,
    }
  }

  componentDidMount = () => {
    // this.interval = setInterval(this.updatePass, 3000)
    this.interval = setTimeout(this.updatePass, 3000)
  }

  updatePass = async () => {
    const { paying } = this.state

    const { eventId, userAccount } = this.props
    let requestUrl = this.apiUrl + "/getEventPass"
    this.setState({ ownLoading: true })
    const response = await axios.get(requestUrl, { params: { eventId, userAccount }})
    this.setState({ ownLoading: false })

    if (!response.data.result) {
      return
    }

    this.setState({
      pass: response.data.result.pass,
      count: response.data.result.count,
      ownLoading: paying && !response.data.result.pass
    })
  }

  onPay = () => {
    const { eventId, onPay } = this.props

    this.setState({ paying: true })
    onPay(eventId)
  }

  onConsume = async () => {
    const { eventId, onConsume } = this.props

    this.setState({ ownLoading: true })
    await onConsume(eventId)
    this.setState({ ownLoading: false })

    await this.updatePass()
  }

  render = () => {
    const { pass, count, ownLoading } = this.state
    const { loading } = this.props

    const showLoading = (loading || ownLoading)

    const canEnterTxt = (pass && count > 0) ? 'Can enter' : `Can't enter`
    return (
    <div className="d-flex flex-row" style={{padding: '10px'}}>
      <div style={{margin: 'auto 10px auto 0px'}}>Enter Price: {EVENT_PASS_PRICE}</div>
      <div style={{margin: 'auto 10px auto 0px'}}>Owned count: {count}</div>
      <div style={{margin: 'auto 10px auto 0px'}}>{canEnterTxt}</div>
      {!showLoading && (
        <button className="button-common button-smaller" onClick={this.onPay}>
          Pay
        </button>
      )}
      {pass && count > 0 && !showLoading && (
        <button className="button-common button-smaller" onClick={this.onConsume}>
          Consume
        </button>
      )}
      {!showLoading && (
        <button className="button-common button-smaller" onClick={this.updatePass}>
          Refresh
        </button>
      )}
      { showLoading && (<Loader />)}
    </div>)
  }
}

const stIdle = 'idle'
const stEntering = 'entereing'
const stEntered = 'entered'
const stConsuming = 'consuming'
const stRacing = 'racing'
class RaceExample extends Component {
  constructor(props) {
    super(props)

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }
    this.orderInterval = null
    this.eventId = 'race-event1'
    this.state = {
      owned: 0,
      raceState: stIdle,
      orderId: null
    }
  }

  componentDidMount() {
    // this.orderInterval = setInterval(this.updateInfo, 1000)
    this.orderInterval = setTimeout(this.updateInfo, 1000)
  }

  updateInfo = async () => {
    const { userAccount } = this.props
    if (!userAccount) {
      return
    }

    const eventId = this.eventId

    let requestUrl = this.apiUrl + "/getEventPass"
    const response = await axios.get(requestUrl, { params: { eventId, userAccount }})

    if (!response.data.result) {
      return
    }
    this.setState({
      owned: response.data.result.count
    })
  }

  onEnter = async () => {
    const { userAccount, raceTokenContract } = this.props
    const { owned } = this.state
    if (!userAccount || !raceTokenContract) {
      return
    }

    this.setState({ raceState: stEntering })

    if (owned === 0) {
      this.buyRacePass()
    } else {
      this.setState({ raceState: stEntered })
    }
  }

  buyRacePass = async () => {
    const { onPay } = this.props

    const result = await onPay(this.eventId)
    if (!result) {
      this.setState({ raceState: stIdle })
    }
    const { orderId } = result.data
    this.setState({ orderId })

    this.orderInterval = setInterval(this.updateOrder, 5000)
  }

  updateOrder = async () => {
    const { orderId } = this.state
    if (!orderId) {
      return
    }

    let requestUrl = this.apiUrl + "/getSingleOrder"
    const response = await axios.get(requestUrl, { params: { orderId }})
    const order = response.data.result
    if (order.status === 'completed') {
      clearInterval(this.orderInterval)
      // update
      await this.updateInfo()
      // and repeat
      this.onEnter()
    } else if (order.status === 'error') {
      clearInterval(this.orderInterval)
      this.setState({ raceState: stIdle })
    } else {
      // keep waiting
    }
  }

  onStartRace = async () => {
    const { onConsume } = this.props
    this.setState({ raceState: stConsuming })
    await onConsume(this.eventId)
    await this.updateInfo()
    this.setState({ raceState: stRacing })
  }

  onEndRace = () => {
    this.setState({ raceState: stIdle })
  }

  render = () => {
    const { owned, raceState } = this.state

    return (
    <div className="d-flex flex-column" style={{padding: '10px'}}>
      <div style={{margin: 'auto 10px auto 0px'}}>Assumption is that the user has already bought some RaceTokens (i.e. RaceExample doesn't prompt to buy them).</div>
      <br></br>
      <div style={{margin: 'auto 10px auto 0px'}}>Tech info (not for user):</div>
      <div style={{margin: 'auto 10px auto 0px'}}>Already payed races: {owned}</div>
      <div className="d-flex flex-row" style={{padding: '10px'}}>
        { raceState === stIdle && (
          <button className="button-common button-smaller" onClick={this.onEnter}>
            Enter race
          </button>
        )}
        { (raceState === stEntering || raceState === stConsuming) && (
          <>
            <Loader />
            { raceState === stEntering && (
              <div style={{margin: 'auto 10px auto 0px', paddingLeft: '1em'}}>Buying a race pass for RaceToken(s)...</div>
            )}
            { raceState === stConsuming && (
              <div style={{margin: 'auto 10px auto 0px', paddingLeft: '1em'}}>Consuming 1 race pass...</div>
            )}
          </>
        )}
        { raceState === stEntered && (
          <>
            <button className="button-common button-smaller" onClick={this.onStartRace}>
              Start race
            </button>
            <div style={{margin: 'auto 10px auto 0px', paddingLeft: '1em'}}>I.e. "consume" 1 race pass.</div>
          </>
        )}
        { raceState === stRacing && (
          <>
            <button className="button-common button-smaller" onClick={this.onEndRace}>
              End race
            </button>
            <div style={{margin: 'auto 10px auto 0px', paddingLeft: '1em'}}>I.e. return to the the default state.</div>
          </>
        )}
      </div>
    </div>)
  }
}

export default class ScrapTest extends Component {

  constructor(props) {
    super(props)

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }
    if (window.web3) {
      this.web3js = new Web3(window.web3.currentProvider)
    }

    //const scrapContractAddr = '0x5bFb3951ff4501ea99861be2db2B8400AbEbeA51' // rinkeby
    //const scrapContractAddr = '0x13821df55975382b99FcE961A34a823445b92Be7' // matic test2
    //const partContractAddr = '0x35526f164eF92bcA4110A9b47B277eBc76a9e630' // matic test2

    const scrapContractAddr = ScrapJson.networks[scrapNetwork].address
    const partContractAddr = PartJson.networks[partNetwork].address  // uncomment if deployed correctly
    //const partContractAddr = '0x35526f164eF92bcA4110A9b47B277eBc76a9e630' // matic test2
    const raceTokenAddress =  RaceTokenJson.networks[raceTokenNetwork].address

    const userAccount = this.props.web3state.metamaskAccount
    this.scrapContract = new this.web3js.eth.Contract(ScrapJson.abi, scrapContractAddr, {from: userAccount})
    this.partContract = new this.web3js.eth.Contract(PartJson.abi, partContractAddr, {from: userAccount})
    this.raceTokenContract = new this.web3js.eth.Contract(RaceTokenJson.abi, raceTokenAddress, {from: userAccount})

    this.state = {
      availableRewards: [],
      userOrders: [],
      userParts: [],
      eventPasses: [{
        eventId: 'race-event1',
        loading: false
      }],
      scrapBalance: 0,
      scrapAllowance: 0,
      rewardsLoading: false,
      raceTokensOrderId: null,
      raceTokensOrderActive: false,
      raceTokensOrderError: false
    }
  }

  componentDidMount() {
    this.props.history.loginReturn = '/scrap'
  }

  isMatic = () => {
    const { networkId } = this.props.web3state
    return networkId === MATIC_TESTNET_ID
  }

  isCorrectScrapNet = () => {
    const { networkId } = this.props.web3state
    return networkId === scrapNetwork
  }

  isCorrectRaceTokenNet = () => {
    const { networkId } = this.props.web3state
    return networkId === raceTokenNetwork
  }

  ensureEth = () => {
    if (this.isMatic()) {
      alert('Switch to eth network')
      return false
    }
    return true
  }

  ensureMatic = () => {
    if (!this.isMatic()) {
      alert('Switch to matic netowrk')
      return false
    }
    return true
  }

  ensureScrapNet = () => {
    if (scrapNetwork === MATIC_TESTNET_ID) {
      return this.ensureMatic()
    } else if (scrapNetwork === RINKEBY_NET_ID) {
      return this.ensureEth()
    }
  }

  ensurePartNetwork = () => {
    if (partNetwork === MATIC_TESTNET_ID) {
      return this.ensureMatic()
    } else if (partNetwork === RINKEBY_NET_ID) {
      return this.ensureEth()
    }
  }

  componentWillReceiveProps = (newProps) => {
    this.updateRewards(newProps)
    this.updateOrders(newProps)
    this.updateParts(newProps)
  }

  onCreateReward = async () => {
    const userAccount = this.props.web3state.metamaskAccount
    let requestUrl = this.apiUrl + "/createReward"

    const requestId = '0'
    const recipient = userAccount
    const amount = 100

    await axios.post(requestUrl, {
      requestId,
      recipient,
      amount,
    })
    this.updateRewards(this.props)
    //const sigHex = this.web3js.utils.bytesToHex(response.data.signature.data.slice(1))
    //const hash = this.web3js.utils.soliditySha3(requestId, recipient, amount)
    //console.log(`hash: ${hash}`)
    //const test = await this.web3js.eth.personal.ecRecover(hash, sigHex)
    // console.log(`test: ${test}`)
  }

  updateRewards = async (props) => {
    const userAccount = props.web3state.metamaskAccount
    if (!userAccount) { return }

    this.setState({rewardsLoading: true})
    let requestUrl = this.apiUrl + "/pendingRewards"
    const recipient = userAccount
    const response = await axios.get(requestUrl, { params: { recipient }})
    this.setState({
      availableRewards: response.data.items,
      rewardsLoading: false
    })
  }

  updateOrders = async (props) => {
    const userAccount = props.web3state.metamaskAccount
    if (!userAccount) { return }

    let requestUrl = this.apiUrl + "/getOrders"
    const response = await axios.get(requestUrl, { params: { userAccount }})
    this.setState({userOrders: response.data.items})
  }

  claimReward = async (rewardId) => {
    if (!this.ensureScrapNet()) { return }

    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    const { availableRewards } = this.state
    const reward = availableRewards.find(reward => reward.rewardId === rewardId)
    if (!reward) { return }

    const amount = reward.amount
    const signature = this.web3js.utils.bytesToHex(reward.signature.data.slice(1))

    try {
      await this.scrapContract.methods.claimReward(rewardId, amount, signature)
        .send({
          from: userAccount,
          chainId: scrapNetwork,
        })
        .on('transactionHash', (txnHash) => {
          let requestUrl = this.apiUrl + "/setRewardTxnHash"
          axios.post(requestUrl, { rewardId, txnHash })
        })
    } catch (err) {
      console.error(err)
      return
    }
    let requestUrl = this.apiUrl + "/completeReward"
    await axios.post(requestUrl, { rewardId })
    this.updateRewards(this.props)
  }

  checkScrapBalanceForOrder = async (neededBalance) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return false }

    const balance = await this.scrapContract.methods.balanceOf(userAccount).call({
      chainId: scrapNetwork
    })
    if (balance < neededBalance) {
      return false
    }
    return true
  }

  checkScrapAllowanceForOrder = async (neededBalance) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return false }

    const allowance = await this.scrapContract.methods.allowance(userAccount, ETH_SYSTEM_ACCOUNT).call({
      chainId: scrapNetwork
    })
    if (allowance < neededBalance) {
      return false
    }
    return true
  }

  checkRaceTokenBalanceForOrder = async (neededBalance) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return false }

    const balance = await this.raceTokenContract.methods.balanceOf(userAccount).call({
      chainId: raceTokenNetwork
    })
    if (balance < neededBalance) {
      return false
    }
    return true
  }

  checkRaceTokenAllowanceForOrder = async (neededBalance) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return false }

    const allowance = await this.raceTokenContract.methods.allowance(userAccount, ETH_SYSTEM_ACCOUNT).call({
      chainId: raceTokenNetwork
    })
    if (allowance < neededBalance) {
      return false
    }
    return true
  }

  setScrapAllowance = async (price) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    try {
      await this.scrapContract.methods.approve(ETH_SYSTEM_ACCOUNT, price * 10)
        .send({
          from: userAccount,
          chainId: scrapNetwork,
        });
    } catch (error) {
      return
    }
  }

  setRaceTokenAllowance = async (price) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    try {
      await this.raceTokenContract.methods.approve(ETH_SYSTEM_ACCOUNT, price * 10)
        .send({
          from: userAccount,
          chainId: scrapNetwork,
        });
    } catch (error) {
      return
    }
  }

  onPlaceOrder = async () => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    const balanceOk = await this.checkScrapBalanceForOrder(TEST_ORDER_PRICE)
    if (!balanceOk) {
      alert('Bad balance')
      return
    }

    const allowanceOk = await this.checkScrapAllowanceForOrder(TEST_ORDER_PRICE)
    if (!allowanceOk) {
      this.setScrapAllowance(TEST_ORDER_PRICE)
      return
    }
    let requestUrl = this.apiUrl + "/placeDummyOrder"

    await axios.post(requestUrl, {
      userAccount,
    })
    this.updateOrders(this.props)
  }

  updateParts = async (props) => {
    const userAccount = props.web3state.metamaskAccount
    if (!userAccount) { return }

    let idx = 0
    const tokenIds = []
    while(true) {
      try {
        const id = await this.partContract.methods
          .tokenOfOwnerByIndex(userAccount, idx).call({
            chainId: partNetwork
          })
        tokenIds.push(id)
        this.setState({ userParts: tokenIds })
        idx += 1
      } catch (err) {
        break
      }
    }
  }

  onPartUpgrade = async (partId, toLevel, upgradePrice) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    const balanceOk = await this.checkScrapBalanceForOrder(upgradePrice)
    if (!balanceOk) {
      alert('Bad balance')
      return false
    }

    const allowanceOk = await this.checkScrapAllowanceForOrder(upgradePrice)
    if (!allowanceOk) {
      this.setScrapAllowance(upgradePrice)
      return false
    }
    let requestUrl = this.apiUrl + "/upgradePart"

    await axios.post(requestUrl, {
      userAccount,
      partId,
      toLevel
    })
    this.updateOrders(this.props)
    return true
  }

  onPartDismantle = async (partId) => {
    if (!this.ensurePartNetwork()) { return }
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    // TODO: set dismantling address
    await this.partContract.methods
      .transferFrom(userAccount, ETH_SYSTEM_ACCOUNT, partId)
      .send({
        from: userAccount,
        chainId: scrapNetwork,
      })
    this.updateParts(this.props)
  }

  onBuyRaceToken = async () => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    // hardcode the price here for now, TODO add an api call to get it from server
    const raceTokenAmount = 10
    const SCRAP_FOR_RACE_TOKEN = 1
    const scrapPrice = Math.trunc(raceTokenAmount / SCRAP_FOR_RACE_TOKEN)

    const balanceOk = await this.checkScrapBalanceForOrder(scrapPrice)
    if (!balanceOk) {
      alert('Bad balance')
      return
    }

    const allowanceOk = await this.checkScrapAllowanceForOrder(scrapPrice)
    if (!allowanceOk) {
      this.setScrapAllowance(scrapPrice)
      return
    }

    this.setState({
      raceTokensOrderActive: true,
      raceTokensOrderError: false,
    })

    let requestUrl = this.apiUrl + "/purchaseRaceToken"
    const result = await axios.post(requestUrl, {
      userAccount,
      raceTokenAmount
    })

    const { orderId } = result.data
    this.setState({
      raceTokensOrderId: orderId
    })
    this.orderInterval = setInterval(this.updateRaceTokenOrder, 1000)
  }

  updateRaceTokenOrder = async () => {
    const { raceTokensOrderId } = this.state
    if (!raceTokensOrderId) {
      return
    }

    let requestUrl = this.apiUrl + "/getSingleOrder"
    const response = await axios.get(requestUrl, { params: { orderId: raceTokensOrderId }})
    const order = response.data.result
    if (order.status === 'completed') {
      clearInterval(this.orderInterval)
      this.setState({ 
        raceTokensOrderId: null,
        raceTokensOrderActive: false
      })
    } else if (order.status === 'error') {
      clearInterval(this.orderInterval)
      this.setState({ 
        raceTokensOrderId: null,
        raceTokensOrderActive: false,
        raceTokensOrderError: true,
      })
    } else {
      // keep waiting
    }
  }

  onCreateEvent = () => {
    const eventId = this.web3js.utils.randomHex(11)
    const { eventPasses } = this.state
    eventPasses.push(
    {
      eventId,
      loading: false
    })
    this.setState(eventPasses)
  }

  onEventPassPay = async (eventId) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    const balanceOk = await this.checkRaceTokenBalanceForOrder(EVENT_PASS_PRICE)
    if (!balanceOk) {
      alert('Bad balance')
      return
    }

    const allowanceOk = await this.checkRaceTokenAllowanceForOrder(EVENT_PASS_PRICE)
    if (!allowanceOk) {
      this.setRaceTokenAllowance(EVENT_PASS_PRICE)
      return
    }

    const { eventPasses } = this.state
    const pass = eventPasses.find(eventPass => eventPass.eventId === eventId)

    if (pass !== null) {
      pass.loading = true
      this.setState({ eventPasses })
    }

    let requestUrl = this.apiUrl + "/eventPassPay"
    const result = await axios.post(requestUrl, {
      userAccount,
      eventId
    })

    if (pass !== null) {
      pass.loading = false
      this.setState({ eventPasses })
    }

    return result
  }

  onEventPassConsume = async (eventId) => {
    const userAccount = this.props.web3state.metamaskAccount
    if (!userAccount) { return }

    let requestUrl = this.apiUrl + "/eventPassConsume"

    await axios.post(requestUrl, {
      userAccount,
      eventId
    })
  }

  render() {
    const { availableRewards, userOrders, userParts, eventPasses,
      rewardsLoading, raceTokensOrderActive, raceTokensOrderError } = this.state
    const userAccount = this.props.web3state.metamaskAccount

    const isCorrectScrapNet = this.isCorrectScrapNet()
    const isCorrectRaceTokenNet = this.isCorrectRaceTokenNet()

    const rewards = availableRewards.map(reward =>
      <Reward key={reward.rewardId} reward={reward} onClaimReward={this.claimReward} />
    )

    const orders = userOrders
      .slice(-Math.min(userOrders.length, 10))
      .map(order => 
        <Order key={order.OrderId} orderId={order.OrderId} />
      )

    const parts = userParts.map(part =>
      <Part key={part} partId={part} onUpgrade={this.onPartUpgrade} onDismantle={this.onPartDismantle} />
    )

    const eventPassesDivs = eventPasses.map(eventPass =>
      <EventPass
        key={eventPass}
        eventId={eventPass.eventId}
        loading={eventPass.loading}
        onPay={this.onEventPassPay}
        onConsume={this.onEventPassConsume}
        userAccount={userAccount}
      />
    )

    return (
      <div className="FAQ">
        <title>Scrap Test page</title>
        <section className="section-header header-main container-fluid"
          style={{height: '2000px'}}>
          <div className="container d-flex flex-column align-items-left">
            <div className="overflow-wrap">
              <h5 style={{ padding: '10px'}}>Scrap Test page</h5>
            </div>
            <BalanceDisplay
              userAccount={userAccount}
              scrapContract={this.scrapContract}
              raceTokenContract={this.raceTokenContract}
              isCorrectScrapNet={isCorrectScrapNet}
              isCorrectRaceTokenNet={isCorrectRaceTokenNet}
            />
            <div style={{ padding: '10px'}} className="d-flex flex-row w200">
              <button
                style={{ marginRight: '10px'}}
                className="button-common button-smaller"
                onClick={this.onCreateReward}
              >
                Create test reward
              </button>
              {!rewardsLoading && (
                <button
                  style={{ marginRight: '10px'}}
                  className="button-common button-smaller"
                  onClick={() => this.updateRewards(this.props)}
                >
                  Refresh
                </button>
              )}
              {rewardsLoading && (
                <Loader />
              )}
            </div>
            <div className="bg w100 d-flex flex-column">
              <h5 style={{ padding: '10px' }}>Available rewards:</h5>
              <div style={{maxHeight: '256px', overflowY: 'scroll', padding: '10px'}}>
                { rewards }
              </div>
            </div>
            <div  style={{ padding: '10px'}} className="w100">
              { !raceTokensOrderActive && (
                <button  style={{ marginRight: '10px'}} className="button-common button-smaller" onClick={this.onBuyRaceToken}>
                  Buy 10 race tokens
                </button>
              )}
              { raceTokensOrderActive && (
                <Loader />
              )}
              { raceTokensOrderError && (
                <div>Buying tokens error</div>
              )}
            </div>
            <div className="bg w100 d-flex flex-column">
              <h5 style={{ padding: '10px' }}>Race:</h5>
              <div style={{maxHeight: '256px', overflowY: 'scroll', padding: '10px'}}>
                <RaceExample
                  userAccount={userAccount}
                  onPay={this.onEventPassPay}
                  onConsume={this.onEventPassConsume}
                  raceTokenContract={this.raceTokenContract}
                />
              </div>
            </div>
            <div  style={{ padding: '10px'}} className="w100">
              <button  style={{ marginRight: '10px'}} className="button-common button-smaller" onClick={this.onCreateEvent}>
                Create test event
              </button>
            </div>
            <div className="bg w100 d-flex flex-column">
              <h5 style={{ padding: '10px' }}>Events:</h5>
              <div style={{maxHeight: '256px', overflowY: 'scroll', padding: '10px'}}>
                { eventPassesDivs }
              </div>
            </div>
            <div  style={{ padding: '10px'}} className="w100">
              <button className="button-common button-smaller" onClick={this.onPlaceOrder}>
                Place test order
              </button>
            </div>
            <div className="bg w100 d-flex flex-column">
              <h5 style={{ padding: '10px' }}>Last 10 orders:</h5>
              <div style={{maxHeight: '256px', overflowY: 'scroll', padding: '10px'}}>
                { orders }
              </div>
            </div>
            <div className="w100 d-flex flex-column">
              <h5>Parts:</h5>
              <div className="w1000 d-flex flex-row flex-wrap">
                { parts }
              </div>
            </div>
          </div>
        </section>
        <button className="back-to-top" type="button" />
      </div>
    );
  }
}
