import React from 'react';
import Web3 from 'web3';
// import {Link} from 'react-router-dom';
import ComponentWithAlert from './ComponentWithAlert';
// import NiftyGatewayJS from 'niftygateway';
import axios from 'axios';
import $ from 'jquery';
import ReactSlider from 'react-slider';

// const infuraKey = process.env.INFURA_KEY;
const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

const ETH_SYSTEM_ACCOUNT = process.env.REACT_APP_ETH_SYSTEM_ACCOUNT;
const LEGENDARY_MULT = 1.5;
// const LEGENDARY_BONUS_START = new Date(process.env.REACT_APP_LEGENDARY_BONUS_START || 0);
// const LEGENDARY_BONUS_END = new Date(process.env.REACT_APP_LEGENDARY_BONUS_END || 0);


class Crate extends ComponentWithAlert {
  constructor(props) {
    super(props);

    if (window.web3)
      this.web3js = this.connectToEth();

    this.contract = null;

    this.state = {
      price: 0,
      etherPrice: 0,
      discountedPrice: 0,
      discountedEtherPrice: 0,
      cap: 0,
      totalSupply: 0,
      remaining: 0,

      cratesOwned: "0",
      systemAllowance: "0",

      crateAmt: 1,
      purchaseCurrency: "ETH",

      isReady: false,
      isBuying: false,
      isGranting: false,
      isOpening: false,
      isAxieCrate: false
    }

    this.handlePurchaseSubmit = this.handlePurchaseSubmit.bind(this);
    this.onChangePurchaseCurrency = this.onChangePurchaseCurrency.bind(this);
    this.onChangeCrateAmount = this.onChangeCrateAmount.bind(this);
    // this.niftygatewayPurchase = this.niftygatewayPurchase.bind(this);
    this.getEtherScanURL = this.getEtherScanURL.bind(this);
    this.tokenSwap = this.tokenSwap.bind(this);
    this.buyWithEth = this.buyWithEth.bind(this);

    if (this.props.web3state && this.props.web3state.networkId) {
      this.contractAddress = this.getAddressFromJson(this.props.contractJson, this.props.web3state.networkId);
      this.update();
    }

    if (this.props.name === "Axie Crate")  {
      this.state.isAxieCrate = true;
    }
    
    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.web3state) {
      var networkId = this.props.web3state.networkId;
      var userAccount = this.props.web3state.metamaskAccount;
      var crateDiscount = this.props.crateDiscount;
      var needsUpdate = false;

      if (networkId !== prevProps.web3state.networkId) {
        this.contractAddress = this.getAddressFromJson(this.props.contractJson, networkId);
        this.contract = this.getContract();

        needsUpdate = true;
      }

      if (userAccount !== prevProps.web3state.metamaskAccount) {
        needsUpdate = true;
      }

      if (prevProps.crateDiscount !== crateDiscount) {
        this.getCratePrice();
      }

      if (needsUpdate) {
        this.update();
      }
    }
  }

  dismiss() {
    this._alert("Purchase successful. <a id='inventoryButton' href='" +
    window.location.origin + "/inventory/crates'>View Crates</a>", "info");
    $('#inventoryButton').css('background', 'none');
    $('#inventoryButton').css('color', 'white');
    $('#inventoryButton').css('border', '2px solid white');
    $('#inventoryButton').css('border-radius', '10px');
    $('#inventoryButton').css('padding', '5px');

    this.props.unmountMe();
  }

  async update() {
    await this.getCratePrice();
    await this.getRemaining();
    await this.getCratesOwned();
    /* await this.getSystemAllowance(); */
    setTimeout(() =>{
       if (this.props.updateTotalCrates){
      this.props.updateTotalCrates(Number(this.state.cratesOwned))
    }
  },500)

    this.setState({isReady: true});
  }

  connectToEth() {
    return new Web3(window.web3.currentProvider);
  }

  getAddressFromJson(contractJson, networkId)
  {
    var contractNetworks = contractJson.networks;

    if (contractNetworks[networkId] !== undefined)
      return (contractNetworks[networkId].address)
  }

  getContract() {
    if (!this.contract &&
        this.contractAddress)
    {
      var contractAddress = this.contractAddress;
      this.contract = new this.web3js.eth.Contract(this.props.contractJson.abi, contractAddress, {from: this.userAccount});
    }

    return this.contract;
  }

  async getCratePrice() {
    var contract = this.getContract();

    try {
      contract.methods.price().call().then(price => {
        var discountedPrice = price * ((100 - this.props.crateDiscount) / 100);
        var discountedEtherPrice = this.web3js.utils.fromWei(discountedPrice.toString(), "ether");
        var etherPrice = this.web3js.utils.fromWei(price.toString(), "ether");
        this.setState({
          price: price.toString(),
          etherPrice,
          discountedPrice: discountedPrice.toString(),
          discountedEtherPrice
        });
      })
      .catch(e => {
      })

    } catch(e) {
    }
  }

  async getCratesOwned() {

    try {

    var userAccount = this.props.web3state.metamaskAccount;
    var contract = this.getContract();

    contract.methods.balanceOf(userAccount).call().then(balance => {
      this.setState({cratesOwned: balance});

    })
    .catch(e => {
    });

    } catch(e) {
    }

  }

  async getRemaining() {

    try {

    var contract = this.getContract();
    var totalSupply = await contract.methods.totalSupply().call();
    var cap = await contract.methods.cap().call();
    var bnTotalSupply = this.web3js.utils.toBN(totalSupply);
    var bnCap = this.web3js.utils.toBN(cap);
    var remaining = bnCap.sub(bnTotalSupply);

    if (this.state.isAxieCrate)
    {
      this.setState({
        remaining: remaining.toString(),
        cap: cap,
        totalSupply: totalSupply
      });
    }
    else

    {
      this.setState({
        remaining: remaining.toString(),
        cap: Math.floor(Number(cap) * 0.95).toString(),
        totalSupply: totalSupply
      });
    }

    } catch(e) {
    }
  }

  /* async getSystemAllowance() {
    try {
      var contract = this.getContract();
      var userAccount = this.props.web3state.metamaskAccount;
      var allowance = await contract.methods
        .allowance(userAccount, ETH_SYSTEM_ACCOUNT)
        .call();


      this.setState({systemAllowance: allowance});

      return allowance;
    }
    catch (e) {
    }
  } */

  getDropRate(value, precision=0) {
    return (value * 100).toFixed(precision);
  }

  getTotalPrice(denomination = "wei") {
    // var costWithDiscount = this.props.cost * ((100 - this.props.crateDiscount) / 100);

    var costInWei = this.web3js.utils.toWei(this.state.discountedEtherPrice.toString());
    var buyAmount = this.web3js.utils.toBN(this.state.crateAmt);
    var totalPrice = this.web3js.utils.toBN(costInWei).mul(buyAmount).toString();


    if (denomination === "wei")
      return totalPrice;

    return this.web3js.utils.fromWei(totalPrice, denomination);
  }

  async tokenSwap(e) {
    /*e.preventDefault();
    this.props.kyberPopupRef.current.showPurchase({
      selectedCrate: {...this.props},
      ...this.state
    });*/
    e.preventDefault();
    const crateAmount = this.state.crateAmt;
    const crateType = this.props.crateType;
    const userAccount = this.props.web3state.metamaskAccount;

    const requestUrl = this.apiUrl + '/createOrder'
    const resp = await axios.get(requestUrl,
      {
        params: {
          buyer: userAccount,
          crate: crateType,
          amount: crateAmount
        }
      })
    const { orderId } = resp.data

    this.props.kyberPopupRef.current.showPurchase({
      selectedCrate: {...this.props},
      orderId,
      ...this.state
    });
  }

  handlePurchaseSubmit(e) {
    e.preventDefault();

    if (!this.state.isReady) {
      return;
    }

    if (this.props.web3state.ready) {
      switch (this.state.purchaseCurrency) {
        case "ETH":
          if (!this.state.isAxieCrate)
            this.buyWithEth();
          else
            this.buyAxieWithEth();
        break;
        case "kyber":
          this.tokenSwap(e)
        break;
        case "creditcard":
        // if (!this.state.isAxieCrate)
          // this.niftygatewayPurchase();
        // else
          // this.axieNiftygatewayPurchase();
        break;
        default:

        break;
      }
    }
  }

  async buyAxieWithEth() {
    if (this.state.isBuying) {
      this._alert("Unable to buy, pending transaction.", "info");
      return;
    }

    var context = this;
    var userAccount = this.props.web3state.metamaskAccount;
    var contract = this.getContract();
    var totalPrice = this.getTotalPrice();

    this.setState({isBuying: true});

    try {
      await contract.methods.purchase(this.props.referralAddress)
      .send({from: userAccount, value: totalPrice}).on('transactionHash', (function(hash){

        context._alert("Purchase started: <a id='etherscanButton' target='_blank' href='"
        + this.getEtherScanURL() + hash + "'>View on Etherscan</a>", "info");
        $('#etherscanButton').css('background', 'none');
        $('#etherscanButton').css('color', 'white');
        $('#etherscanButton').css('border', '2px solid white');
        $('#etherscanButton').css('border-radius', '10px');
        $('#etherscanButton').css('padding', '5px');

      }).bind(this));

      this._alert("Purchase successful. <a id='inventoryButton' href='" +
      window.location.origin + "/inventory/crates'>View Crates</a>", "info");
      $('#inventoryButton').css('background', 'none');
      $('#inventoryButton').css('color', 'white');
      $('#inventoryButton').css('border', '2px solid white');
      $('#inventoryButton').css('border-radius', '10px');
      $('#inventoryButton').css('padding', '5px');

      context.setState({isBuying: false});

      context.getCratesOwned();
      context.getRemaining();
    }
    catch(e) {
      this._alert("Purchase cancelled.", "error");
      context.setState({isBuying: false});
    };
  }

  async buyWithEth() {
    if (this.state.crateAmt === 0) {
      alert("You can't buy Nothing!");
      return;
    }

    if (this.state.isBuying) {
      this._alert("Unable to buy, pending transaction.", "info");
      return;
    }

    var context = this;
    var userAccount = this.props.web3state.metamaskAccount;
    var contract = this.getContract();
    var totalPrice = this.getTotalPrice();

    this.setState({isBuying: true});
    console.log('context.props.web3state', this.props.referralAddress);
    try {
      var referralAddress;
      var customReferalAddress;
      if (this.props.crateType === "s1ck") {
        referralAddress = userAccount;
        customReferalAddress = "";
      }
      else if (!window.web3.utils.checkAddressChecksum(this.props.referralAddress)) {
        // false = custom affilate link
        referralAddress = userAccount;
        customReferalAddress = this.props.referralAddress;
      } else {
        referralAddress = this.props.referralAddress;
        customReferalAddress = this.props.referralAddress;
      }
      console.log("whut?");
      await contract.methods.purchase(this.state.crateAmt, referralAddress, customReferalAddress)
      .send({from: userAccount, value: totalPrice}).on('transactionHash', (function(hash){

        context._alert("Purchase started: <a id='etherscanButton' target='_blank' href='"
        + this.getEtherScanURL() + hash + "'>View on Etherscan</a>", "info");
        $('#etherscanButton').css('background', 'none');
        $('#etherscanButton').css('color', 'white');
        $('#etherscanButton').css('border', '2px solid white');
        $('#etherscanButton').css('border-radius', '10px');
        $('#etherscanButton').css('padding', '5px');

      }).bind(this));

      this._alert("Purchase successful. <a id='inventoryButton' href='" +
      window.location.origin + "/inventory/crates'>View Crates</a>", "info");
      $('#inventoryButton').css('background', 'none');
      $('#inventoryButton').css('color', 'white');
      $('#inventoryButton').css('border', '2px solid white');
      $('#inventoryButton').css('border-radius', '10px');
      $('#inventoryButton').css('padding', '5px');

      context.setState({isBuying: false});

      context.getCratesOwned();
      context.getRemaining();
    }
    catch(e) {
      console.log(e);
      this._alert("Purchase cancelled.", "error");
      context.setState({isBuying: false});
    };
  }

  onChangePurchaseCurrency() {
    this.setState({
      purchaseCurrency: this.refs.purchaseCurrency.value
    });
  }

  onChangeCrateAmount(e) {
    try {
    this.setState({
      crateAmt: e
    });
    } catch (e) {
      console.log(e)
    }
    /*e.preventDefault();
    this.setState({
      crateAmt: e
    });*/
    /*this.setState({
      crateAmt: this.refs.crateAmtRange.value
    });*/
  }

  /* NG_DEV_KEY = "JJGQGrna8e0kvGHNT0MDghnXJfoOJ2";
  nftg = new NiftyGatewayJS('main', this.NG_DEV_KEY);
  fun_sig_axie = 'purchaseFor(address,address)';
  fun_sig = 'purchaseFor(address,uint256,address,string)'; */


  /* axieNiftygatewayPurchase() {
    var contractAddress = this.contractAddress;
    var args = [
      'userAddress',
      this.props.referralAddress
    ];

    var purchaseForObject = {
      contractAddress: contractAddress,
      purchaseForFunction: this.fun_sig_axie,
      argsList: args,
      value: this.props.cost,
      displayTitle: this.props.name,
      displayImageURL: window.location.origin + "/images/" + this.props.image,
      createNiftyWallet: false
    }

    this.nftg.sendPurchaseForTransaction(purchaseForObject).then(res => {
      if (res.didSucceed) {
        // var myTrackingURL = res.transactionURL
      } else {
        alert('Looks like something went wrong!')
      }
    }).catch(function (error) {
      // invalid purchase object
    })
  } */

  /* niftygatewayPurchase() {
    var crateAmt = parseInt(this.state.crateAmt);
    var contractAddress = this.contractAddress;
    var userAccount = this.props.web3state.metamaskAccount;
    var referralAddress ;
    var customReferralAddress;

    if (!window.web3.utils.checkAddressChecksum(this.props.referralAddress)) {
      // false = custom affilate link
      referralAddress = userAccount;
      customReferralAddress = this.props.referralAddress;
    } else {
      referralAddress = this.props.referralAddress;
      customReferralAddress = this.props.referralAddress;
    }

    var args = [
      'userAddress',
      crateAmt,
      referralAddress,
      customReferralAddress
    ];

    var purchaseForObject = {
      contractAddress: contractAddress,
      purchaseForFunction: this.fun_sig,
      argsList: args,
      value: crateAmt * ( this.props.cost * ((100 - this.props.crateDiscount) / 100)),
      displayTitle: this.props.name + " x " + crateAmt,
      displayImageURL: window.location.origin + "/images/" + this.props.image,
      createNiftyWallet: false
    }

    this.nftg.sendPurchaseForTransaction(purchaseForObject).then(res => {
      if (res.didSucceed) {
        // var myTrackingURL = res.transactionURL
      } else {
        alert('Looks like something went wrong!')
      }
    }).catch(function (error) {
      // invalid purchase object
    })
  } */

  isLegendaryBonusActive() {
    return false;
//    var now = Date.now();
//    return (LEGENDARY_BONUS_START <= now && now < LEGENDARY_BONUS_END);
  }

  getLegBonusRate(rate) {
    return rate * LEGENDARY_MULT;
  }
  getEtherScanURL() {

    var baseUrl;
    var networkId = this.props.web3state.networkId;

    if (networkId) {
      switch (networkId) {
        case 1:
          baseUrl = "https://etherscan.io";
          break;
        case 3:
          baseUrl = "https://ropsten.etherscan.io";
          break;
        case 4:
          baseUrl = "https://rinkeby.etherscan.io"
          break;
        default:
      }

      if (!baseUrl) {
        return null;
      }

      return baseUrl + "/tx/" ;
    }
  }

  renderBullets() {
    let r;

    if (this.props.crateType === "s1ck") {
      r = <ul>
        <li>
          Number of crates for sale: 350
        </li>
        <li>
          Contains 2 random parts
        </li>
        <li>
          Drop rates: 25% per part type
        </li>
        <li>
          Maximum of 3 crate purchases per wallet
        </li>
        <li>
          Must have a CryptoKitties NFT in your wallet to purchase
        </li>
        <li>
          <strong>Crate Amount:</strong> 1
        </li>
      </ul>
    }
    else if (this.state.isAxieCrate) {
      r = <ul>
        <li>
          Total number of crates for sale: 150
        </li>
        <li>
          Each crate contains: 4 Axie parts at random
        </li>
        <li>
          You can only purchase 1 Axie Crate at a time
        </li>
        <li>
          Maximum of 3 crate purchases per wallet
        </li>
        <li>
          All car parts are pre-minted and amount to: 80 common cars, 50 rare cars, 20 epic cars
        </li>
        <li>
          <strong>Crate Amount:</strong> 1
        </li>
      </ul>
    }
    else {
      r = <ul>
      <li>Number of crates for sale: {this.props.totalCrates}</li>
      <li> Each crate contains Contains {this.props.parts} random parts</li>
      <li>Drop rates: Common {this.getDropRate(this.props.droprates.common)}%, Rare {this.getDropRate(this.props.droprates.rare)}%, Epic {this.getDropRate(this.props.droprates.epic)}%</li>
      <li>Legendary drop chance: { this.isLegendaryBonusActive() ?
        <span>
          <span className="strikethrough">{this.getDropRate(this.props.droprates.legendary, 4)}%</span>
          <span className="crate-rateup ml-2"><strong className="green">{this.getDropRate(this.getLegBonusRate(this.props.droprates.legendary), 5)}%</strong> 1.5x Rate Up!</span>
        </span>
      : this.getDropRate(this.props.droprates.legendary, 4).toString() + "%" }</li>
      </ul>
    }

    return r;
  }

  render() {
    var originalPrice = <h4 className="d-inline mr-2">{this.props.cost} ETH</h4>;
    var discountedPrice = "";
    var discount = "";

    if (this.props.crateDiscount !== 0) {
      originalPrice = <strike className="h4 mr-2">{this.props.cost} ETH</strike>;
      discountedPrice = <h4 className="mr-2">{this.state.discountedEtherPrice} ETH</h4>;
      discount = <h4 className="text-success mb-4">({this.props.crateDiscount}% off)</h4>;
    }

    return (
      <div className={"Crate mb-5" + (this.props.isSelected ? "" : " d-none")}>
        {this.props.crateType === "s1ck" ?
          <div className="mb-5 text-center">
            <div className=""><img src={require("../images/ckitties/BG@2x.png")} className="img-fluid" alt=""/></div>
            <h1>Get your RoboKitty Parts!</h1>
            <p>The exclusive CryptoKitties collaboration car parts and crates are available starting December 11, 2019 4PM PST / 7PM EST (December 12, 2019 1AM CET / 8AM GMT+8).</p>
          </div>
          :
          <div className="mb-5 text-center">
            <div className="title s1">
            <h1>Season 1 Crate Sale!</h1>
            <h4>New cars and parts available exclusively from Dec 3rd to 20th, 2019, or until all crates are sold</h4>
            </div>
          </div>
        }

        <div className="row">
          <div className="col-md-5 ">
            <h2 className="mb-0" style={{color: '#121256'}}>{this.props.name}</h2>
            <div className="d-flex flex-row flex-wrap">
            {originalPrice}
            {discountedPrice}
            {discount}
            </div>
            {/*<strike className="h4 d-inline mr-2">{this.props.cost} ETH</strike>*/}
            {/*<h4 className="d-inline mr-2">{this.props.cost} ETH</h4>*/}
            {/*<h4 className="text-success mb-4 d-inline">({this.props.crateDiscount}% off)</h4>*/}
            <img src={require("../images/presale/" + this.props.image)} className="main-crate-image mb-5 mt-5" alt="" />
            <h4 className="text-muted text-center mb-5">{this.state.remaining} left</h4>
          </div>
          <div className="col-md-7 store-crate-info d-flex flex-column p-3">
            {this.renderBullets()}

            { this.state.remaining.toString() !== "0" ?
              <div>
                <form onSubmit={this.handlePurchaseSubmit} className="mt-auto">
                {this.state.isAxieCrate || this.props.crateType === "s1ck" ? null :
                  /*<div className="form-group" style={{marginBottom: '10px'}}>
                    <label htmlFor="crateAmtRange" className="mb-3">Crate Amount</label>
                    <input onChange={this.onChangeCrateAmount} type="range" className="form-control-range" ref="crateAmtRange" value={this.state.crateAmt} min={1} max={100}/>
                    <label htmlFor="crateAmtRange" className="mb-4">{this.state.crateAmt}</label>
                  </div>*/
                  <div className="reactslider-wrapper-ck">
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="promo-thumb"
                      trackClassName="promo-track"
                      onChange={this.onChangeCrateAmount}
                      min={1}
                      max={100}
                      renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    />
                  </div>
                }
                {this.state.isAxieCrate ? null :
                  <div>
                    <label htmlFor="inputGroupSelect02" style={{display: 'block'}}>Purchase with</label>
                    <div className="form-group mb-3 d-flex flex-row flex-wrap align-items-center">
                    <div className="mb-2">
                      <select
                        onChange={this.onChangePurchaseCurrency}
                        className="custom-select currency form-control mr-2 p-2"
                        id="inputGroupSelect02"
                        width="200px"
                        ref="purchaseCurrency"
                        defaultValue="ETH"
                      >
                        <option value="ETH">ETH</option>
                        {/*<option value="creditcard">Credit Card</option>*/}
                        {this.props.crateType !== "s1ck" ? <option value="kyber">Other Currencies</option> : ""}
                      </select>
                    </div>
                     {/* <button onClick={this.tokenSwap} className="btn btn-success mr-4 mb-2" id="convertTo"><img src={require('../images/icons/logo-kyber.png')} className="mr-2" height="20px" alt="" />Buy with DAI</button>*/}
                    </div>
                    {/*<p className="small"><span className="text-danger">IMPORTANT:</span> If purchasing with credit card, you will need a separate Ethereum wallet address and set this as your destination wallet. Otherwise, you cannot use your crates! Click <Link to="/faq">here</Link> for more info.</p>*/}
                  </div>
                  }
                  <div className="d-flex flex-row align-items-center">
                  <h4 className="d-inline mb-0 mr-3">Total: {
                    parseFloat(this.getTotalPrice("ether")).toFixed(5)} ETH</h4><input type="submit" className="crate-amt-submit button-common small-border" value="Purchase"/>
                  </div>
                </form>
              </div>
            : "" }

          </div>
        </div>
      </div>
    );
  }
}

export default Crate;
