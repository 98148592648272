import React, { Component } from 'react';

function withSaleInfo(WrappedComponent) {
  return class extends Component {

    saleStartDate = ""

    constructor(props) {
      super(props);
      this.state = {
        saleStartDate: "",
        saleStarted: false
      };
    }

    componentDidMount() {
      var startDate = 1558447200000;
      // var day = 86400000;
      // var currentDate = Date.now();

      var countDownDate =  startDate;

      // Update the count down every 1 second
      this.interval = setInterval( () => {
        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // update state
        this.setState(
          {
            saleStartDate: days + "d " + hours + "h " + minutes + "m " + seconds + "s "
          }
        )

        // If the count down is finished
        if (distance < 0) {
          // refresh page
          this.setState(
            {
              saleStartDate: "OPEN!",
              saleStarted: true
            }
          )

          clearInterval(this.interval);
          this.interval = null;

        }

      }, 1000);

    }

    componentWillUnmount(){
      clearInterval(this.interval);
      this.interval = null;
    }


    render() {
      return (
          <WrappedComponent saleStartDate={this.state.saleStartDate} saleStarted={this.state.saleStarted} {...this.props} />
      );
    }
  }
};

export default withSaleInfo;
