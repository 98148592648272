import React, { Component } from "react";
import { Route } from "react-router-dom";
import axios from "axios";
import withWeb3Alert from "./../Web3Alert";

import AlpacaNavigation from "./AlpacaNavigation";
import AlpacaLogin from "./AlpacaLogin";
import Alpaca from "./Alpaca";
import AlpacaTokenStatus from "./AlpacaTokenStatus";
import AlpacaUsers from "./AlpacaUsers";
import "./../../styles/scss/Alpaca.scss";

const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || "localhost";
const SERVER_PORT =
  process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const DEBUG_MODE = process.env.REACT_APP_DEBUG_MODE === "true";

class AlpacaWrapper extends Component {
  constructor(props) {
    super(props);

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
    this.state = {
      isLogin: false,
    };
    this.loginHandler = this.loginHandler.bind(this);
    // web3JS.utils.toHex(bigAddress)

    this.initInterceptors();
  }

  async initInterceptors() {
    this.requestInterceptors = axios.interceptors.request.use(async (request) => {
      var accounts = await window.web3.eth.getAccounts();
      var selectedAddress = accounts[0];

      if (localStorage.token !== undefined) {
        request.headers = {
          Authorization:
            localStorage.token.split(" ")[0] +
            " " +
            window.web3.utils.toChecksumAddress(selectedAddress),
        };
      } else {
        request.headers = { Authorization: undefined };
      }
      return request;
    });

    this.responseInterceptors = axios.interceptors.response.use((response) => {
      if (
        response.data.isLogout &&
        window.location.href.indexOf("AlpacaLogin") === -1
      ) {
        //window.location = '/login';
        this.props.history.push("/Alpaca/AlpacaLogin");
        throw new axios.Cancel("Please, Login.");
      }
      return response;
    });
  }

  loginHandler() {}

  componentDidMount() {
    this.checkLogin();
  }

  async checkLogin() {
    /*var address = ''
    if (localStorage.token) {
      address = localStorage.token.split(" ")[1];
      var isLogin = await axios.get(this.apiUrl + '/checkAdminLogin',  {params: {address: address}});
      if (!isLogin && window.location.href.indexOf('AlpacaLogin') === -1 ) {
        window.location = '/AlpacaWrapper/AlpacaLogin/';
      }
    } else {
      if (window.location.href.indexOf('AlpacaLogin') === -1 ) {
         window.location = '/AlpacaWrapper/AlpacaLogin/';
      }
    }*/
    if (
      localStorage.token === undefined &&
      window.location.href.indexOf("AlpacaLogin") === -1
    ) {
      window.location = "/Alpaca/AlpacaLogin/";
    }
  }
  
  render() {
    return (
      <div className="AlpacaWrapper">
        <Route path="/Alpaca/AlpacaLogin/" component={AlpacaLogin} />
        <AlpacaNavigation />
        <Route path="/Alpaca/Alpaca/" component={Alpaca} />
        <Route path="/Alpaca/AlpacaUsers/" component={AlpacaUsers} />
        <Route
          path="/Alpaca/AlpacaTokenStatus/"
          component={AlpacaTokenStatus}
        />
      </div>
    );
  }
}

export default withWeb3Alert(AlpacaWrapper);
