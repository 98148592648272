import React, { Component } from 'react'

export default class BaseAlert extends Component {
  constructor(props) {
    super(props);

    this.state = this._getBaseState();

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _getBaseState() {
    return {
      isClosed: true,
      title: "",
      body: "",
      warning: null,
      noButton: false
    };
  }

  show(extras) {
    this.setState({
      isClosed: false,
      title: extras.title,
      body: extras.body,
      warning: extras.warning,
      noButton: extras.noButton,
      callback: extras.callback
    });
  }

  hide() {
    if(this.state.callback){
      this.state.callback();
    }
    this.setState(this._getBaseState());
  }

  handleSubmit(e) {
    e.preventDefault();
    this.hide();
  }

  render() {
    return (
  		<div onClick={!this.state.noButton ? this.hide : null} className={"text-center blur-modal " + (this.state.isClosed ? "d-none" : "d-flex flex-column align-items-center justify-content-center")}>
  			<div className="modal-container vertical" onClick={(e) => {e.stopPropagation();}}>
  				<h4 className="h-blue">{this.state.title}</h4>

          {/*this.state.warning ?
            <h2 className="h-blue">- {this.state.warning} -</h2>
          : null */}
  				{this.state.body}
          {this.state.warning ?
            <span className="text-danger fontsize-normal"><strong>Warning: {this.state.warning}</strong></span>
          : null }
          <form>
            {!this.state.noButton ?
            <button type="submit" onClick={this.handleSubmit} className="button-common button-smaller mt-3">Confirm</button>
            : null }
          </form>
  			</div>

  		</div>
  	);
  }
}
