import React, { Component } from 'react'
import { Link } from "react-router-dom";


export default class Footer extends Component {
  render() {
    return (
		<footer className="container-fluid pb-5 pt-3">
      <div className="container d-flex flex-row">
          {/*<div className="footer-links">
            <p><strong>BATTLE RACERS</strong></p><br />
            <Link to="/press">Press</Link><br />
            <Link to="/faq">FAQ</Link><br />
            <Link to="/aboutus">About Us</Link><br /><br />
            {this.props.referrer !== "0x0000000000000000000000000000000000000000" ?

              <span id="ReferredBy" className="d-block mt-3">referred by: {this.props.referrer}</span>

            : null }
            <span className="mb-5"><a href="http://altitude-games.com" target="_blank" rel="noopener noreferrer"><u>©2021 Altitude Games Pte Ltd</u></a></span>


          </div>*/}
          <div>
              <div className="socmed mb-2">
                <p className="mb-1 text-light"><strong>Social</strong></p>
                <div className="socmed-icons">
                  <div className="socmed-icons-item">
                    <a href="https://www.facebook.com/battleracersgame/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square" /></a>
                  </div>
                  <div className="socmed-icons-item">
                    <a href="https://medium.com/battle-racers" target="_blank" rel="noopener noreferrer"><i className="fab fa-medium-m"></i></a>
                  </div>
                  <div className="socmed-icons-item">
                    <a href="https://twitter.com/BattleRacers" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" /></a>
                  </div>
                  {/*<div className="socmed-icons-item">
                    <a href="https://t.me/battleracers" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane" /></a>
                  </div>
                  <div className="socmed-icons-item">
                    <a href="https://discord.gg/gfEPSra" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord" /></a>
                  </div>*/}
                </div>
              </div>
            <p className="mt-3"><u>©2022 Playcheck Games</u></p>
            </div>
          </div>
        </footer>
			);
  		}
}
