import React, { Component } from 'react'
import '../styles/scss/style.scss';
import '../styles/scss/PromoTemplate.scss';
// import CrateCK from './CrateCK';
import Crate from './Crate';
import {CrateInfo} from './Presale';
import SectionKittyTrick from './SectionKittyTrick';
import MetamaskInst from './MetamaskInst';
import Loader from './Loader';

import Web3 from 'web3'
import axios from 'axios';

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost'

export default class PromoTemplate extends Component {
  constructor(props) {
    super(props);

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }
    if (window.web3) {
      this.web3js = new Web3(window.web3.currentProvider)
    }

    this.state = {
      isReady: false,
      isLoading: false,
      kitties: [],
      crateDiscount: 0,
      offset: 0
    }

    this.kyberPopupRef = React.createRef();

    this.onConsumeTrick = this.onConsumeTrick.bind(this);
    this.updateKitties = this.updateKitties.bind(this);
  }

  componentDidMount() {
    this.updateKitties();
  }

  async updateKitties() {
    if (this.state.isLoading) {
      return false;
    }

    this.setState({isLoading: true});

    const requestUrl = this.apiUrl + "/ckGetKitties"
    const address = this.props.web3state.metamaskAccount
    const blockNumber = await this.web3js.eth.getBlockNumber()

    try {
      const resp = await axios.get(requestUrl, {
        params: {
          'address': address.toLowerCase(),
          'blockNumber': blockNumber,
          'offset': this.state.offset
        },
      })
      console.log(resp.data)

      this.setState({
        kitties: this.state.kitties.concat(resp.data.kitties),
        offset: this.state.offset + resp.data.kitties.length,
        isLoading: false,
        isReady: true
      })
      return true;
    } catch (error) {
      console.log(error)
    }
    return false;
  }

  async onConsumeTrick(kitty) {
    const address = this.props.web3state.metamaskAccount.toLowerCase()
    if (!kitty || !kitty.challengeStr) { return }
    const kittyId = kitty.id;

    let signature = ''
    try {
      signature = await this.web3js.eth.personal.sign(kitty.challengeStr, address)
    } catch (err) {
      console.log(err)
      return
    }

    // console.log(`signature: ${signature}`)
    // console.log(`address: ${address}`)
    // console.log(`challengeStr: ${kitty.challengeStr}`)
    // console.log(`blockNumber: ${kitty.blockNumber}`)
    const requestUrl = `${this.apiUrl}/ckConsumeTrick`
    try {
      const resp = await axios.get(requestUrl, {
        params: {
          'address': address.toLowerCase(),
          'kittyId': kittyId,
          'blockNumber': kitty.blockNumber,
          'signature': signature,
        },
      })
      console.log(resp.data)
    } catch (error) {
      console.log(error)
      return false;
    }

    return true;
    // this.updateKitties();
  }

  render() {
    if (!this.state.isReady) {
      return <Loader />;
    }

    let crate = CrateInfo.s1ck;

    /* eslint-disable jsx-a11y/heading-has-content */
    return (

      <div id="content" className="Presale ck">
        <header id="purchasePane" className="section-header ck header-main d-flex flex-column">
          <div className="container d-flex flex-column align-items-center">
          <Crate
            isSelected={true}
            kyberPopupRef={this.kyberPopupRef}
            web3state={this.props.web3state}
            referralAddress={this.props.referrer}
            crateDiscount={this.state.crateDiscount}
            {...crate}
          />
          </div>
        </header>
        <SectionKittyTrick
          web3state={this.props.web3state}
          kitties={this.state.kitties}
          onConsumeTrick={this.onConsumeTrick}
          updateKitties={this.updateKitties}
          isLoading={this.state.isLoading} />
        <section className="card-array ck container-fluid">
          <div className="container mb-5">
            <p></p>
            <div className="row">
              <div className="col-md-6 p-3">
                <div className="col-md-12 d-flex flex-column feature-a">
                  <h2></h2>
                  <div className="mt-auto">
                    <p>There will only be 1200 CryptoKitties parts available both for purchase and redeemable with the Battle Racers Kitty Trick. These will be pre-minted (similar to our pre-sale and Season 1 Legendaries) to guarantee that 300 complete cars can be formed. Any unsold crates will be burned two weeks after the event has gone live.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-3">
                <div className="col-md-12 d-flex flex-column feature-b">
                  <h2></h2>
                  <div className="mt-auto">
                    <p>Similar to our Axie Infinity event, you need to have at least one CryptoKitty in your MetaMask wallet in order to participate. It can be any CryptoKitty, for as long as you own at least one! (Those without CryptoKitties will not be able to purchase the crates from Battle Racers).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-5">
          <MetamaskInst></MetamaskInst>
        </section>
      </div>
    );

    /* eslint-enable jsx-a11y/heading-has-content */
  }
}
