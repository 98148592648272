import React, { Component } from 'react'
import '../styles/scss/AboutUs.scss';

export default class AboutUs extends Component {
  render() {
    return (
      <div className="AboutUs">
        <header className="section-header header-main about-us container-fluid">
          <div className="container d-flex align-items-center h-100">
            <div className="header-main-headline text-light active">
              <h1>About Altitude Games</h1>
              <p className="text-light">Altitude Games is a Manila-based game studio behind the free-to-play mobile games Kung Fu Clicker, Holy Ship! (3rd IMGA SEA Best Quickplay), Dream Defense (2017 Android Excellence for Fall), and Run Run Super V. The company was founded in 2014 by veterans of the Southeast Asian game industry. Our mission: to bring the best Asian mobile games to the rest of the world. We are proudly composed of the best talent in the region -- currently 30+ developers and still growing!</p>
            </div>
          </div>
        </header>
        <section className="section-founders container-fluid ptpb-100px">
          <div className="container text-center module">
            <div className="h-diamond text-center d-flex align-content-center mb-5"><h2 className="text-center mb-5">The Founders</h2></div>
            {/* <p class="mb-5">Caramels ice cream gingerbread liquorice donut jelly-o cake chocolate cake gingerbread. Cake pie cake chupa chups pastry. Caramels tootsie roll macaroon gingerbread caramels toffee topping.</p> */}
            <div className="d-flex flex-row justify-content-center flex-wrap">
              <div className="about-item"><img src={require("../images/icons/gabby.png")} alt="" />
                <h5>Gabby Dizon</h5>
                <p>CEO
                  <br />
                  <a href="http://ph.linkedin.com/in/gabbydizon"><i className="fab fa-linkedin" /></a></p>
              </div>
              <div className="about-item"><img src={require("../images/icons/luna.png")} alt="" />
                <h5>Luna Javier</h5>
                <p>Creative Director
                  <br />
                  <a href="https://www.linkedin.com/in/lunacruz/"><i className="fab fa-linkedin" /></a></p>
              </div>
              <div className="about-item"><img src={require("../images/icons/marc.png")} alt="" />
                <h5>Marc Polican</h5>
                <p>Engineering Director
                  <br />
                  <a href="http://ph.linkedin.com/pub/marc-anthony-polican/0/524/b82"><i className="fab fa-linkedin" /></a></p>
              </div>
              <div className="about-item"><img src={require("../images/icons/paul.png")} alt="" />
                <h5>Paul Gadi</h5>
                <p>Technical Director
                  <br />
                  <a href="http://ph.linkedin.com/in/paulgadi"><i className="fab fa-linkedin" /></a></p>
              </div>
              {/* <div class="about-item"><img src={require("../images/icons/chester2.png")} alt="">
                    <h5>Chester Ocampo</h5>
                    <p>Art Director
                    <br />
                    <Link to="http://ph.linkedin.com/pub/chester-ocampo/4/165/875"><i class="fab fa-linkedin"></i></Link></p>
                    </div> */}
            </div>
          </div>
        </section>
        <section className="section-execteam container-fluid custombg ptpb-100px">
          <div className="container text-center text-light module">
            <div className="h-diamond text-center d-flex align-content-center mb-5"><h2 className="text-center mb-5">The Executive Team</h2></div>
            {/* <p class="mb-5">Caramels ice cream gingerbread liquorice donut jelly-o cake chocolate cake gingerbread. Cake pie cake chupa chups pastry. Caramels tootsie roll macaroon gingerbread caramels toffee topping.</p> */}
            <div className="d-flex flex-row justify-content-center flex-wrap">
              <div className="about-item"><img src={require("../images/icons/keith.png")} alt="" />
                <h5>Keith Morales</h5>
                <p>Product Director
                  <br />
                  <a href="https://www.linkedin.com/in/keithmorales/"><i className="fab fa-linkedin" /></a></p>
              </div>
              <div className="about-item"><img src={require("../images/icons/jaime.png")} alt="" />
                <h5>Jaime Favis</h5>
                <p>Analytics Director
                  <br />
                  <a href="https://www.linkedin.com/in/jaime-miguel-favis-77a68a39/"><i className="fab fa-linkedin" /></a></p>
              </div>
              <div className="about-item"><img src={require("../images/icons/des.png")} alt="" />
                <h5>Des de San Andres</h5>
                <p>Finance and Admin Manager
                  <br />
                  <a href="http://www.linkedin.com/in/desiree-de-san-andres-31a93b104"><i className="fab fa-linkedin" /></a></p>
              </div>
              <div className="about-item"><img src={require("../images/icons/shelly.jpg")} alt="" />
                <h5>Michelle del Mundo</h5>
                <p>Art Director
                  <br />
                  <a href="https://www.linkedin.com/in/joanne-michelle-soneja-del-mundo-945a6320/"><i className="fab fa-linkedin" /></a></p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-br-team container-fluid text-center ptpb-100px">
          <div className="container">
            <div className="h-diamond text-center d-flex justify-content-center mb-5 mt-5"><h2 className="text-center mb-5 team-headline-fix">The Battle Racers Team</h2></div>
            <div className="row">
              <div className="col-md-12 p-0 mb-4"><img src={require("../images/about-us/team-photo.jpg")} className="img-fluid" alt="" /></div>
            </div>
            <div className="container text-center">
              <p>The Battle Racers team is composed of engineers, game designers, and 2D and 3D artists with over 90 years of combined experience in game development. Before joining Altitude, we’ve worked for or with companies such as Anino Games, Boomzap, Gameloft, TouchTen, and goGame. We’ve worked on PC, console, handheld, and mobile titles. Battle Racers is our first blockchain-based game, and we’re excited to share it with you all!
              </p>
            </div>
          </div>
        </section>
        <button className="back-to-top" type="button" />
      </div>
    );
  }
}
