import React, {Component} from 'react';
import axios from 'axios';
import Web3 from 'web3';

// const SYSTEM_WALLET = process.env.REACT_APP_ETH_SYSTEM_ACCOUNT;

class AlpacaTxnPane extends Component {
  constructor(props) {
    super(props);

    this.contractJson = this.props.contractJson;
    this.contractAddress = this.getContractAddress();
    this.contractCreationTxnHash = this.getContractCreationTxnHash();

    console.log("alpacaTxnPane contractAddress", this.props.id, this.contractJson)

    this.state = {
      txns: []
    };
  }

  componentDidMount() {
    this.getTxns();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var userWallet = this.props.userWallet;

    if (userWallet !== prevProps.userWallet) {
      this.contractAddress = this.getContractAddress();
      this.getTxns();
    }
  }

  async getStartBlock() {
    var startBlock = 0;
    var web3 = new Web3(window.web3.currentProvider);
    var txnHash = this.contractCreationTxnHash;

    if (txnHash) {
      var txn = await web3.eth.getTransaction(txnHash);
      startBlock = txn.block;
    }

    return startBlock;
  }

  getContractAddress() {
    var netId = this.props.web3state.networkId;
    console.log("netId", this.props.id, netId);

    if (netId in this.contractJson.networks) {
      return this.contractJson.networks[netId].address;
    }

    return null;
  }

  getContractCreationTxnHash() {
    var netId = this.props.web3state.networkId;

    if (netId in this.contractJson.networks) {
      return this.contractJson.networks[netId].transactionHash;
    }

    return null;
  }

  getEtherScanAPIURL() {
    var baseUrl;
    var networkId = this.props.web3state.networkId;

    switch (networkId) {
      case 1:
        baseUrl = "https://api.etherscan.io";
        break;
      case 3:
        baseUrl = "https://api-ropsten.etherscan.io"
        break;
      case 4:
        baseUrl = "https://api-rinkeby.etherscan.io"
        break;
      default:
    }

    if (!baseUrl) {
      return null;
    }

    return baseUrl;
  }

  getEtherScanURL() {
    var baseUrl;
    var networkId = this.props.web3state.networkId;

    switch (networkId) {
      case 1:
        baseUrl = "https://etherscan.io";
        break;
      case 3:
        baseUrl = "https://ropsten.etherscan.io"
        break;
      case 4:
        baseUrl = "https://rinkeby.etherscan.io"
        break;
      default:
    }

    if (!baseUrl) {
      return null;
    }

    return baseUrl;
  }

  async getTxns() {
    var baseUrl = this.getEtherScanAPIURL();
    var userWallet = this.props.userWallet;
    if (!baseUrl || !userWallet) {
      return;
    }

    console.log("netid", this.props.id, this.props.web3state.networkId);

    var requestUrl = baseUrl + "/api";
    var axiosAlt = axios.create();
    axiosAlt.get(requestUrl, {
      params: {
        module: "account",
        action: "tokentx",
        contractAddress: this.contractAddress,
        startblock: await this.getStartBlock(),
        endblock: "latest",
        apikey: "U4TVSYFECA61JBCACVR64HUG3TC81SGQRE",
        sort: "asc",
        address: userWallet
      }
    })
    .then((r) => {
      this.setState({txns: r.data.result});
    });
  }

  renderTxns() {
    var txns = [];
    var runningTotal = Web3.utils.toBN(0);

    if (this.state.txns) {
      txns = this.state.txns.map((txn) => {
        var timeStamp = new Date(txn.timeStamp * 1000)
        var rowClass = "";
        var value = Web3.utils.toBN(txn.value);


        if (txn.to === this.props.userWallet.toLowerCase()) {
          rowClass = "table-success";
        }
        else if (txn.from === this.props.userWallet.toLowerCase()) {
          rowClass = "table-danger";
          value = Web3.utils.toBN("-" + value);
        }

        runningTotal = runningTotal.add(value);

        return (
          <tr key={txn.hash} className={rowClass}>
            <td>
              <a href={this.getEtherScanURL() + "/tx/" + txn.hash} target="_blank" rel="noopener noreferrer">
                {txn.hash.slice(0,12) + "..."}
              </a>
            </td>
            <td>{timeStamp.toLocaleString()}</td>
            <td>{txn.from.slice(0,10)}...</td>
            <td>{txn.to.slice(0,10)}...</td>
            <td>{value.toString()}</td>
            <td>{runningTotal.toString()}</td>
          </tr>
        );
      });
    }

    return txns.reverse();
  }

  render() {
    return (
      <div id={this.props.id} className="tab-pane">
        <div>
          Contract Address: {this.contractAddress}
        </div>

        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th>TxnHash</th>
              <th>Timestamp</th>
              <th>From</th>
              <th>To</th>
              <th>Amount</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTxns()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AlpacaTxnPane;
