import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../styles/scss/FAQ.scss';

export default class Faq extends Component {
  render() {
    return (
      <div className="FAQ">
        <title>Battle Racers - Frequently Asked Questions</title>
        <section class="section-header faq header-main container-fluid">
          <div className="container d-flex align-items-center justify-content-center h-100">
            <div className="header-main-headline text-center">
              <h1>FAQ</h1>
              <p>Last updated: March 30, 2021</p>
            </div>
          </div>
        </section>
        <section className="section-founders container-fluid ptpb-100px">
          <div className="container module faq-content">
            <div className="h-diamond text-center d-flex align-content-center mb-5"><h2 className="text-center mb-5">The Game</h2></div>
            <h3>What is Battle Racers?</h3>
            <p id="q1"><i>Battle Racers</i> is an action-packed arcade game where you design, build, and race model cars on arcade-sized tracks. You can mix and match parts and weapons to create your perfect car, then use its combat skills to beat other players to the finish line.
              <br />
            </p>
            <h3>How far along is Battle Racers in development?</h3>
            <p id="q2"><i>Battle Racers</i> is currently in Early Access. You can check out our latest development news on <a href="https://medium.com/battle-racers">Medium</a>.
              <br />
            </p>
            <h3>Where can I play the game?</h3>
            <p id="q2"><i>Battle Racers</i> is currently playable on desktop browsers through our Early Access demo at <a href="https://play.battleracers.io">play.battleracers.io</a> and in Decentraland (coordinates 67,-21). A stable Internet connection is required.
              <br />
              <br />
            A crypto wallet or blockchain wallet is also required to play. You’ll need to install the <a href="https://metamask.io/download.html">MetaMask extension in your browser</a> and create a MetaMask wallet to sign in. You can also use an Venly account to log in to our website and play the demo. An internet connection is also needed to play. Once in-game, you can equip your owned parts in the sidechain and assemble your own unique practice car!
            </p>
            <h3>What happens if I don’t log in using a cryptocurrency wallet?</h3>
            <p id="">You will not be authorized to view or access your inventory of parts and crates if you do not log in using a MetaMask or Venly wallet.
            <br />
            </p>
            <h3>How do I play Battle Racers?</h3>
            <p id="">Enter the Battle Racers Early Access build and hop on inside the giant car - you can’t miss it!
              <br />
              <br />
              Use your mouse and the WASD keys on your keyboard to explore your surroundings. You’ll see different track tables, where you can race, and garage kiosks, where you can see your inventory of the 6 default basic cars that you can freely race with (also known as test drives), and your own practice car.
              <br />
            </p>
            <h3>What is a practice car?</h3>
            <p id="">A practice car is composed of any car parts that you own on the sidechain. Each car is made up of four (4) parts: Front, Body, Rear, and Wheels. Once you have one of each type, they can be combined together as a car. A default part will be selected for any part types that you do not own. The stats of the car will be based on the cumulative stats of each of the parts used. Learn more about car customization <a href="https://medium.com/battle-racers/sneak-peek-car-customization-27d7e267ea47">here</a>.
              <br />
            </p>
            <h3>What are car parts and where can I get them?</h3>
            <p id="">Car parts are ERC-20 tokens that are initially minted on the Matic Chain. You can get parts by trading with other players (see Parts Trading sections below) and by opening crates, which you can either win from competitive racing (coming soon) or buy from our website during seasonal sales. Each part has its own stats, specialties, and rarities, so you can build for aesthetics or gameplay - it’s up to you.
            <br />
            </p>
            <h3>Can I sell my duplicate parts?</h3>
            <p id="">Yes, you can sell any duplicate or unwanted parts. Parts that are on the main Ethereum network can be <a href="https://medium.com/battle-racers/parts-trading-launch-next-week-tournaments-and-a-giveaway-1d79b9f18241" target="_blank" rel="noopener noreferrer">traded on OpenSea</a>, while parts on the Matic Chain can be <a href="https://medium.com/battle-racers/trade-your-car-parts-on-arkane-e05035acd162" target="_blank" rel="noopener noreferrer">traded on the Venly Market</a>. See Parts Trading sections below.
            <br />
            </p>
            <h3>Can I combine parts from different car sets?</h3>
            <p id="">You can mix and match all parts across all brands and models - e.g. you can have Vista wheels on a Zeta body. Learn more about our car brands <a href="https://medium.com/battle-racers/sneak-peek-cars-f43d5762121a" target="_blank" rel="noopener noreferrer">here</a>.
            <br />
            </p>
            <h3>How do I know which car parts I should use?</h3>
            <p id="">Aside from the cosmetic differences, all part types carry stats based on their specialties that affect the racing performance of your car. Every part has weight, as well as at least two (2) stats, which are dependent on what type of part it is. The values of these stats are given when the part is minted with some variance. Learn more about car parts <a href="https://medium.com/battle-racers/sneak-peek-car-parts-a8fadf39e9d8" target="_blank" rel="noopener noreferrer">here</a>.
            <br />
            </p>
            <h3>What’s the difference between rarities?</h3>
            <p id="">Eventually, each car part can be upgraded in level, with higher levels meaning higher stats. Rarity determines the maximum level to which a part can be upgraded. Higher rarities have higher level caps.
            <br />
            <br />
            Parts with the Elite tag will have a slightly higher upgrade ceiling than normal. You have a 1.5% chance to get the Elite tag on your parts.
            <br />
            <br />
            The highest-level rarity (Legendary) has the highest level cap. Learn more about rarity and level caps <a href="https://medium.com/battle-racers/sneak-peek-car-parts-a8fadf39e9d8" target="_blank" rel="noopener noreferrer">here</a>.
            <br />
            </p>
            <h3>How do I build my own car?</h3>
            <p id="q5">Approach one of the garage kiosks to select or build a car. The seventh and last car slot in the garage in Early Access is your practice car, and there will be a menu where you can choose parts from the sidechain that you want to race with.
            <br />
            <br />
            In the Early Access demo, basic common parts from the Hyperion brand can fill in part slots which you may not own a part for. Once you’re happy with your car, click SAVE: move on over to a podium on one of the two tracks and test-drive your creation! Learn more about car customization <a href="https://medium.com/battle-racers/sneak-peek-car-customization-27d7e267ea47">here</a>.
              <br />
            </p>
            <h3>Do I need to own a race car to play Battle Racers?</h3>
            <p id="q7">You can play <i>Battle Racers</i> even if you don’t own a race car! We want to create a gaming experience where even non-NFT owners can enjoy the game. The Early Access demo comes with a standard car that you can test-drive. Test-drive cars will not show up in anyone’s parts inventory; and the same set of cars will be available for everyone.
              <br />
            </p>
            <h3>How do I start racing?</h3>
            <p id="">Walk up to an empty podium in front of a track and interact with it to join a race. Choose your car and the weapons to equip on it, click Join, and wait for other players. Once the race begins, your cars will automatically accelerate, but you will be able to use skills based on the weapons you have selected. The race ends when all players have completed the required number of laps, and the racer who reaches the finish line in the shortest amount of time wins.
            <br />
            </p>
            <h3>How many players can play at the same time?</h3>
            <p id="">We currently have two tracks inside the arena. Each track can have a max of 4 players in a real-time multiplayer race. If there are no other players available, AI will automatically take the other slots so you can always test your practice cars against opponents.
            <br />
            </p>
            <h3>What are weapons?</h3>
            <p id="">Weapons are additional items that you equip before a race. They are not permanently attached to any car and can be considered as equipment (if this were a role-playing game, your car is your character, and weapons are the gear shared across different characters).
            <br />
            <br />
            Weapons have skills attached to them; they include offensive (e.g. missiles), defensive (e.g. shields), position-based (e.g. boosts), and traps (e.g. bear traps). Skills add player interaction so it’s not just a matter of who has the fastest car - it’s also who can outsmart their opponents.
            <br />
            </p>
            <h3>How do I use the skills from my weapons?</h3>
            <p id="">Every player needs to equip two weapons before racing. During the race, click on your skill button on the center of the screen to activate it; each skill will have a cooldown before you can use it again. Cooldown length varies per skill.
            <br />
            </p>
            <h3>Where can I get more weapons?</h3>
            <p id="">Right now, weapons cannot be bought or traded, and can only be selected from the same pool as every player. Watch out for our weaponry systems update in the future.
            <br />
            </p>
            <h3>Can I earn money by playing Battle Racers?</h3>
            <p id="q9">Once we release competitive races, you will be able to earn our in-game currency called $SCRAP, an ERC-20 token minted on the Matic Chain. $SCRAP can also be obtained by salvaging your car parts. (See $SCRAP section below.)
              <br />
            </p>
            <h3>What is decentralized about Battle Racers?</h3>
            <p id="q10">
              The race cars are non-fungible tokens that the players will own separate from the game. While <i>Battle Racers’</i> gameplay data will be centralized (to support real-time play), the token itself will be a standard ERC-721 token, and its stats, ownership, and race record will be publicly viewable on the blockchain mainnet. This will enable other blockchain games to use the cars if they decide to support it. We will also release public APIs around the tokens and gameplay data so the community can create their own tools and games.
              <br />
              <br />
             Decentraland is a decentralized virtual world where no one company has authority over the land; decision-making will be left up to the community. For Battle Racers, our long-term plan is to let players build and host tracks on their parcels, turning this into a community-created and community-run game.
              <br />
            </p>


            {/* <div className="h-diamond text-center d-flex align-content-center mb-5"><h2 className="text-center mb-5">Weekly Duels</h2></div>
            <h3>What are the Weekly Duels?</h3>
            <p id="">The Weekly Duels is a regular series of mini-tournaments where you can win prizes every week. Each week will consist of two phases: the Qualifier Time Trials and the actual Duels, which will be a single-elimination round of 1-vs-1 Best-of-3 matches. <a href="https://link.medium.com/78aKSBaAzab">Read the full mechanics here.</a>
            <br />
            </p>
            <h3>How do I qualify for the Weekly Duels?</h3>
            <p id="">During the Qualifier Trials, you can secure a spot by clocking a fast time in Track 1 of our Battle Racers arena. Screenshots of the race results should be posted on our Discord server before the cutoff every Monday, 12AM PST / 9AM CET. The top 16 players with the fastest times reported will be invited to participate in the week’s Duels. Submissions after the cutoff will count towards the following week’s Duels
            <br />
            </p>
            <h3>Can I submit multiple screenshots for the Time Trials?</h3>
            <p id="">Yes, you can submit multiple screenshots, but we'll only take one best submission per player.
            <br />
            </p>
            <h3>What happens if only a few people join? Do I automatically win if I'm the only one?</h3>
            <p id="">A minimum of four (4) players is required for the Weekly Duels to push through. Otherwise, the qualifiers will have to be extended and we will follow the schedule for the following week.
            <br />
            </p>
            <h3>What if I have the same time as another player?</h3>
            <p id="">If two or more players are tied with the same race time for the 16th slot, then a tie-breaker match will immediately take place.
            <br />
            </p>
            <h3>Do I need to purchase parts? Can I use any car?</h3>
            <p id="">You can choose from our default Test Drive car, or a customized Practice Car with parts that you own on the blockchain.
            <br />
            </p>
            <h3>Can I join succeeding tournaments (such as the Duel Cup, or the following week’s Weekly Duels) even if I don't win the Weekly Duels?</h3>
            <p id="">Yes, you can continue to join the tournaments whether you win or lose.
            <br />
            </p>
            <h3>How do I know who my opponent is?</h3>
            <p id="">The tournament brackets will be based on traditional seeding rules based on their seed order by qualifying time. It will be announced every Monday 7AM PST / 4PM CET on our official Discord server. Once it is posted, participants can schedule their duels right away and must report their Best-of-3 scores before the deadline in order to advance to the next round.
            <br />
            </p>
            <h3>What if my opponent doesn't show up?</h3>
            <p id="">Players with non-present opponents will receive a bye for the current leg and automatically advance to the next should there be evidence provided of the opponent’s commitment and non-compliance to an agreed schedule.
            <br />
            </p>
            */}



            <div className="h-diamond text-center d-flex align-content-center mb-5 mt-6"><h2 className="text-center mb-5">Battle Racers: Season 1</h2></div>
            <p id="">Battle Racers: Season 1 marked the first set of cars and parts NFTs made available after our initial item pre-sale last May 2019. It features 52 new sets of cars (6 brands with 2 new models and 4 new colors each, plus some special hidden sets!), which can be mixed and matched to your heart’s content.

            Season 1 also commemorated the Early Access release of the Battle Racers game before it’s available in Decentraland. You can race against the AI or other players using your own parts or 1 of the 6 default roster of basic cars.

            Season 1 was released on Dec. 4th, 2019, and the sale period ended on Dec. 22nd, 2019. Although its crates are no longer available for sale on battleracers.io, you can acquire individual parts (and coming soon, even crates!) via OpenSea.
            <br />
            </p>

            <h3>What happened to leftover crates that aren't sold during the crate sale?</h3>
            <p id="q12p5">After the sale period, any unsold crates have been burned.<br /></p>
            <h3>What other cryptowallets are supported by Battle Racers?</h3>
            <p id="">In addition to MetaMask, Trust and Enjin wallets are currently supported. Have a wallet you’d like to recommend for support? Send us feedback in Discord in the link below.
            <br />
            </p>



            {/* PARTS TRADING */}

            <div className="h-diamond text-center d-flex align-content-center mb-5 mt-6"><h2 className="text-center mb-5">Parts Trading - OpenSea</h2></div>
            <h3>How do I buy or sell car parts on OpenSea?</h3>
            <p id="q20">Check out the instructions from OpenSea on how to make a purchase and conduct a sale or auction <a href="https://opensea.io/faq" target="_blank" rel="noopener noreferrer">here</a>. You will need to own cryptocurrency such as ETH, DAI, or MANA in order to make a transaction and pay for the necessary gas fees.
              <br />
            </p>
            <h3>Why can’t I see all of my parts on OpenSea?</h3>
            <p id="">Parts that are newly obtained from crates are minted on the Matic Chain and will need to be transferred from the sidechain to the main Ethereum network before they can be traded or sold in the OpenSea marketplace.
            <br />
            </p>
            <h3>How do I transfer my parts across the Matic and Ethereum networks?</h3>
            <p id="">Parts can only be transferred one at a time and you need to have enough ETH in your wallet to pay for the corresponding gas fees. Check out the step-by-step guide <a href="https://medium.com/battle-racers/parts-trading-launch-next-week-tournaments-and-a-giveaway-1d79b9f18241" target="_blank" rel="noopener noreferrer">here</a>. As an alternative, you can trade parts within the Matic Chain through the Venly Market. See Venly Market section below.
            <br />
            </p>
            <h3>I’ve clicked on “Transfer” and then “Confirm” and nothing happened. What do I do?</h3>
            <p id="">You can refresh your browser and go back to the Inventory page to view all of your parts. Parts that are ready to be withdrawn will be marked as “CLAIM NOW”. Meanwhile, parts that are still pending transfer will be marked as “QUEUED”. Once on the part page, you must click “Claim Part” to proceed with the transfer. Gas fees will apply for each part that you claim.
            <br />
            </p>
            <h3>Can I cancel a part transfer?</h3>
            <p id="">You can only cancel a transfer if you have not yet clicked “Confirm” on the warning prompt before starting the transfer process. Once you’ve clicked “Confirm”, the token will already be on its way to the main Ethereum network.
            <br />
            </p>
            <h3>I was transferring my part to the main Ethereum network but the prompt says it will charge me for gas. What happens if I don’t pay this gas cost?</h3>
            <p id="">Your part will remain in the Matic-Ethereum bridge and will not be returned to your wallet until you are able to pay the gas cost.
            <br />
            </p>
            <h3>I’ve confirmed the transfer transaction in MetaMask and paid the gas cost. What happens now?</h3>
            <p id="">You now have to wait until your transaction is completed and your part is transferred safely to the main Ethereum network. Refresh the Parts in ETH tab and check your inventory in OpenSea to find your part there and start trading!
            <br />
            </p>
            <h3>MetaMask says the transaction failed, but the Battle Racers parts page still says my part is being transferred, what do I do?</h3>
            <p id="">When this occurs, please refresh your Battle Racers part page and the MetaMask popup should reappear. You can confirm the transaction again once it does. If your part is still stuck, please file a ticket through our #support channel in <a href="discord.gg/altitudegames" target="_blank" rel="noopener noreferrer">Discord</a>.
            <br />
            </p>

            <div className="h-diamond text-center d-flex align-content-center mb-5 mt-6"><h2 className="text-center mb-5">Parts Trading - Venly Market</h2></div>
             <h3>What do I need to trade on the Venly Market?</h3>
            <p id="">You will need to create an Venly wallet, which supports both Ethereum and Matic Network chains. Check out the instructions on how to connect your Matic wallet to the Venly <a href="https://medium.com/arkane-network/launching-the-matic-nft-market-with-battle-racers-2-2-2021-358da09092e0" target="_blank" rel="noopener noreferrer">here</a>.
            <br />
            </p>
            <h3>I already have a MetaMask wallet. Why do I need to create an Venly wallet?</h3>
            <p id="">Creating an Venly wallet is optional and is only needed if you want to buy or sell parts on the Matic Chain without transferring them to the Ethereum main network and paying the necessary gas fees. The Venly Market is Matic’s first NFT marketplace, and trading within the Matic Chain is not yet available in other marketplaces.
            <br />
            </p>
            <h3>Can I also play Battle Racers using my Venly wallet?</h3>
            <p id="">Yes, you can play on our Early Access demo at <a href="play.battleracers.io" target="_blank" rel="noopener noreferrer">play.battleracers.io</a> using an Venly wallet. However, playing on Decentraland is not yet supported at the moment.
            <br />
            </p>
            <h3>How do I buy or sell car parts in the Venly Market?</h3>
            <p id="">You will need to own Credits, a FIAT currency used to make transactions on the Venly Market. (1 Credit = 1 USD). Meanwhile, you will need to import the Matic wallet which contains the parts that you want to sell through the Venly website. Learn more <a href="https://medium.com/battle-racers/trade-your-car-parts-on-arkane-e05035acd162" target="_blank" rel="noopener noreferrer">here</a>.
            <br />
            </p>
            <h3>Do I need to pay ETH gas fees?</h3>
            <p id="">No, there will be no ETH gas fees since the Venly Market is currently on the Matic Chain only. Instead, there will be $MATIC gas fees, which are relatively lower. You can check the average gas costs <a href="http://explorer.matic.network/" target="_blank" rel="noopener noreferrer">here</a>.
            <br />
            </p>
            <h3>Where can I get $MATIC tokens?</h3>
            <p id="">$MATIC can be procured directly into your Matic wallet through <a href="https://transak.com/" target="_blank" rel="noopener noreferrer">Transak</a> and <a href="https://quickswap.exchange/" target="_blank" rel="noopener noreferrer">Quickswap</a>. You can opt to swap ETH to MATIC on other exchanges, however, ETH gas fees will apply to transactions made on the main Ethereum network.
            <br />
            </p>
            <h3>My parts are currently on my MetaMask wallet; can I move them to my Venly wallet?</h3>
            <p id="">Yes, you can import your MetaMask wallet to an Venly wallet to access your parts in Ethereum. You can also access parts that are on the Matic Chain by adding your Matic wallet through the <a href="https://app.arkane.network/" target="_blank" rel="noopener noreferrer">Venly website</a>. Once imported, you will be able to sell them through the Venly Market or send them to another Matic wallet without transferring to the main Ethereum main network.
            <br />
            </p>

            <div className="h-diamond text-center d-flex align-content-center mb-5 mt-6"><h2 className="text-center mb-5">$SCRAP</h2></div>
            <h3>What is $SCRAP?</h3>
            <p id="">$SCRAP is an ERC20 token on the Matic Chain which serves as Battle Racers’ progression currency.
              <br />
            </p>
            <h3>How do I know how much $SCRAP I own?</h3>
            <p id="">You will be able to view your $SCRAP count on the Battle Racers Inventory page. Just log in to the Ethereum address which contains your parts and crates using MetaMask or Venly.
              <br />
            </p>
            <h3>Where can I use $SCRAP?</h3>
            <p id="">Its primary purpose is to allow players to upgrade car parts, in which all stats of a certain part will be increased by a percentage until it reaches a maximum level. You will also be able to use $SCRAP to join competitive races. Both of these features are still in development and we will make an announcement when they are rolled out.
              <br />
            </p>
            <h3>How much $SCRAP will I need to upgrade parts?</h3>
            <p id="">It depends on the rarity of the car part that you want to upgrade.
              <br />
            </p>
            <h3>Can I win $SCRAP from Practice Races?</h3>
            <p id="">No, you will only be able to win $SCRAP rewards through competitive racing.
              <br />
            </p>
            <h3>Where can I get $SCRAP?</h3>
            <p id="">$SCRAP can later be mined through parts salvaging and competitive races, and may also be won as prizes from promotions and tournaments.
              <br />
            </p>
            <h3>On what exchanges can we buy $SCRAP and how much will it cost?</h3>
            <p id="">We will not be selling $SCRAP, so its value will be largely determined by the community. Token owners have the option to sell theirs in NFT marketplaces that support ERC20.
              <br />
            </p>
            <h3>Where can I trade $SCRAP with other people?</h3>
            <p id="">You will be able to trade the $SCRAP token within the Matic Chain through MetaMask or Venly. Corresponding MATIC gas fees will apply.
              <br />
              <br />
              We are also currently working on a bridge to transfer $SCRAP from the Matic Chain to the main Ethereum network, and vice versa. This will allow $SCRAP trading within Ethereum, and will be launched soon after the $SCRAP airdrop.
              <br />
            </p>
            <h3>Can we stake SCRAP?</h3>
            <p id="">There are no immediate plans for staking at the moment but we are exploring that option. We will make an announcement once it is available.
              <br />
            </p>





            <p>Have a question that isn’t answered here? Ask us in our <a href="https://discord.gg/gfEPSra" target="_blank" rel="noopener noreferrer">Discord</a> channel!
              <br />
            </p>
          </div>
        </section>
        <button className="back-to-top" type="button" />
      </div>
    );
  }
}
