import React, { Component } from 'react'
import Popup from 'reactjs-popup';
import Iframe from 'react-iframe';

import '../styles/scss/KyberPopup.scss';

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost'
const KYBER_PAYMENT_RECEIVE = process.env.REACT_APP_KYBER_PAYMENT_RECEIVE
const ETH_NETWORK = process.env.REACT_APP_ETH_NETWORK || "localhost";
export default class KyberPopup extends Component {

  base_url = "https://widget.kyber.network/v0.7.4/?type=pay&mode=iframe&theme=theme-emerald";
  receiveAddr = KYBER_PAYMENT_RECEIVE;
  receiveToken = "ETH";
  network = ETH_NETWORK;

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      full_url: ''
     }

    this.apiUrl = 'https://' + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }
    console.log('this.crateDiscount', this);

  }

  openModal = () => {
    this.setState({ open: true })
    window.addEventListener('message', this.handleMessage);
  }

  closeModal = () => {
    this.setState({ open: false })
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = (e) => {
    if (e.data === "CloseWidget")
      this.closeModal();
  }

  showPurchase(params) {
    // this.network = params.selectedCrate.web3state.networkType;
    const orderId = params.orderId;
    const productQty = params.crateAmt;
    console.log('params', params)
    // TODO Cost
    const receiveAmount =/*params.selectedCrate.cost;// *//*1 * productQty; //*/productQty * ((params.discountedEtherPrice));
    const callback = this.apiUrl + '/payCallback';
    const paymentData = orderId
    const full_url =
      this.base_url + '&' +
      'receiveAddr=' + this.receiveAddr + '&' +
      'receiveToken=' + this.receiveToken + '&' +
      'callback=' + callback + '&' +
      'paramForwarding=true&' +
      'receiveAmount=' + receiveAmount + '&' +
      'network=' + this.network + '&' +
      'paymentData=' + paymentData + '&'

    console.log('full_sssurl', full_url)
    this.setState({ full_url })
    this.openModal();
  }

  /* eslint-disable jsx-a11y/anchor-is-valid */
  render() {
    return (
      <Popup
        className="KyberPopup"
        contentStyle={{ width: '80%', height: '70%', border: 'none' }}
        open={this.state.open}
        closeOnDocumentClick={false}
      >
        <Iframe url={this.state.full_url}
            width="100%"
            height="100%"
            id="myId"
            className="myClassname"
            loading="Loading..."
            display="initial"
            position="relative"/>

        <a href="#" className="modalDismiss" onClick={this.closeModal}>
          &times;
        </a>

      </Popup>
    )
  }
  /* eslint-enable jsx-a11y/anchor-is-valid */
}
