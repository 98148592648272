import React, { Component } from 'react'

export default class PanelKittyTrick extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConsuming: false,
      isConsumed: false
    }

    this.onConsumeTrick = this.onConsumeTrick.bind(this);
  }

  async onConsumeTrick() {
    if (this.state.isConsuming) {
      return;
    }

    this.setState({isConsuming:true});

    var kitty = this.props.kitty;
    var res = await this.props.onConsumeTrick(kitty);

    this.setState({isConsuming:false, isConsumed: res});
  }

  renderButton() {
    if (this.props.kitty.trickConsumed || this.state.isConsumed) {
      return <button type="button" disabled className="button-common button-small">Trick Used</button>;
    }

    return <button type="submit" onClick={this.onConsumeTrick} disabled={this.state.isConsuming} className="button-common button-small">Perform Trick</button>;
  }

  render() {
    return (
      <div className="col-lg-3 col-md-6 ck panel-kitty-trick">
        <div className="col-12">
          <h4>#{this.props.kitty.id} {this.props.kitty.name}</h4>
          <img className="card-img-top w-100 mb-2" src={this.props.kitty.image_url} alt="" />
          {/*<p>{this.props.kitty.bio}</p>*/}
          {this.renderButton()}
        </div>
      </div>
    );
  }
}
