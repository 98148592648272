import React, { Component } from 'react'
import axios from 'axios';
import Web3 from 'web3';
import Loader from './Loader';

import { executeMetaTx, transactToSideChainPart, transactToSideChainBridge, getSignatureParameters } from "../helpers/meta-tx";

import PartJson from '../contracts/Part.json';
import MaticPartJson from '../contracts/BRPart.json';
import BridgeJson from '../contracts/BRNftBridge.json';
import { getSideChainConfig } from '../helpers/side-chain';


const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const METADATA_URL = process.env.REACT_APP_METADATA_URL || 'localhost';
const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true');

const ETHEREUM_NET_IDS = [1, 2, 3, 4, 42];

const sideChainConf = getSideChainConfig();
const sideChain = new Web3(sideChainConf.rpcUrl);

export default class InventoryPartsPage extends Component {
  constructor(props) {
    super(props);

    this.tokenId = this.props.match.params.tokenId;
    this.tokenURI = METADATA_URL + this.tokenId;

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }
    
    if (DEBUG_MODE) {
      this.tokenURI = window.location.protocol + "//" + SERVER_HOST + "/api/items/" + this.tokenId
    }
    
    this.state = {
      isSideChain: false,
      isLoading: true,
      inTransit: false,
      isApproving: false,
      metadata: {
        details:{}
      },
      approvalStatus: {
        sideChain: {},
        mainChain: {}
      },
      isPartOwned: false
    }

    this.onTransfer = this.onTransfer.bind(this);
    this.fetchMetadata = this.fetchMetadata.bind(this);

    this.approveSideChainBridge = this.approveSideChainBridge.bind(this);
    this.depositToMaticBridge = this.depositToSideChainBridge.bind(this);

    this.approveEthBridge = this.approveEthBridge.bind(this);
    this.depositToEthBridge = this.depositToEthBridge.bind(this);

    this.watchApprovalStatus = this.watchApprovalStatus.bind(this);
    this.watchTokenReadyness = this.watchTokenReadyness.bind(this);
    this.withdrawFromBridge = this.withdrawFromBridge.bind(this);
    this.postClaimReceipt = this.postClaimReceipt.bind(this);
    this.saveEthDepositBlock = this.saveEthDepositBlock.bind(this);
    this.cancelEthDepositBlock = this.cancelEthDepositBlock.bind(this);
    this.cancelMetadataTransit = this.cancelMetadataTransit.bind(this);

    this.cancelEthClaim = this.cancelEthClaim.bind(this);
  }

  watchApprovalStatus(){
    const { isApproving, metadata } = this.state;
    if(metadata.id){
      if(isApproving){
        this.getApprovalStatus();
      }
    }
  }

  watchTokenReadyness(){
    const { metadata, inTransit } = this.state;
    if(metadata.id){
      if(metadata.state !== "READY" || inTransit){
        this.fetchMetadata();
      }
    }
  }

  componentDidMount() {
    if(this.props.web3state.bridgeUnmapped){
      this.props.popupGeneric.current.show({
        title: "Unsupported Ethereum Network",
        body: "This website does not support the Ethereum network that you have selected. Please switch to the Main Ethereum Network in order to proceed."
      });
    } else {
      this.initLoad();
      this.approvalWatcher = setInterval(this.watchApprovalStatus, 60000);
      this.metaWatcher = setInterval(this.watchTokenReadyness, 60000);
    }
    
  }

  componentWillUnmount(){
    clearInterval(this.approvalWatcher);
    clearInterval(this.metaWatcher);
  }

  getOpenseaUrl() {
    var netId = this.props.web3state.networkId;
    var contractAddress = PartJson.networks[netId].address;
    var url = OPENSEA_URL + contractAddress.toLowerCase() + "/" + this.state.metadata.id;

    return url;
  }

  getName() {
    return !!this.state.metadata ? this.state.metadata.name : "";
  }

  getRarityClass() {
    if (!this.state.metadata.details.rarity) {
      return "";
    }

    return this.state.metadata.details.rarity.toLowerCase();
  }

  async initLoad(){
    await this.getApprovalStatus();
    await this.fetchMetadata();
  }

  async getApprovalStatus(){
    //chcek bridge approval status
    let resp = await axios.get(`${this.apiUrl}/pt-bridge/get-approval-states`);

    if(!resp) return;

    const approvalStatus = resp.data;
    let approvalMapping = {};

    const isSideChain = ETHEREUM_NET_IDS.indexOf(this.props.web3state.networkId) < 0 && !!approvalStatus.sideChain[this.props.web3state.networkId];

    if(isSideChain){
      approvalMapping = approvalStatus.sideChain;
    } else {
      approvalMapping = approvalStatus.mainChain;
    }

    if(approvalMapping[this.props.web3state.networkId] === undefined){
      approvalMapping[this.props.web3state.networkId] = await this.props.web3state
        .partContract.methods.isApprovedForAll(this.props.web3state.metamaskAccount, 
          this.props.web3state.bridgeContract.options.address).call();
    }

    const { metadata } = this.state;
    this.setState({ isApproving: (
      (metadata.chain === "ETH" && !approvalStatus.mainChain[this.props.web3state.networkId]) ||
      (metadata.chain === "LOOM" && !approvalStatus.sideChain[approvalStatus.sideChainNetId])
    ) });

    this.setState({approvalStatus, isSideChain});
  }

  async fetchMetadata() {
    this.setState({isLoading: true});
    try {
      let resp = await axios.get(`${this.apiUrl}/pt-bridge/part-status/${this.tokenId}`, 
        { params: { 
          networkId: this.props.web3state.networkId 
        }});

      if(!resp) return;

      const metadata = resp.data;

      this.setState({metadata, isLoading: false, inTransit: metadata.state === 'TRANSIT' });

    } catch (err){
      this.setState({isLoading: false });
    }
    
  }

  getCarImgUrl(id, type) {
    var carId;

    switch (type) {
      case "bumper":
        carId = id.replace("Bumper", "");
        break;
      case "casing":
        carId = id.replace("Casing", "");
        break;
      case "spoiler":
        carId = id.replace("Spoiler", "");
        break;
      case "wheels":
        carId = id.replace("Wheel", "");
        break;
      default:
    }

    return "/cars/" + carId + ".png";
  }

  approveEthBridge(){
    const { approvalStatus } = this.state;

    let approvalMapping = this.state.approvalStatus.mainChain;

    if(approvalMapping[this.props.web3state.networkId] !== true){
      this.setState({isApproving: true});

      this.props.web3state.ethPartContract.methods.setApprovalForAll( this.props.web3state.ethBridgeContract.options.address, true)
        .send({ from: this.props.web3state.metamaskAccount })
        .on('transactionHash', txHash => {
          this.setState({isApproving: true});

          this.props.popupGeneric.current.show({
            title: "Approval In Progress",
            body: "Approval may take a while to process. Please wait some time for the changes to take place."
          });
        })
        .on('receipt', (receipt) => {
          this.setState({isApproving: false});
          this.props.popupGeneric.current.show({
            title: "Approval Granted",
            body: "Your parts can now be transferred to Ethereum Main Network for trading into OpenSea.",
            callback: () => {}
          });

          const newApprovalStatus = { ...approvalStatus  };
          newApprovalStatus.mainChain[this.props.web3state.networkId] = true;

          this.setState({ approvalStatus: newApprovalStatus, 
            isSideChain: ETHEREUM_NET_IDS.indexOf(this.props.web3state.networkId) < 0 && !!newApprovalStatus.sideChain[this.props.web3state.networkId] });
        })
        .then(() => {
          this.setState({isApproving: false});
        })
        .catch(err => {
          this.setState({isApproving: false});
          this.props.popupGeneric.current.show({
            title: "Approval Cancelled",
            body: "Action for approval encountered an error.",
            callback: () => {}
          });
        });
    }

    this.setState({ isSideChain: ETHEREUM_NET_IDS.indexOf(this.props.web3state.networkId) < 0 && !!this.state.approvalStatus.sideChain[this.props.web3state.networkId] })
  }

  async depositToEthBridge(){
    const depositBlockNumber = await window.web3.eth.getBlockNumber();

    this.props.web3state.ethBridgeContract.methods.deposit(this.props.web3state.ethPartContract.options.address, this.state.metadata.id)
      .send({ from: this.props.web3state.metamaskAccount })
      .on('transactionHash', txHash => {
        this.saveEthDepositBlock(depositBlockNumber, this.state.metadata.id, true);
      })
      .then(async receipt => {
        this.saveEthDepositBlock(depositBlockNumber, this.state.metadata.id);
      })
      .catch(async err => {
        const newmeta = { ...this.state.metadata, chain: "ETH", state: "READY" };
        this.setState({inTransit: false, metadata: newmeta});

        await this.cancelEthDepositBlock(this.state.metadata.id);

        this.props.popupGeneric.current.show({
          title: "Part Transfer Failed",
          body: `An Error Has Occured on Metamask`
        });

        console.log(`An Error Has Occured: ${err.message}`);
      });
  }

  async cancelEthClaim(){
    const { id } = this.state.metadata;

    await axios.post(`${this.apiUrl}/mainchain-bridge/cancel-claim`, { id })
      .then(resp => {
        this.props.popupGeneric.current.show({
          title: "Cancel Claim Result",
          body: resp.data.result,
          callback: () => {}
        });
      })
      .catch(err => {
        console.log(`POST ${this.apiUrl}/mainchain-bridge/cancel-claim failed ${err}`);
      });
  }

  async cancelEthDepositBlock(partId){
    await axios.post(`${this.apiUrl}/mainchain-bridge/cancel-deposit`, { partId })
      .catch(err => {
        console.log(`POST ${this.apiUrl}/mainchain-bridge/cancel-deposit failed ${err}`);
      });
  }

  async saveEthDepositBlock(depositblockNumber, partId, showPrompt = false){
    await axios.post(`${this.apiUrl}/mainchain-bridge/save-deposit-block`, 
      { 
        depositblockNumber, 
        partId
      })
      .then(resp => {
        if(showPrompt){
          this.props.popupGeneric.current.show({
            title: "Part Transfer",
            body: `Your item is now in transit to Matic Network Sidechain.`
          });
        }
      }).catch(err => {
        console.log(`POST ${this.apiUrl}/mainchain-bridge/save-deposit-block failed ${err}`);
      });
  }

  async approveSideChainBridge(){
    const { metamaskAccount } = this.props.web3state;
    const { approvalStatus } = this.state;
    
    //send agnostic transaction for matic bridge approval
    const { sideChainNetId } = approvalStatus;
    const maticPartAddress = MaticPartJson.networks[sideChainNetId].address;
    const maticBridgeAddress = BridgeJson.networks[sideChainNetId].address;

    if(!maticPartAddress){
      console.log(`Part not deployed on the side chain.`);
      return;
    }

    this.setState({isApproving: true});

    const data = await sideChain.eth.abi.encodeFunctionCall({
      name: 'setApprovalForAll', 
      type: 'function', 
      inputs: [
        {
          "name": "operator",
          "type": "address"
        },
        {
          "name": "approved",
          "type": "bool"
        }
      ]
    }, [ maticBridgeAddress, true ]);

    try {
      await executeMetaTx(
        sideChain, 
        metamaskAccount, 
        maticPartAddress, 
        "Battle Racers Part", 
        "2",
        data,
        transactToSideChainPart,
        () => {
          //this.setState({isApproving: true});
        },
        () => {
          this.setState({isApproving: false});
        });
    } catch (approvalErr){
      this.setState({isApproving: false});
    }
    
  }

  async depositToSideChainBridge(){
    const { metamaskAccount } = this.props.web3state;
    const { metadata, approvalStatus } = this.state;
    
    //send agnostic transaction for matic bridge approval
    const { sideChainNetId } = approvalStatus;
    const maticPartAddress = MaticPartJson.networks[sideChainNetId].address;
    const maticBridgeAddress = BridgeJson.networks[sideChainNetId].address;

    if(!maticBridgeAddress){
      console.log(`Bridge not deployed on the side chain.`);
      return;
    }

    if(!maticPartAddress){
      console.log(`Part not deployed on the side chain.`);
      return;
    }

    const data = await sideChain.eth.abi.encodeFunctionCall({
      name: 'deposit', 
      type: 'function', 
      "inputs": [
        {
          "name": "token",
          "type": "address"
        },
        {
          "name": "tokenId",
          "type": "uint256"
        }
      ]
    }, [ maticPartAddress, metadata.id ]);

    try {
      await executeMetaTx(
        sideChain,
        metamaskAccount, 
        maticBridgeAddress, 
        "Battle Racers Matic NFT Bridge", 
        "1",
        data,
        (tx, nextFunc) => {
          transactToSideChainBridge(tx, nextFunc, metadata.id);
        },
        (response) => {
          if(response.data.result === "" && !!response.data.error){
            this.cancelMetadataTransit('LOOM');
            this.props.popupGeneric.current.show({
              title: "Part Transfer Failed",
              body: `You are going too fast! Please allow some time in between transactions before starting the next transfer.`,
              callback: () => { }
            });
          }
          
        },
        () => {
          this.cancelMetadataTransit('LOOM');
        });
    } catch(metaTxErr) {
      this.cancelMetadataTransit('LOOM');
    }
    
  }

  cancelMetadataTransit(chain){
    const newmeta = { ...this.state.metadata, chain: chain, state: "READY" };
    this.setState({inTransit: false, metadata: newmeta });
  } 

  onTransfer(event) {
    const { metadata } = this.state;

    //if metadata is being sent back to matic
    if(metadata.chain === "ETH"){
      //check first if matic bridge is already approved
      if(this.state.approvalStatus.mainChain[this.props.web3state.networkId] !== true){
        this.props.popupGeneric.current.show({
          title: "Permit Approval",
          body: "Allow Battle Racers to manage your part on your behalf to be able to send it back to Matic chain.",
          callback: this.approveEthBridge
        });
      } else {
        const newmeta = { ...this.state.metadata, chain: "LOOM", state: "TRANSIT" };
        this.setState({inTransit: true, metadata: newmeta });

        this.props.refTransferConfirm.current.show({
          title: "Transfer",
          body: "You are about to send this item to the Matic Network Sidechain.",
          item: this.state.metadata,
          confirmCallback: () => {
            this.depositToEthBridge();
          },
          cancelCallback: () => {
            const newmeta = { ...this.state.metadata, chain: "ETH", state: "READY" };
            this.setState({inTransit: false, metadata: newmeta });
          },
          successCallback: this.fetchMetadata,
          failCallback: () => {this.setState({inTransit: false})}
        });
      }

    } else {
      //part is on matic "LOOM"
      if(this.state.approvalStatus.sideChain[this.state.approvalStatus.sideChainNetId] !== true){
        this.props.popupGeneric.current.show({
          title: "Permit Approval",
          body: "Allow Battle Racers to manage your part on your behalf to be able to send it back to Ethereum chain.",
          callback: this.approveSideChainBridge
        });
      } else {
        const newmeta = { ...this.state.metadata, chain: "ETH", state: "TRANSIT" };
        this.setState({inTransit: true, metadata: newmeta });

        this.props.refTransferConfirm.current.show({
          title: "Transfer",
          body: "Warning: Please check if the item you have selected is correct and there is enough ETH in your wallet before you click Confirm. It cannot be cancelled once the transaction has started (gas fees will apply per item claimed).",
          item: this.state.metadata,
          confirmCallback: () => {
            this.depositToSideChainBridge();
          },
          cancelCallback: () => {
            const newmeta = { ...this.state.metadata, chain: "LOOM", state: "READY" };
            this.setState({inTransit: false, metadata: newmeta });
          },
          successCallback: this.fetchMetadata,
          failCallback: () => {this.setState({inTransit: false})}
        });
      }
    }
  }

  async postClaimReceipt(txHash, partId){
    const url = `${this.apiUrl}/mainchain-bridge/save-claim-tx`;

    await axios.post(url, { txHash, partId })
      .then(resp => {
        const newmeta = { ...this.state.metadata, chain: "ETH", transferrable: false, state: "CLAIMING" };
        this.setState({inTransit: true, metadata: newmeta });
      }).catch(err => {
        console.log(`POST ${url} claim tx failed ${err}`);
      });
  }

  async withdrawFromBridge(){
    const { metadata } = this.state;
    const { metamaskAccount } = this.props.web3state;
    const txDetails = metadata.metaTxData;

    console.log("txDetails", txDetails);
    if(!!txDetails && txDetails.signature){ //if(metadata.state === "ETH_AWAIT_WITHDRAW" && !!txDetails){
      await this.props.web3state.ethPartContract.methods
        .mintPartSigned(
          metamaskAccount, 
          metadata.id, 
          txDetails.tokenURI, 
          txDetails.signature)
        .send({from: metamaskAccount})
        .on('transactionHash', async txHash => {
          this.postClaimReceipt(txHash, metadata.id);
        })
        .then(receipt => {
          this.postClaimReceipt(receipt.transactionHash, metadata.id);
        });

    } else {
      const { r, s, v } = getSignatureParameters(window.web3, txDetails.intent);

      await this.props.web3state.ethBridgeContract.methods
        .executeMetaTransaction(txDetails.from, txDetails.fnSig, r, s, v)
        .send({from: metamaskAccount})
        .on('transactionHash', txHash => {
          this.postClaimReceipt(txHash, metadata.id);
        })
        .then(receipt => {
          this.postClaimReceipt(receipt.transactionHash, metadata.id);
        });

    }
  }
  
  renderTransferButton() {
    const { isSideChain, metadata } = this.state;

    if (!metadata || !metadata.owned) {
      return ""
    }

    const { state, chain } = metadata;

    if(state === "CLAIMING"){
      let cancelClaimMarkup = "";
      if(metadata.claimCancellable){
        cancelClaimMarkup = (
          <div className="parts-command">
              <button 
                className="button-common button-smaller londrina-fix text-light" 
                onClick={this.cancelEthClaim}
                >Cancel Claim</button>
            <p>Cancel claim and send Part back to Matic.</p>
          </div>
        )
      }

      return (
        <>
          <div className="parts-command">
              <button 
                className="button-common button-smaller londrina-fix text-light" 
                disabled>Claim In Progress</button>
            <p>The part is now being processed to your Ethereum account.</p>
          </div>
          {cancelClaimMarkup}
        </>
      );
    }

    if(state === "ETH_AWAIT_CLAIM"){
      return <div>The part is being queued for transfer to the Ethereum Main Network.</div>;
    }

    if (this.state.inTransit || state === "TRANSIT") {
      if (chain === "ETH") {
        return <div>The part is being transferred to the Ethereum Main Network.</div>;
      } else {
        return <div>The part is being transferred to the Matic Network Sidechain.</div>;
      }
    }

    if (this.state.isApproving) {
      if (chain === "ETH") {
        return <div>Approval for handling Parts going to Matic is in progress.</div>;
      } else {
        return <div>Approval for handling Parts going to Ethereum is in progress.</div>;
      }
    }

    if(isSideChain){
      return (
        <div>
          <button disabled
              className="button-common button-smaller londrina-fix text-light">Transfer Not Available</button>
          <p>Please switch your Metamask / Arkane network to supported Ethereum chain.</p>
        </div>
      )

    } else if(this.props.web3state.bridgeUnmapped){
      return (
        <div>
          <button disabled
              className="button-common button-smaller londrina-fix text-light">Transfer Not Available</button>
          <p>Unable to transfer this Part from current Ethereum Network. Select supported Ethereum chain.</p>
        </div>
      )
    }

    if(!!metadata.metaTxData && state === "ETH_CLAIM_READY"){ //(state === "ETH_AWAIT_WITHDRAW" || state === "ETH_AWAIT_TRANSFER")
      return (
        <div className="parts-command">
            <button 
              className="button-common button-smaller londrina-fix text-light" 
              onClick={this.withdrawFromBridge}>Claim Part</button>
          <p>The part will now be transferred to your account on the Ethereum Main Network. Claim now to proceed with the transaction (gas fees will apply).</p>
        </div>
      );
    }

    if (!metadata.transferrable) {
      return "";
    }

    if(metadata.chain === "ETH"){
      return (
        <div className="parts-command">
            <button 
              className="button-common button-smaller londrina-fix text-light" 
              onClick={this.onTransfer}>Send to Matic</button>
          <p>Bring your part to the Matic Network sidechain. This part will no longer be tradable on the Ethereum Main Network through OpenSea.</p>
        </div>
      );
    } else {
      return (
        <div  className="parts-command">
            <button 
              className="button-common button-smaller londrina-fix text-light" 
              onClick={this.onTransfer}>Send to Ethereum</button>
          <p>Bring your part to Ethereum and trade it on OpenSea.</p>
        </div>
      );
    }
  }

  renderOpenseaButton() {
    var metadata = this.state.metadata;
    var state = metadata.state;
    var chain = metadata.chain;

    if (chain === "LOOM" || state !== "READY" || this.state.isSideChain){
      
      return "";
    }

    return (
      <div className="parts-command" style={{ display: "block" }}>
        <a href={this.getOpenseaUrl()} rel="noopener noreferrer" target="_blank">
          <button disabled={this.state.isSideChain} className="button-common button-smaller londrina-fix text-light">View on OpenSea</button>
        </a>
        <p>See this part in OpenSea to start trading.</p>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    var metadata = this.state.metadata;
    var isElite = metadata.details && metadata.details.isElite;
    var renderElite;
    var renderImage;

    if (isElite) {
      renderElite = <div className="text-center d-flex parts-tag elite"><span>ELITE</span></div>;
    }

    if (metadata.image) {
      renderImage = <img className="card-img-top" id="partsImage" src={metadata.image} alt={metadata.name || ""} />
    }
    else {
      renderImage = <Loader/>
    }

    return (
      <div className="container">
        <section className="item-page d-flex flex-row mb-5 row justify-content-center flex-wrap mt-4 footer-height-f">
          <div className="InventoryPart p-1 text-deco-none m-4">
            <div className={"card list-inventory-item w-100 p-2 " + this.getRarityClass()} id="">
              <div className="d-flex flex-row flex-wrap">
                <h5 id="partNumber" className="mb-0">#{this.tokenId}</h5>
                <div className="d-flex flex-row parts-tag-group align-items-center justify-content-center ml-auto">
                  {renderElite}
                  <div className="text-center d-flex parts-tag prime"><span>{metadata.details.edition}</span></div>
                </div>
              </div>
              {renderImage}
              <div className="card-body text-center d-flex flex-column align-items-center border-top-1">
                <h5 className="card-title font-30px d-inline mb-2">{metadata.name || ""}</h5>
                <div className={"text-center d-flex rarity-tag " + this.getRarityClass() }><span>{metadata.details.rarity}</span></div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column p-3 col-md-6">
            {/*<h4 className="text-muted mb-1">{metadata.details.rarity || "Level 1/40"}</h4>*/}
            <div className="d-flex align-items-center flex-wrap pb-2">
              <h3 className="mb-0 h-blue d-inline mr-3">{metadata.name}</h3>
              <a href={this.getCarImgUrl(metadata.details.internalId, metadata.details.internalType)} target="_blank" rel="noopener noreferrer">
              <button className="button-common button-smaller londrina-fix text-light">View Complete Set</button>
            </a>
            </div>
            {/*<div className="list-inventory parts stats d-flex flex-column flex-wrap mt-2 bg-light p-3 pb-5 mb-3" id="">
            <span>Weight: <span id="statWeight">{metadata.details.weight || 0}</span></span>
            <span>Durability: <span id="statDurability">{metadata.details.durability || 0}</span></span>
            <span>Speed: <span id="statSpeed">{metadata.details.speed || 0}</span></span>
            <span>Power: <span id="statPower">{metadata.details.power || 0}</span></span>
            <span>Steering: <span id="statHandling">{metadata.details.steering || 0}</span></span>           
          </div>*/}
          <table>
              <tbody>
                <tr>
                  <th>Weight</th>
                  <td>{metadata.details.weight || 0}</td>
                </tr>
                <tr>
                  <th>Durability</th>
                  <td>{metadata.details.durability || 0}</td>
                </tr>
                <tr>
                  <th>Speed</th>
                  <td>{metadata.details.speed || 0}</td>
                </tr>
                <tr>
                  <th>Power</th>
                  <td>{metadata.details.power || 0}</td>
                </tr>
                <tr>
                  <th>Steering</th>
                  <td>{metadata.details.steering || 0}</td>
                </tr>
              </tbody>
          </table>    
          <div className="parts-commandlist d-flex justify-content-center flex-wrap text-center">
            {this.renderTransferButton()}
            {this.renderOpenseaButton()}
          </div>
          </div>

        </section>
      </div>
    );
  }
}
