import React, { Component } from 'react'
import ItemShowcase from './ItemShowcase';

export default class CrateActionShowcase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClosed: true,
      isAnimating: "",
      doneAnimating: false,
      parts: []
    }

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onTriggerOpen = this.onTriggerOpen.bind(this);
  }

  show({crateType, parts}) {
    this.crateType = crateType;
    this.setState({isClosed: false, parts: parts});
  }

  hide(e) {
    this.setState({
      isClosed: true,
      isAnimating: "",
      doneAnimating: false,
      parts: []});
  }

  // handleSubmit(e) {
  //   e.preventDefault();
  //   this.hide();
  // }

  onTriggerOpen(e) {
    e.stopPropagation();
    this.setState({isAnimating: "animate"})
    setTimeout(function() {
      this.setState({doneAnimating: true})
    }.bind(this), 900)
  }

  renderParts() {
    var parts = this.state.parts;
    var render = [];

    for (var i = 0; i < parts.length; i++) {
      render.push(
        <ItemShowcase key={i} part={parts[i]} />
      );
    }

    return render;
  }

  crateDisplay() {
    return this.state.doneAnimating ? "d-none" : "d-flex flex-row flex-wrap justify-content-center";
  }

  partsDisplay() {
    return this.state.doneAnimating ? "d-flex flex-row flex-wrap justify-content-center" : "d-none";
  }

  render() {
    return (
			<div className={"CrateAction blur-modal " + (this.state.isClosed ? "d-none" : "d-flex flex-row justify-content-center")}
      >
				<div className="modal-container text-center bg-transparent w-100">
				<h5 className="text-light display-1">Parts Obtained!</h5>

        <div className={this.crateDisplay()}>
          <div className={"crate-open " + this.crateType + " " + this.state.isAnimating} onClick={this.onTriggerOpen}></div>
        </div>

				<div className={"showcase " + this.partsDisplay()}>
					{this.renderParts()}
				</div>
        { this.state.doneAnimating ?
        <button type="submit" onClick={this.hide} className="button-common mb-5 mt-3">DISMISS</button>
        : null
        }

				</div>
			</div>
			);
  		}
}
