import React, { Component } from 'react';

import './../../styles/scss/Alpaca.scss';

export default class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: '',
      value: {},
    }
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    if (e.target.checked) {
      this.setState({isChecked: 'checked'})
    } else {
       this.setState({isChecked: ''})
    }
    if (this.props.onChange) {
      this.props.onChange();
    }
  }
  componentDidMount() {
     this.setState({value: this.props.value});
  }
  render() {
    return (<div>
          <input onChange={this.onChange} type="checkbox" checked={this.state.isChecked} /*value={JSON.stringify(this.props.value)}*//>
        </div>)
  }
}
