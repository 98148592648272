import React from 'react'
// import axios from 'axios';
import Web3 from 'web3';

import BaseConfirm from './BaseConfirm';
import Loader from '../Loader'

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost'
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

export default class TransferConfirm extends BaseConfirm {
  constructor(props) {
    super(props);

    this.web3js = null;

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }

    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }

    this.cancel = this.cancel.bind(this);
  }

  componentDidUpdate() {
    if (typeof window.web3 === 'undefined' ||
      window.web3.__isMetaMaskShim__) {
      return
    }

    if (window.web3) {
      this.web3js = new Web3(window.web3.currentProvider)
    }
  }

  show(extras) {
    // if (!extras.callback) {
    //   return;
    // }
    this.setState({
      isClosed: false,
      title: extras.title,
      body: extras.body,
      item: extras.item || null,
      warning: extras.warning,
      noButton: extras.noButton,
      confirmCallback: extras.confirmCallback,
      cancelCallback: extras.cancelCallback,
      successCallback: extras.successCallback,
      failCallback: extras.failCallback
    });
  }

  cancel() {
    this.state.cancelCallback();
    this.hide();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.state.confirmCallback();
    //this.loomToEthFirstTime();
    this.hide();
  }

  /* loomToEthFirstTime()  {
    const tokenId = this.state.item.id;
    let requestUrl = this.apiUrl + "/partTransfer";

    axios.get(requestUrl, {params: {
      tokenId: tokenId,
      dest: "ETH"
    }})
    .then(async (r) => {
      if (r.data && r.data.txnHash) {
        this.state.successCallback();
        this.props.refPendingTransfers.current.pollPending();
      }
      else {
        this.state.failCallback();
      }
    })
    .catch(e => {
      this.state.failCallback();
    });
  } */

  getRarityClass() {
    if (!("rarity" in this.state.item.details)) {
      return "";
    }

    return this.state.item.details.rarity.toLowerCase();
  }

  renderCard() {
    var metadata = this.state.item;
    if (!metadata) {
      return;
    }

    var renderImage;
    if (metadata.image) {
      renderImage = <img className="card-img-top" id="partsImage" src={metadata.image} alt={metadata.name || ""} />
    }
    else {
      renderImage = <Loader />
    }

    return (
      <div className="InventoryPart p-1 text-deco-none m-2">
        <div className={"card list-inventory-item w-100 p-2 " + this.getRarityClass()} id="">
          <div className="d-flex flex-row flex-wrap">
            <h5 id="partNumber" className="mb-0">#{this.state.item.id}</h5>
            <div className="d-flex flex-row parts-tag-group align-items-center justify-content-center ml-auto">
              <div className="text-center d-flex parts-tag prime"><span>{this.state.item.details.edition}</span></div>
            </div>
          </div>
          {renderImage}
          <div className="card-body text-center d-flex flex-column align-items-center border-top-1">
            <h5 className="card-title font-30px d-inline mb-2">{this.state.item.name}</h5>
            <div className={"text-center d-flex rarity-tag " + this.state.item.details.rarity.toLowerCase()}><span>{this.state.item.details.rarity}</span></div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
  		<div onClick={!this.state.noButton ? this.cancel : null} className={"text-center blur-modal " + (this.state.isClosed ? "d-none" : "d-flex flex-column align-items-center justify-content-center")}>
  			<div className="modal-container vertical" onClick={(e) => {e.stopPropagation();}}>
  				<h4 className="h-blue">{this.state.title}</h4>

          {this.renderCard()}

          {this.state.warning ?
            <h2 className="h-blue">- {this.state.warning} -</h2>
          : null }
  				{this.state.body}
          <p className="text-danger fontsize-normal mt-2"><strong>Warning: Please make sure that you have selected the correct item before clicking Confirm. Once this process has started, it cannot be cancelled.</strong></p>
          <form>
            {!this.state.noButton ?
            <button type="button" onClick={this.cancel} className="button-common button-smaller mt-3 ml-2 mr-2">Cancel</button>
            : null }
            {!this.state.noButton ?
            <button type="submit" onClick={this.handleSubmit} className="button-common button-smaller mt-3 ml-2 mr-2">Confirm</button>
            : null }
          </form>
  			</div>

  		</div>
  	);
  }
}
