import React, { Component } from 'react';
import { Link, Route } from "react-router-dom";
import axios from 'axios';
import '../styles/scss/AccountNav.scss';
import Web3 from 'web3'
import ScrapJson from '../contracts/Scrap.json'
import {getSideChainConfig} from '../helpers/side-chain'

// import PartJson from '../contracts/Part.json'

const sideChainConf = getSideChainConfig();
const sideChain = new Web3(sideChainConf.rpcUrl);

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';

// const METADATA_URL = process.env.REACT_APP_METADATA_URL;
const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;
const OPENSEA_COLLECTION = process.env.REACT_APP_OPENSEA_COLLECTION;
const OPENSEA_COLLECTION_URL = OPENSEA_URL + OPENSEA_COLLECTION;
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;
const NavLink = ({ label, to, activeOnlyWhenExact }) => (
  <Route
    path={to}
    exact={activeOnlyWhenExact}
    children={({ match }) => (
      <Link className={(match ? "active " : "")} to={to}>
        <span>{label}</span>
      </Link>
    )}
  />
);

export default class AccountNav extends Component {
  constructor(props) {
    super(props);

    if (window.web3) {
      this.web3js = new Web3(window.web3.currentProvider)
    }

    this.state = {
      hasPendingMint: true,
      pendingTxnHash: null,
      isLoading: false,
      numberOfAjaxCAllPending: 1,
      scrapBalance: "-"
    }

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }


    this.setTxnHash = this.setTxnHash.bind(this);


    /*this.requestInterceptors = axios.interceptors.request.use(request => {
      request.headers =  { Authorization: localStorage.token};
      this.setState({numberOfAjaxCAllPending: this.state.numberOfAjaxCAllPending += 1});
      return request
    })
    this.responseInterceptors = axios.interceptors.response.use(response => {
      this.setState({numberOfAjaxCAllPending: this.state.numberOfAjaxCAllPending -= 1});
      if (response.data.isLogout && window.location.href.indexOf('login') === -1) {
          // this.props.loginStateHandler(false);
          window.location = '/login';
          throw new axios.Cancel('Please, Login.');
      }
      //this.props.loginStateHandler(true);
      return response
    });*/
    setTimeout(() => {
      this.setState({numberOfAjaxCAllPending: 0});
    }, 2000);
  }

  componentDidMount() {
    this.checkLogin();
    this.getTxnHash();
    this.updateScrapBalance();
  }
  componentWillUnmount() {
    //axios.interceptors.request.eject(this.requestInterceptors);
    //axios.interceptors.response.eject(this.responseInterceptors);
  }

  getAddressFromJson(contractJson, networkId)
  {
    var contractNetworks = contractJson.networks;

    if (contractNetworks[networkId] !== undefined)
      return (contractNetworks[networkId].address)

    return null
  }

  async updateScrapBalance() {
    // console.log("sideChainConf", sideChainConf);
    var networkId = sideChainConf.networkId
    // console.log("netid", networkId);
    var contractAddress = this.getAddressFromJson(ScrapJson, networkId)
    // console.log("address", contractAddress);
    var contract = new sideChain.eth.Contract(ScrapJson.abi, contractAddress)
    // console.log("getBalance", this.props.web3state.metamaskAccount);
    var scrapBalance = await contract.methods.balanceOf(this.props.web3state.metamaskAccount).call()

    this.setState({scrapBalance});
  }

  async getTxnHash() {
    var requestUrl = this.apiUrl + "/getTxnHash";
    //var address = this.props.web3state.metamaskAccount;

    axios.get(requestUrl)
    .then((r) => {
      var pending = r.data;

      if (pending.parts) {
        this.setState({pendingTxnHash: null});
        this.props.popupShowcase.current.show(r.data);
      }
      // Keep polling if there's a timestamp
      else if (pending.timestamp || pending.txnHash) {
        this.setState({
          pendingTimestamp: pending.timestamp,
          pendingTxnHash: pending.txnHash
        });

        setTimeout(()=>{this.getTxnHash();}, 45000 );
      }
    });
  }

  setTxnHash(txnHash) {
    this.setState({pendingTxnHash: txnHash})
    this.getTxnHash();
  }
  async checkLogin() {
    //if (localStorage.token !== undefined && window.location.href.indexOf('login') === -1)
    //var address = this.props.web3state.metamaskAccount;
    // var resp = await axios.get(this.apiUrl + '/checkLogin', {params: {address: address}});
  }
  getEtherScanURL() {
    if (!this.state.pendingTxnHash)
      return null;

    var baseUrl;
    var networkId = this.props.web3state.networkId;

    console.log("AccountNav", networkId);

    if (networkId) {
      switch (networkId) {
        case 1:
          baseUrl = "https://etherscan.io";
          break;
        case 3:
          baseUrl = "https://ropsten.etherscan.io"
          break;
        case 4:
          baseUrl = "https://rinkeby.etherscan.io"
          break;
        default:
      }

      console.log("baseUrl", baseUrl);

      if (!baseUrl) {
        return null;
      }

      return baseUrl + "/tx/" + this.state.pendingTxnHash;
    }
    return null;
  }

  onDisconnect = async () => {
    this.props.confirmGeneric.current.show({
      title: "Confirmation",
      body: "Are you sure you want to disconnect this address?",
      onConfirm: async () => {
        if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.close) {
          await window.web3.currentProvider.close();
        }
      },
      onCancel: () => {}
    });
    
  }

  renderPendingTxn() {
    if (!this.state.pendingTxnHash) {
      return "";
    }

    var etherScanUrl = this.getEtherScanURL();
    //
    return (
      <div className="pending-tx">
        <div className="hash">
          <span>Pending Tx:</span>
          <span className="mr-2">{this.state.pendingTxnHash}</span>
        </div>
        <a href={etherScanUrl} className="p-1 btn btn-warning text-light" target="_blank" rel="noopener noreferrer">View on Etherscan</a>
      </div>
    );
  }

  render() {

    return (
      <div className="AccountNav"  style={{paddingTop:((this.state.numberOfAjaxCAllPending > 0 || !this.props.web3state.ready))?"50px":"0px"}}>
        <section className="container mt-70px"  style={{display:((this.state.numberOfAjaxCAllPending > 0 || !this.props.web3state.ready))?"none":"block"}}>
          <div className="account-nav-wrapper pt-5 pb-5">
            <div className="account-nav d-flex flex-column align-items-center justify-content-center flex-wrap">
              <div className="user-id overflow-wrap mb-3">
                <div className="row mb-2">
                  <div className="table-content col-md-9">
                    <div className="table-header">
                      <h5>Ethereum Address</h5>
                    </div>
                    <div className="table-data">
                      <span className="mr-2">{this.props.web3state.metamaskAccount}</span>
                      <button className="dropdown button-common button-smaller">- - -<button type="button" className="ml-auto button-common button-smaller" onClick={this.onDisconnect}>{'Disconnect'}</button></button>
                      
                    </div>
                  </div>
                  <div className="table-content col-md-3">
                    <div className="table-header mb-0">
                      <h5><img src={require("../images/ui/icon-scrap.png")} alt="" className="img-fluid"/>$SCRAP</h5>
                      <span className="fas fa-info-circle tip"><span className="tip-content">$SCRAP is an ERC20 token minted on the Matic Chain. It is the Battle Racers progression currency that can be used to upgrade parts and enter competitive races.</span></span>

                    </div>
                    <div className="table-data">
                      <span>{this.state.scrapBalance}</span>
                    </div>
                  </div>
                </div>
                {/*<div className="email-field ml-auto d-flex flex-column align-items-end">
                  <span className="mb-1">Current Owned: </span>
                  <span>Current Server Crate Allowance: </span>
                </div>*/}
                <div className="d-flex flex-column align-items-center justify-content-center">

                </div>
              </div>

              {/*<div>
                <div>Race with Test-drive Cars for free in our Early Access build!</div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <a href="https://play.battleracers.io" className="button-common londrina-fix text-light" target="_blank" rel="noopener noreferrer" >Play Now!</a>
                </div>
              </div>*/}
            </div>
            {this.renderPendingTxn()}
          </div>
          {/* toggle active class from parts/crates */}
          <nav className="navbar-inventory-nav mb-4">
            <span className="tip"><NavLink label="Parts" to="/inventory" activeOnlyWhenExact={true} /><span className="tip-content">Your Battle Racers car parts on the Matic Chain. Parts received from opening crates can be found here.</span></span>
            <span className="tip"><NavLink label="Parts in ETH" to="/inventory/market" activeOnlyWhenExact={true} /><span className="tip-content">Your Battle Racers car parts on the Ethereum main network. Parts purchased from Ethereum-based marketplaces (such as OpenSea) can be found here.</span></span>
            <span className="tip"><NavLink label="Crates" to="/inventory/crates" activeOnlyWhenExact={true} /></span>
            <span className="tip"><a href="https://venly.market/search?contractName=Battle%20Racers"><img className="h-80" src={require("../images/icons/logo-venly-xs.png")} alt="" height="20"/></a></span>
            <span className="tip"><a href="https://opensea.io/collection/battle-racers"><img className="h-80" src={require("../images/misc/opensea-logo-full-colored-blue.png")} alt="" height="20"/></a></span>
          </nav>
        </section>
      </div>
    );
  }
}
