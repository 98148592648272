import React, { Component } from 'react';
import { Route } from "react-router-dom";
import axios from 'axios';

// import PresaleTest from './PresaleTest';
import Inventory from './Inventory';
import InventoryCrates from './InventoryCrates';
import InventoryPartsPage from './InventoryPartsPage';
import AccountNav from './AccountNav';
import PendingTransfers from './PendingTransfers';
// import CkTest from './CkTest'
// import PromoTemplate from './PromoTemplate';

import Loader from './Loader';
// import Presale from './Presale';
import '../styles/scss/style.scss';
import '../styles/scss/Inventory.scss';
import '../styles/scss/media-queries.scss';

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

export default class InventoryWrapper extends Component {
  constructor(props) {
    super(props);

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }

    this.lastRequest = null;
    this.lastRequestId = null;

    this.getParts = this.getParts.bind(this);
    this.performGetParts = this.performGetParts.bind(this);
  }

  getParts(chain, userAccount, lastTokenId, cb) {
    let newRequestId = `${chain}/${userAccount}/${lastTokenId}`;

    if(this.lastRequestId !== newRequestId){
      this.lastRequestId = newRequestId;

      if(this.lastRequest){
        clearTimeout(this.lastRequest);
      }

      this.lastRequest = setTimeout(function(){
        this.performGetParts(chain, userAccount, lastTokenId, cb);
      }.bind(this), 1000 + Math.random(1500));
    }
    
  }

  async performGetParts(chain, userAccount, lastTokenId, cb){
    try {
      let resp = await axios.get(this.apiUrl + "/getParts", {params: {
        chain,
        address: userAccount,
        lastTokenId,
        pageSize: 100
      }}, {withCredentials: true});
  
      cb(resp);

      this.lastRequestId = "";
    } catch(err){
      //console.log(err);

      //retry
      this.lastRequest = setTimeout(function(){
        this.performGetParts(chain, userAccount, lastTokenId, cb);
      }.bind(this), 1000 + Math.random(1500));
    }
    
  }

  render () {
    if (typeof window.web3 === 'undefined') {
      return (<div style={{marginTop:'120px'}}><Loader/></div>)
    }
    //console.log('this.props.web3state.ready', this.props.web3state.ready);
    if (!this.props.web3state.ready) {
      return (<div style={{marginTop:'120px'}}><Loader/></div>)
    }
    return (
      <div>
        <Route path="/(inventory|parts)" render={props => (

            <PendingTransfers
              ref={this.props.refPendingTransfers}
              web3state={this.props.web3state}
              popupShowcase={this.props.popupShowcase}
              {...props}
            />

        )} />
        <Route path="/(inventory|parts)" render={props => (
          <AccountNav
            ref={this.props.accountNav}
            web3state={this.props.web3state}
            popupShowcase={this.props.popupShowcase}
            refPendingTransfers={this.props.refPendingTransfers}
            confirmGeneric={this.props.confirmGeneric}
            {...props}
          />
        )} />
        <Route exact path="/inventory" render={props => (
          <Inventory
            getParts={this.getParts}
            web3state={this.props.web3state}
            popupGeneric={this.props.popupGeneric}
            chain="LOOM"
            {...props} />

        )} />
        <Route exact path="/inventory/market" render={props => (
          <Inventory
            getParts={this.getParts}
            web3state={this.props.web3state}
            popupGeneric={this.props.popupGeneric}
            chain="ETH"
            {...props} />
        )} />
        <Route path="/inventory/crates" render={props => (
          <InventoryCrates
            ref={this.props.inventoryRef}
            web3state={this.props.web3state}
            popupShowcase={this.props.popupShowcase}
            popupGeneric={this.props.popupGeneric}
            accountNav={this.props.accountNav}
            {...props} />

        )} />
        <Route path="/parts/:tokenId" render={props => (
          <InventoryPartsPage
            web3state={this.props.web3state}
            popupGeneric={this.props.popupGeneric}
            refTransferConfirm={this.props.refTransferConfirm}
            {...props}
          />
        )} />

        {/*<Route exact path="/season1" render={props => (
          <Presale
            web3state={this.props.web3state}
            referrer={this.props.referrer}
            {...props}/>
        )} />*

        <Route path="/kitty_challenge" render={props => (
          <PromoTemplate
            web3state={this.props.web3state}
            {...props}
          />
        )} />*/}
      </div>
    );
  }
}
