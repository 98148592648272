import React, { Component } from 'react'

export default class MetamaskInst extends Component {
  render() {
    return (
          <div className="container">
            <div className="row d-flex flex-row align-items-center justify-content-center text-left getting-started-content">
              <div className="card metamask mm1">
                <img className="card-img-top" src={require("../images/presale/mm-1.jpg")} alt="" />
                <div className="card-body p-2 pt-3">
                  <h5 className="card-title">1. Set up MetaMask</h5>
                  <p className="card-text text-rem7">Install the <a href="https://metamask.io/">MetaMask</a> extension on your browser</p>
                </div>
              </div>
              <div className="card metamask enjin1">
                <img className="card-img-top" src={require("../images/presale/mm-1b.jpg")} alt="" />
                <div className="card-body p-2 pt-3">
                  <h5 className="card-title">1. Set up Enjin</h5>
                  <p className="card-text text-rem7">Install the <a href="https://play.google.com/store/apps/details?id=com.enjin.mobile.wallet&hl=en">Enjin</a> wallet app on your device.</p>
                </div>
              </div>
              <div className="card metamask mm2">
                <img className="card-img-top" src={require("../images/presale/mm-2.jpg")} alt="" />
                <div className="card-body p-2 pt-3">
                  <h5 className="card-title">2. Create a wallet</h5>
                  <p className="card-text">Create or connect an Ethereum wallet to MetaMask</p>
                </div>
              </div>
              <div className="card metamask enjin2">
                <img className="card-img-top" src={require("../images/presale/mm-2.jpg")} alt="" />
                <div className="card-body p-2 pt-3">
                  <h5 className="card-title">2. Create a wallet</h5>
                  <p className="card-text">Create or connect an Ethereum wallet to Enjin</p>
                </div>
              </div>
              <div className="card metamask mm3">
                <img className="card-img-top" src={require("../images/presale/mm-3.jpg")} alt="" />
                <div className="card-body p-2 pt-3">
                  <h5 className="card-title">3. Connect MetaMask</h5>
                  <p className="card-text">Make sure you are logged in on the Main Ethereum network</p>
                </div>
              </div>
              <div className="card metamask enjin3">
                <img className="card-img-top" src={require("../images/presale/mm-3b.jpg")} alt="" />
                <div className="card-body p-2 pt-3">
                  <h5 className="card-title">3. Connect Enjin</h5>
                  <p className="card-text">Make sure you are logged in on the Main Ethereum network</p>
                </div>
              </div>
              <div className="card metamask">
                <img className="card-img-top" src={require("../images/presale/mm-4.jpg")} alt="" />
                <div className="card-body p-2 pt-3">
                  <h5 className="card-title">4. Buy crates</h5>
                  <p className="card-text" />
                </div>
              </div>
            </div>
          </div>
          );
  }
}
