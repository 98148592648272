import React from 'react'
import { Link } from "react-router-dom";
import Crate from './Crate';

import '../styles/scss/InventoryCrate.scss';

export default class ItemInventoryCrates extends Crate {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    /* this.grantAllowance = this.grantAllowance.bind(this); */
    this.onCrateOpened = this.onCrateOpened.bind(this);

    var self = this;

    this.crateChecker = setInterval(async function() {
        await self.getCratesOwned();
    }, 5000);
    //console.log(this.updateTotalCrates, this.state.cratesOwned)
  }
  componentWillUnmount() {
    clearInterval(this.crateChecker);
    this.crateChecker = null;
  }
  async onCrateOpened(crateType, parts) {
    await this.getCratesOwned();
    /* await this.getSystemAllowance(); */

    this.props.popupShowcase.current.show({crateType, parts});
  }

  /* async grantAllowance(grantAmt) {
    var contract = this.getContract();
    var userAccount = this.props.web3state.metamaskAccount;

    try {
      this.setState({"isGranting": true});

      this._alert("Granting started. Please wait.", 'info');
      await contract.methods.approve(ETH_SYSTEM_ACCOUNT, grantAmt).send({from: userAccount});

      await this.getSystemAllowance();

      this._alert("Allowance granted.", 'info');
      this.setState({"isGranting": false});
    }
    catch (e) {
      this.setState({"isGranting": false});
      this._alert("Transaction cancelled.", "error");    }
  } */

  async onClick(e) {
    e.preventDefault();

    // TEMP: Disable opening from frontend
    // this.props.popupGeneric.current.show({
    //   title: "Coming Soon!",
    //   body: "Crate opening is temporarily disabled. Please come back later."
    // });
    // return;


    if (!this.state.isReady) {
      return;
    }

    if (this.state.isGranting) {
      this.props.popupGeneric.current.show({
        title: "Granting crate allowance...",
        body: <p>Thank you for confirming the crate allowance transaction.</p>,
        warning: "Please wait for the crate allowance transaction to finish before opening your crates."
      });
      return;
    }

    if (this.props.web3state.networkId && this.props.web3state.metamaskAccount) {
      if (this.state.cratesOwned === "0") {
        this.props.popupGeneric.current.show({
          title: "No crates owned!",
          body: ''
        });
        return;
      }

      const web3 = this.connectToEth();

      console.log("REACT_APP_ETH_SYSTEM_ACCOUNT", process.env.REACT_APP_ETH_SYSTEM_ACCOUNT);
      console.log("REACT_APP_ETH_SYSTEM_ACCOUNT", process.env.REACT_APP_ETH_SYSTEM_ACCOUNT && web3.utils.toChecksumAddress(process.env.REACT_APP_ETH_SYSTEM_ACCOUNT));
      this.props.popupOpen.current.show({
        crateType: this.props.crateType,
        crateContract: this.getContract(),
        cratesOwned: this.state.cratesOwned,
        onCrateOpened: this.onCrateOpened,
        genericPopup: this.props.popupGeneric,
        systemAccount: process.env.REACT_APP_ETH_SYSTEM_ACCOUNT && web3.utils.toChecksumAddress(process.env.REACT_APP_ETH_SYSTEM_ACCOUNT)
      });

      /* if (this.state.systemAllowance !== "0") {
        this.props.popupOpen.current.show({
          crateType: this.props.crateType,
          cratesOwned: this.state.cratesOwned,
          onCrateOpened: this.onCrateOpened,
          genericPopup: this.props.popupGeneric
        });
      }
      else {
        if (this.props.name === "Axie Crate" || this.props.crateType === "s1ck")
        {
          this.props.popupAllowance.current.show({
            grantAllowance: this.grantAllowance,
            grantAmount: 3,
            crateName: this.props.name
          });
        }

        else
        {
          this.props.popupAllowance.current.show({
            grantAllowance: this.grantAllowance,
            crateName: this.props.name
          });
        }
      } */
    }
  }
  renderCrate() {

  }

  render() {
    if (this.props.image !== undefined && this.props.name !== undefined
        && (/*(this.props.name !== "Axie Crate"  &&  this.props.name !== "S1 Axie Crate") && */Number(this.state.cratesOwned) > 0)) {
      return (
      <Link to="/Crate" onClick={this.onClick} className="InventoryCrate col-6 col-md-3 mb-3">
        <div className="p-3" id="">
          <div className="p-3 b-1">
          <img className="img-fluid" src={require("../images/presale/" + this.props.image)} alt={this.props.name} />
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center pb-1">
            <h4 className="mb-0 text-dark">{this.props.name}</h4>
            <span className="ml-auto text-muted">Qty: {this.state.cratesOwned}</span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center">
          {/* <span className="text-muted small">Crate Allowance: {this.state.systemAllowance}</span> */}
          </div>
        </div>
      </Link>
      )
    }

    return (null)
    //
    // return (
		// 	<Link to="/Crate" onClick={this.onClick} className="InventoryCrate col-6 col-md-3 mb-3">
    //   {this.props.image !== undefined && this.props.name !== undefined
    //     && ((this.props.name !== "Axie Crate"  &&  this.props.name !== "S1 Axie Crate") || this.state.cratesOwned > 0)?
    //     <div className="p-3" id="">
    //       <div className="p-3 b-1">
    //       <img className="img-fluid" src={require("../images/presale/" + this.props.image)} alt={this.props.name} />
    //       </div>
    //       <div className="d-flex flex-row align-items-center justify-content-center pb-1">
    //         <h4 className="mb-0 text-dark">{this.props.name}</h4>
    //         <span className="ml-auto text-muted">Qty: {this.state.cratesOwned}</span>
    //       </div>
    //       <div className="d-flex flex-row align-items-center justify-content-center">
    //       <span className="text-muted small">Crate Allowance: {this.state.systemAllowance}</span>
    //       </div>
    //     </div>
    //   : null }
    //   </Link>
    // );
  }
}
