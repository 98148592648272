import React, { Component } from 'react'
import {Link} from 'react-router-dom';

import '../styles/scss/GameInfo.scss';

export default class GameInfo extends Component {
  render() {
    return (
      <div className="GameInfo">
        <title>Battle Racers - Game Info</title>
        <header className="section-header header-main container-fluid parallax-window game-info" data-parallax="scroll" data-image-src="#">
          <div className="container">
            <div className="d-flex flex-column align-items-left justify-content-center h-100">
              <div className="header-main-headline text-light active">
                <h1>What is Battle Racers?</h1>
                <p>Battle Racers is an action-packed arcade game where you build, race, and battle model cars on arcade-sized tracks.</p>
              </div>
              <Link to="/faq" className=""><button className="button-common mb-5 mt-5 londrina-fix text-light">Frequently Asked Questions</button></Link>
            </div>
          </div>
        </header>
        <section className="section-build container-fluid ptpb-100px">
          <div className="container">
            {/* <div class="h-diamond text-center d-flex align-content-center mb-5"><h2 class="text-center mb-5">SAMPLE HEADER</h2></div> */}
            <p />
            <div className="row mb-5 img-layout module">
              <div className="col-md-6"><img src={require("../images/home/mix-and-match.jpg")} alt="" /></div><div className="col-md-6 d-flex flex-row align-items-center"><div><h3>Create a car by mixing and matching parts in our Garage</h3><p>Each car is made up of four parts: Body, Front, Back, and Wheels.</p></div></div>
            </div>
            <div className="row mb-5 img-layout module">
              <div className="col-md-6 order-md-2"><img src={require("../images/game-info/game-info-2.jpg")} alt="" className="mb-2" /></div><div className="col-md-6 order-md-1 d-flex flex-row align-items-center"><div><h3>Different parts have different stats and skills</h3><p> Do you choose speed over handling? Firepower or defense? Build the perfect car to match your playstyle!</p></div></div>
            </div>
            <p>You can get parts and weapons by purchasing loot crates, winning them from competitive races, or breaking down race cars. A car is considered a practice car unless you choose to tokenize it into a race car on the blockchain.</p>
          </div>
        </section>
        <section className="section-race container-fluid custombg ptpb-100px">
          <div className="container">
            <h2 className="mb-4">Race in real-time against other players in Decentraland</h2>
            <div className="row img-layout">
              <div className="col-md mb-2 module"><img src={require("../images/game-info/game-info-3.jpg")} alt="" /></div>
            </div>
            <p>Enter the Battle Racers arena on Decentraland - a giant car with multiple arcade-sized tracks. You can’t miss it!</p>
            <p>In the arena, you can join practice or competitive tracks, or simply hang out and watch the action. </p>
            <p>Practice tracks are free to play, and are only for off-chain practice cars. Don’t own a car? We’ll lend you one in Decentraland to test drive!</p>
            <p>Competitive tracks have a small entry fee (to be determined), and are only for tokenized race cars. Win enough competitive races to earn a loot crate of parts.</p>
          </div>
        </section>
        <section className="container ptpb-100px">
          <h2 className="mb-4 h-blue">Earn fame and fortune by tokenizing your car on the blockchain</h2>
          <p>Want to battle it out on the competitive tracks? You can turn your practice car into a race car: making it a a non-fungible token that’s permanently tracked on the blockchain.</p>
          <p>Race cars have an immutable record of their win/loss history, adding to their value and prestige. Plus, they earn more points and have better stats the more they race compared to practice cars!</p>
          <p>Tokenizing your car also turns it into a collectible that can be traded to other players.</p>
        </section>
        <button className="back-to-top" type="button" />
      </div>
    );
  }
}
