
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class AlpacaNavigation extends Component {

  constructor(props) {
 	super(props);
 	this.state = {
      isLogin: false
    };


 }

 render() {
	return (<ul id="crateTabs" className="nav-admin nav-pills nav-justified mt-5" styles={{marginBottom: "100px"}}>
		<li className="nav-item-admin">
	    <Link to="/Alpaca/AlpacaUsers"><div className="nav-item-admin d-flex flex-column align-items-center justify-content-center border-blue" ><button className="btn btn-success" >Alpaca Users</button></div></Link>
	  </li>
	  <li className="nav-item-admin">
	    <Link to="/Alpaca/Alpaca"><div className="nav-item-admin d-flex flex-column align-items-center justify-content-center border-blue" ><button className="btn btn-success" >Alpaca</button></div></Link>
	  </li>
	  <li className="nav-item-admin">
	    <Link to="/Alpaca/AlpacaTokenStatus"><div className="nav-item-admin d-flex flex-column align-items-center justify-content-center border-blue" ><button className="btn btn-success" >Alpaca Token Status</button></div></Link>
	  </li>
	</ul>);
  }
};
