import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../styles/scss/FAQ.scss';

export default class Faq extends Component {
  render() {
    return (
      <div className="FAQ">
        <title>Battle Racers - Frequently Asked Questions</title>
        <section class="section-header faq header-main container-fluid">
          <div className="container d-flex align-items-center justify-content-center h-100">
            <div className="header-main-headline text-center">
              <h1>Development Timeline</h1>
              <p></p>
            </div>
          </div>
        </section>
        <section className="section-founders container-fluid ptpb-100px">
          <div className="container module faq-content">
          <table classname="roadmap w-100">
          <tr>
            <td className="year"><h3>2018</h3></td>
            <td className="pb-5">
                <span>• Initial Game Prototype</span>
                <span>• Announcement of Partnership with Decentraland</span>
            </td>
          </tr>
          <tr>
            <td className="year"><h3>2019</h3></td>
            <td className="pb-5">
                <span>• Minimum Viable Product (MVP) Completed</span>
                <span>• Website and Discord Launch</span>
                <span>• Team and Advisory Board Introductions</span>
                <span>• Smart Contract Development for Parts and Crates</span>
                <span>• Item Pre-sale: Prime Edition</span>
                <span>• Closed Multiplayer Beta</span>
                <span>• Cross-Game Promotions</span>
                <span>• Loom Network to Ethereum Network Bridge</span>
                <span>• Parts Trading on Ethereum via OpenSea</span>
                <span>• Standalone Web Version Early Access</span>
                <span>• Parts Trading on Ethereum via OpenSea</span>
                <span>• Standalone Web Version Early Access</span>
            </td>
          </tr>
          <tr>
            <td className="year"><h3>2020</h3></td>
            <td className="pb-5">
              <span>• Decentraland Public Launch</span>
              <span>• Web Support for Venly Wallet</span>
              <span>• Web Garage Prototype</span>
              <span>• Web / Decentraland Updates</span>
              <span>• Polygon to Ethereum Network </span>
              <span>• Smart Contract Development for SCRAP</span>
              <span>• Competitive Races Prototype</span>
              <span>• Mobile Version Prototype</span>
              <span>• Tournaments</span>
            </td>
          </tr>
          <tr>
            <td className="year"><h3>2021</h3></td>
            <td className="pb-5">
              <span>• Founded Playcheck Games</span>
              <span>• Initial SCRAP Airdrop</span>
              <span>• Parts Trading on Polygon via OpenSea and Venly Market</span>
              <span>• Web / Decentraland Updates</span>
              <span>• Web Support for Metaverse Chat</span>
              <span>• Web Inventory Improvements</span>
              <span>• Web Garage Development</span>
              <span>• Mobile Alpha Development</span>
              <span>• Smart Contract Redeployment for SCRAP</span>
              <span>• API Development for SCRAP</span>
              <span>• API Development for Parts Upgrading</span>
              <span>• Special Edition NFT Drops</span>
              <span>• Tournaments</span>
            </td>
          </tr>
          <tr>
            <td className="year"><h3>2022</h3></td>
            <td className="pb-5">
                <span>• Project Shutdown</span>
            </td>
          </tr>
          </table>

          

            
          </div>
        </section>
      </div>
    );
  }
}
