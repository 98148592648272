import React from 'react'
import ItemInventoryCrates from './ItemInventoryCrates';
import CrateActionClosed from './CrateActionClosed';
import CrateAllowance from './CrateAllowance';
import {CrateInfo} from './Presale';
import Loader from './Loader';
import ComponentWithAlert from './ComponentWithAlert';
import '../styles/scss/Inventory.scss';
import '../styles/scss/CrateAction.scss';
import axios from 'axios';
const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

export default class InventoryCrates extends ComponentWithAlert {
  constructor(props) {
    super(props);
    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
    this.state = {
      numberOfAjaxCAllPending: 2,
      isLoading: false,
      totalCrates: 0
    }
    this.popupOpen = React.createRef();
    this.popupAllowance = React.createRef();
    setTimeout(() => {
      this.setState({numberOfAjaxCAllPending: 0});
    }, 2000);
    this.updateTotalCrates = this.updateTotalCrates.bind(this);
  }
  updateTotalCrates(value) {
      this.setState({
          totalCrates: this.state.totalCrates + value
      });
  }
  renderCrates() {
    var crates = [];

    if (this.props.web3state.metamaskAccount) {

      for (var crateType in CrateInfo) {
        var crate = CrateInfo[crateType];

        crates.push(<ItemInventoryCrates
          cratesCount={this.state.totalCrates}
          updateTotalCrates={this.updateTotalCrates}
          key={crateType}
          apiUrl={this.apiUrl}
          web3state={this.props.web3state}
          popupShowcase={this.props.popupShowcase}
          popupOpen={this.popupOpen}
          popupAllowance={this.popupAllowance}
          popupGeneric={this.props.popupGeneric}
          crateType={crateType}
          {...crate}
        />);
      }
    }
    return crates;
  }
  renderEmptyCrates() {
     return (
       <section className="container main-inventory footer-height-f">
         <div className="d-flex flex-column align-items-center justify-content-center">
            <h3>You currently do not have any crates.</h3>
            <p>Please purchase crates to acquire items!</p>
         </div>
       </section>
      );
  }
  async getTxnHash() {
    var requestUrl = this.apiUrl + "/getTxnHash";
    //var address = this.props.web3state.metamaskAccount;

    axios.get(requestUrl)
  }
  render() {

    if (!this.props.web3state.metamaskAccount) {
      return <Loader />;
    }
    return (
      <div className="Inventory">
        {(!this.props.web3state.metamaskAccount || (this.state.numberOfAjaxCAllPending > 0 )) ? <Loader /> : ""}
        <section className="container main-inventory mb-5 footer-height-f"  style={{display:((this.state.numberOfAjaxCAllPending > 0 ))?"none":"block"}}>
          <div className="list-inventory parts row">
            {this.renderCrates()}
            {(this.state.totalCrates === 0) ? this.renderEmptyCrates(): ""}
          </div>
        </section>
        <CrateActionClosed
          ref={this.popupOpen}
          apiUrl={this.apiUrl}
          userAccount={this.props.web3state.metamaskAccount}
          accountNav={this.props.accountNav}
        />
        <CrateAllowance
          ref={this.popupAllowance}
          userAccount={this.props.web3state.metamaskAccount}
        />
        <button className="back-to-top" type="button" />
      </div>
    );
  }
}
