import React from 'react';
import axios from 'axios';
import ComponentWithAlert from './ComponentWithAlert';
import Loader from './Loader';

//const ETH_SYSTEM_ACCOUNT = process.env.REACT_APP_ETH_SYSTEM_ACCOUNT;

export default class CrateActionClosed extends ComponentWithAlert {
  constructor(props) {
    super(props);

    this.genericPopup = null;

    this.state = {
      crateType: "bronze",
      isClosed: true,
      isOpening: false
    }

    this.onCrateOpened = function () {
      throw Error("onCrateOpened() not implemented.");
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillUnmount() {
    this.onCrateOpened = null;
  }

  async onOpen(e) {
    //var requestUrl = this.props.apiUrl + "/openCrate";

    this.setState({isOpening: true});
    // this._alert("Opening crate, please wait.", "info");

    this.genericPopup.current.show({
      title: "Opening crate...",
      body: <Loader />,
      noButton: true
    });

    // add the don't refresh popup
    window.onbeforeunload = function() {
      return "Data will be lost if you leave the page, are you sure?";
    };

    //call metamask transfer
    if(!!this.state.crateContract && !!this.state.systemAccount){
      
      this.state.crateContract.methods
        .transfer(this.state.systemAccount, 1)
        .send({from: this.props.userAccount })
        .then((tx) => {
          this.genericPopup.current.show({
            noButton: false,
            title: "Your transaction is being processed",
            body: <p>Your parts will be delivered to your account after processing.</p>
          });

        })
        .catch(e => {
          this.genericPopup.current.hide();
          this.genericPopup.current.show({
            title: "Unable to open the crate.",
            body: <p>The network may be busy or your connection is unstable. You may be able to try again after a few minutes.</p>
          });
        })
        .finally(() => {
          this.setState({isOpening: false});
        });
    }

    /* axios.get(requestUrl, {params: {
      crateType: this.crateType.toLowerCase()
    }})
    .then(async (r) => {

      this.genericPopup.current.show({
        noButton: false,
        title: "Your transaction is being processed",
        body: <p>Please wait until the transaction is completed before opening another crate. You won’t be able to open another one until this transaction has ended.</p>
      });

      this.setState({isOpening: false});

      // remove on successful open
      window.onbeforeunload = null;
      // Trigger popup to show parts acquired.
      // this.onCrateOpened(this.crateType, r.data.parts);
      this.props.accountNav.current.setTxnHash(r.data.txnHash);

      // remove please wait alert
      this._removeAlerts();
    })
    .catch((e) => {
      if (e.response) {

        if (e.response.data.status === "Pending mint.") {
          // Trigger generic popup for error
          this.genericPopup.current.hide();
          this.genericPopup.current.show({
            title: "You have a pending transaction",
            body: <p>Please wait until the transaction is completed before trying again.</p>
          });
        }

        else
        {
          // Trigger generic popup for error
          this.genericPopup.current.hide();
          this.genericPopup.current.show({
            title: "Unable to open the crate.",
            body: <p>The network may be busy or your connection is unstable. You may be able to try again after a few minutes.</p>
          });
        }
      }

      else {
        // Trigger generic popup for error
        this.genericPopup.current.hide();
        this.genericPopup.current.show({
          title: "Unable to open the crate.",
          body: <p>The network may be busy or your connection is unstable. You may be able to try again after a few minutes.</p>
        });
      }

      // remove on successful open
      this.setState({isOpening: false});
      window.onbeforeunload = null;

    }); */
    // this.props.openShowcase(this.crateType, [{"external_url":"https://br-staging.alto.gg/parts/1","details":{"internalId":"CatCarCasing","isElite":false,"serialNumber":"7","durability":101,"weight":190,"steering":0,"model":"Cat","power":19,"type":"casing","brand":"Bolt","speed":174,"rarity":"Legendary"},"description":"Cat Car Legendary","id":"1","image":"https://s3-ap-southeast-1.amazonaws.com/br-staging.alto.gg/images/parts/CatCarCasing.png","name":"Bolt Cat Body"}]);
    this.hide();
  }

  show(extras) {
    this.crateType = extras.crateType;
    this.onCrateOpened = extras.onCrateOpened;
    this.genericPopup = extras.genericPopup;
    this.setState({
      crateType: this.crateType, 
      cratesOwned: extras.cratesOwned, 
      isClosed: false,
      crateContract: extras.crateContract,
      systemAccount: extras.systemAccount
    });
  }

  hide() {
    this.setState({isClosed: true});
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.onOpen) {
      this.onOpen(e);
    }
    else {
      this.hide();
    }
  }

  render() {


    return (
			<div onClick={this.hide} className={"CrateAction blur-modal " + (this.state.isClosed ? "d-none" : "d-flex flex-column align-items-center justify-content-center")}>
				<div className="container modal-container text-center bg-transparent align-items-center justify-content-center m-0">
          <div className={"crate-open " + this.state.crateType}></div>
					<button type="submit" onClick={this.handleSubmit} className="button-common mb-5 mt-3">Open Crate!</button>
				</div>
			</div>
		);
  }
}
