import React, { Component } from 'react'
import MetamaskInst from './MetamaskInst';
import KyberPopup from './KyberPopup';
import Crate from './Crate';
import Web3 from 'web3';

import CrateWood from '../contracts/CrateWood.json';
import CrateBronze from '../contracts/CrateBronze.json';
import CrateSilver from '../contracts/CrateSilver.json';
import CrateGold from '../contracts/CrateGold.json';
import CrateAxie from '../contracts/CrateAxie.json';

import S1CrateWood from '../contracts/S1CrateWood.json';
import S1CrateBronze from '../contracts/S1CrateBronze.json';
import S1CrateSilver from '../contracts/S1CrateSilver.json';
import S1CrateGold from '../contracts/S1CrateGold.json';
import CKCrate from '../contracts/CKCrate.json';

//import '../styles/scss/Presale.scss';
import '../styles/scss/rangeslider.scss';
import '../styles/scss/style.scss';
import '../styles/scss/PromoTemplate.scss';


export const CrateInfo = {
  gold: {
    name: "Gold Crate",
    image: "crate-gold.png",
    cost: 0.7,
    parts: 4,
    droprates: {
      epic: 0.30,
      rare: 0.50,
      common: 0.20,
      legendary: 0.024167
    },
    contractJson: CrateGold
  },
  silver: {
    name: "Silver Crate",
    image: "crate-silver.png",
    cost: 0.3,
    parts: 3,
    droprates: {
      epic: 0.12,
      rare: 0.60,
      common: 0.28,
      legendary: 0.009583
    },
    contractJson: CrateSilver
  },
  bronze: {
    name: "Bronze Crate",
    image: "crate-bronze.png",
    cost: 0.1,
    parts: 2,
    droprates: {
      epic: 0.05,
      rare: 0.28,
      common: 0.67,
      legendary: 0.001935
    },
    contractJson: CrateBronze
  },
  wood: {
    name: "Wood Crate",
    image: "crate-wood.png",
    cost: 0.035,
    parts: 1,
    droprates: {
      epic: 0.03,
      rare: 0.17,
      common: 0.80,
      legendary: 0.000556
    },
    contractJson: CrateWood
  },
  axie: {
    name: "Axie Crate",
    image: "crate-axie.png",
    cost: 0.1,
    parts: 4,
    droprates: {
      epic: 0.13,
      rare: 0.33,
      common: 0.53,
      legendary: 0.000000
    },
    contractJson: CrateAxie
  }, // S1
  s1gold: {
    name: "Season 1 Gold Crate",
    image: "crate-s1-gold-hq.png",
    cost: 0.7,
    parts: 4,
    totalCrates: 975,
    droprates: {
      epic: 0.30,
      rare: 0.50,
      common: 0.20,
      legendary: 1.4423 / 100
    },
    crateType:'s1gold',
    contractJson: S1CrateGold
  },
  s1silver: {
    name: "Season 1 Silver Crate",
    image: "crate-s1-silver-hq.png",
    cost: 0.3,
    parts: 3,
    totalCrates: 1800,
    droprates: {
      epic: 0.12,
      rare: 0.60,
      common: 0.28,
      legendary: 0.2083 / 100
    },
    crateType:'s1silver',
    contractJson: S1CrateSilver
  },
  s1bronze: {
    name: "Season 1 Bronze Crate",
    image: "crate-s1-bronze-hq.png",
    cost: 0.1,
    parts: 2,
    totalCrates: 2325,
    droprates: {
      epic: 0.05,
      rare: 0.28,
      common: 0.67,
      legendary: 0.0353 / 100
    },
    crateType:'s1bronze',
    contractJson: S1CrateBronze
  },
  s1wood: {
    name: "Season 1 Wood Crate",
    image: "crate-s1-wood-hq.png",
    cost: 0.030,
    parts: 1,
    totalCrates: 2700,
    droprates: {
      epic: 0.03,
      rare: 0.17,
      common: 0.80,
      legendary:  0.0043 / 100
    },
    crateType:'s1wood',
    contractJson: S1CrateWood
  },
  s1ck: {
    name: "Season 1 CryptoKitties Crate",
    image: "crate-kitty-hq.png",
    cost: 0.100,
    parts: 2,
    totalCrates: 350,
    droprates: {
      epic: 0.03,
      rare: 0.17,
      common: 0.80,
      legendary:  0.0043 / 100
    },
    crateType:'s1ck',
    contractJson: CKCrate
  }
}



export default class Presale extends Component {

  crateInfo = CrateInfo;

  constructor(props) {
    super(props);

    if (window.web3)
      this.web3js = new Web3(window.web3.currentProvider);

    this.state = {
      crateAmt: 1,
      selectedCrate: this.crateInfo.s1gold,
      purchaseCurrency: "ETH",
      isReady: false,
      saleStartDate: 0,
      crateDiscount: 0,
      discountDay: "1",
      canBuyAxie: false
    }

    this.kyberPopupRef = React.createRef();
    this.handleChooseCrate = this.handleChooseCrate.bind(this);
    this.getSaleDate = this.getSaleDate.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    console.log('this.props.referrer', this.props.referrer);
  }

  componentDidMount() {

    if (this.props.web3state.ready) {
      if (this.props.web3state.networkId) {
        this.update();
      }
    }
  }

  copyToClipboard() {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = this.shareURL;                       // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }

    document.getElementById("clipboardButton").innerHTML = "Copied!";
  };

  shareURL = "";

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.web3state.ready) {

      // display referral link
      if (this.userAccount) {
        //this.shareURL = "https://battleracers.io/?r=" + this.userAccount;
        this.shareURL = window.location.origin + "/?r=" + this.userAccount;
        var panel = document.getElementById("referralPanel");
        panel.style.visibility = "visible";

        var referralLink = document.getElementById("referralLink");
        referralLink.innerHTML = this.shareURL;
      }

      var networkId = this.props.web3state.networkId;
      var needsUpdate = false;
      if (prevProps.web3state.networkId !== networkId) {
        needsUpdate = true;
      }

      if (needsUpdate) {
        this.update();
      }

    }
  }

  componentWillUnmount(){
    clearInterval(this.countdown);
    this.countdown = null;
  }

  userAccount = "";
  contractAddress = "";
  contract = "";
  axieContractAddress = "";
  axieContract = "";

  async update() {
    if (this.props.web3state.ready) {
    // store new account and contract values
    this.userAccount = this.props.web3state.metamaskAccount;
    this.contractAddress = this.getAddressFromJson(S1CrateWood, this.props.web3state.networkId);
    this.contract = new this.web3js.eth.Contract(S1CrateWood.abi, this.contractAddress, {from: this.userAccount});
    this.axieContractAddress = this.getAddressFromJson(CrateAxie, this.props.web3state.networkId);
    this.axieContract = new this.web3js.eth.Contract(CrateAxie.abi, this.axieContractAddress, {from: this.userAccount});

    await this.getSaleStartDate();
    await this.getDiscountRate();

    await this.getAxieEligibility();
    // this.setState({canBuyAxie:true});
    }
  }

  getAddressFromJson(contractJson, networkId)
  {
    var contractNetworks = contractJson.networks;

    if (contractNetworks[networkId] !== undefined)
      return (contractNetworks[networkId].address)
  }

  async getDiscountRate() {

    try {
        this.contract.methods.getPresentPercentage().call().then(priceMult => {
        this.setState(
          {
            crateDiscount: (100 - priceMult / 100)
          });
        })
      .catch(e => {
      })

    } catch(e) {
    }

  }

  getSaleDate(startDate) {
      var day = 86400000;
      var currentDate = Date.now(); // + 1 * day;

      return Math.floor((currentDate - startDate) / day) + 1;
  }


  startCountdown(startDate) {
    var day = 86400000;
    var currentDate = Date.now();

    var countDownDate =  startDate;

    while (countDownDate < currentDate)
      countDownDate += day;

    // day 7 discount end
    countDownDate = 1559052000 * 1000;

    // Update the count down every 1 second
    this.countdown = setInterval(function() {

      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));

      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + (days * 24);
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // update state
      var countdownText = hours + "h " + minutes + "m " + seconds + "s ";

      var countdownElement = document.getElementById("countdown");

      if (countdownElement)
        countdownElement.innerHTML = countdownText;

      // If the count down is finished, refresh the page
      if (distance < 0 && this.countdown) {
        clearInterval(this.countdown);
        this.countdown = null;

        // refresh page
        //window.location.reload();
      }
    }, 1000);

  }

  async getAxieEligibility() {
    try {
        this.axieContract.methods.canBuyCrate().call().then(canBuyAxie => {
        this.setState(
          {
            canBuyAxie: canBuyAxie
          });

      })
      .catch(e => {
      })

    } catch(e) {
    }
  }

  async getSaleStartDate() {

    try {
        this.contract.methods.getSaleStartDate().call().then(startDate => {
          var saleStartDate = parseInt(startDate.toString()) * 1000;
          console.log('saleStartDate', saleStartDate);
        this.setState(
          {
            saleStartDate: saleStartDate,
            discountDay: this.getSaleDate(saleStartDate)
          });

          this.startCountdown(saleStartDate);

      })
      .catch(e => {
      })

    } catch(e) {
    }

  }

  handleChooseCrate(e) {
    var key = e.currentTarget.value;
    this.setState({
       selectedCrate: this.crateInfo[key]
    });
  }

  renderCrates() {
    var crates = [];

    for (var i in this.crateInfo) {
      var crate = this.crateInfo[i];

      crates.push(<Crate
        key={i}
        isSelected={crate.name === this.state.selectedCrate.name}
        kyberPopupRef={this.kyberPopupRef}
        web3state={this.props.web3state}
        referralAddress={this.props.referrer}
        crateDiscount={this.state.crateDiscount}
        {...crate}
      />);
    }

    return crates;
  }


  render() {
    return (
      <div id="content" className="s1">
       {/* <header id="purchasePane" className="section-header s1 header-main d-flex flex-column">
        <div className="violator text-center flex-row">
        <span className="mr-3">Enjoy a site-wide 15% discount sponsored by MATIC!</span>
        <img src={require("../images/icons/logo-matic.png")} className="img-fluid" alt="" />
        </div>
        </header>
        <KyberPopup ref={this.kyberPopupRef} crateDiscount={this.state.crateDiscount}/>

        <div className="crates-presale-nav container-fluid  d-flex flex-row align-items-center mt-70px flex-wrap">
          <div className="crate-list container d-flex flex-row flex-wrap justify-content-center bb-2px-lightgray">
             <button onClick={this.handleChooseCrate} value="s1gold" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1gold.name}</h6>
              <img src={require("../images/crates/crate-s1-gold-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1gold.cost} ETH </span>
            </button>

            <button onClick={this.handleChooseCrate} value="s1silver" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1silver.name}</h6>
              <img src={require("../images/crates/crate-s1-silver-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1silver.cost} ETH </span>
            </button>

            <button onClick={this.handleChooseCrate} value="s1bronze" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1bronze.name}</h6>
              <img src={require("../images/crates/crate-s1-bronze-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1bronze.cost} ETH </span>
            </button>
            <button onClick={this.handleChooseCrate} value="s1wood" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1wood.name}</h6>
              <img src={require("../images/crates/crate-s1-wood-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1wood.cost} ETH </span>
            </button>
          </div>
        </div>

        <div className="crates-presale-nav container-fluid  d-flex flex-row align-items-center mt-70px flex-wrap">
          <div className="crate-list container d-flex flex-row flex-wrap">

            <button onClick={this.handleChooseCrate} value="gold" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.gold.name}</h6>
              <img src={require("../images/presale/" + this.crateInfo.gold.image)} className="img-fluid" alt="" />
              <span>{this.crateInfo.gold.cost} ETH</span>
            </button>
            <button onClick={this.handleChooseCrate} value="silver" className="crates-presale-nav-item d-flex flex-column align-items-center">
            <h6>{this.crateInfo.silver.name}</h6>
              <img src={require("../images/presale/" + this.crateInfo.silver.image)} className="img-fluid" alt="" />
              <span>{this.crateInfo.silver.cost} ETH</span>
            </button>
            <button onClick={this.handleChooseCrate} value="bronze" className="crates-presale-nav-item d-flex flex-column align-items-center">
            <h6>{this.crateInfo.bronze.name}</h6>
              <img src={require("../images/presale/" + this.crateInfo.bronze.image)} className="img-fluid" alt="" />
              <span>{this.crateInfo.bronze.cost} ETH</span>
            </button>
            <button onClick={this.handleChooseCrate} value="wood" className="crates-presale-nav-item d-flex flex-column align-items-center">
            <h6>{this.crateInfo.wood.name}</h6>
              <img src={require("../images/presale/" + this.crateInfo.wood.image)} className="img-fluid" alt="" />
              <span>{this.crateInfo.wood.cost} ETH</span>
            </button>

            <button onClick={this.handleChooseCrate} value="s1gold" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1gold.name}</h6>
              <img src={require("../images/crates/crate-s1-gold-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1gold.cost} ETH </span>
            </button>

            <button onClick={this.handleChooseCrate} value="s1silver" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1silver.name}</h6>
              <img src={require("../images/crates/crate-s1-silver-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1silver.cost} ETH </span>
            </button>

            <button onClick={this.handleChooseCrate} value="s1bronze" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1bronze.name}</h6>
              <img src={require("../images/crates/crate-s1-bronze-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1bronze.cost} ETH </span>
            </button>
            <button onClick={this.handleChooseCrate} value="s1wood" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1wood.name}</h6>
              <img src={require("../images/crates/crate-s1-wood-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1wood.cost} ETH </span>
            </button>


            {
              this.state.canBuyAxie ?
                <button onClick={this.handleChooseCrate} value="axie" className="crates-presale-nav-item d-flex flex-column align-items-center">
                <h6>{this.crateInfo.axie.name}</h6>
                  <img src={require("../images/presale/" + this.crateInfo.axie.image)} height={50} className="img-fluid" alt="" />
                  <span>{this.crateInfo.axie.cost} ETH</span>
                </button>
            :null}
            <div className="presale-discount-countdown d-flex flex-column text-right ml-auto">

                {  this.state.crateDiscount > 0 ?

                <div>
                <h5 style={{color: 'yellow'}} className="mb-0">Early Bird Sale: {(this.state.crateDiscount)}% off all crates!</h5>
                <span>Discount Expires in:</span>
                <h6 id="countdown" style={{fontSize: '2rem', lineHeight: '2rem'}}>...</h6>
                </div>

                :  null }

              </div>
          </div>

        </div>
        <header id="purchasePane" className="section-header presale header-main d-flex flex-column">
          <div className="container d-flex flex-column align-items-center">
            {this.renderCrates()}
          </div>
        </header>*/}

        <header id="purchasePane" className="section-header s1 header-main d-flex flex-column">
         {  this.state.crateDiscount > 0 ?  <div className="violator text-center flex-row">
        <span className="mr-3">15% discount sponsored by Matic, extended for 48 hours!</span>
        <img src={require("../images/icons/logo-matic.png")} className="img-fluid" alt="" />
        </div> :  null }
        <KyberPopup ref={this.kyberPopupRef} crateDiscount={this.state.crateDiscount}/>
        <div className="crates-presale-nav container-fluid  d-flex flex-row align-items-center flex-wrap">
          <div className="crate-list container d-flex flex-row flex-wrap justify-content-center bb-2px-lightgray">
            <button onClick={this.handleChooseCrate} value="s1gold" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1gold.name}</h6>
              <img src={require("../images/crates/crate-s1-gold-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1gold.cost} ETH </span>
            </button>

            <button onClick={this.handleChooseCrate} value="s1silver" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1silver.name}</h6>
              <img src={require("../images/crates/crate-s1-silver-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1silver.cost} ETH </span>
            </button>

            <button onClick={this.handleChooseCrate} value="s1bronze" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1bronze.name}</h6>
              <img src={require("../images/crates/crate-s1-bronze-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1bronze.cost} ETH </span>
            </button>
            <button onClick={this.handleChooseCrate} value="s1wood" className="crates-presale-nav-item d-flex flex-column align-items-center">
              <h6>{this.crateInfo.s1wood.name}</h6>
              <img src={require("../images/crates/crate-s1-wood-hq.png")} className="img-fluid" alt="" />
              <span> {this.crateInfo.s1wood.cost} ETH </span>
            </button>
          </div>
        </div>
          <div className="container d-flex flex-column align-items-center">
          {this.renderCrates()}
          </div>
        </header>

        <div className="container referral-panel mt-auto d-flex flex-column align-content-center p-2" id="referralPanel">
          <div className=" d-flex flex-row align-items-center justify-content-center flex-wrap text-center">
            <span className="text-center referral-text">Receive a 10% referral fee from all direct ETH purchases* done using your unique referral link:</span>
            <div className="d-flex flex-row justify-content-center field-group">
              <div className="input-wrapper">
                <span className="referral-field" id="referralLink" />
              </div>
              <button id="clipboardButton" onClick={this.copyToClipboard} className="referral-share-button mr-5px"><i className="fas fa-link" />Copy Link</button>
            </div>
          </div>
        </div>
        <p className="text-center small mb-5">*This does not include ETH purchases using KyberPay.</p>


         <section className="container section-banner-large d-flex flex-column car-brands">
        <div className="text-light mt-3 ml-4 p-3">
          <h1>Featuring 50+ New Car Models!</h1>
          <p>Buy crates of new limited edition car parts ranging across six brands and three rarities (Common, Rare, and Epic), plus the chance to get Elite and Legendary parts with higher upgrade caps!</p>
          </div>
          <div className="container p-5 img-layout">
            <div className="row justify-content-center text-center text-light">
              <div className="col-md-4 col-6"><img src={require("../images/s1/sale1/brand-hyperion-b.png")} alt="" />
              <h4>Hyperion</h4>
              </div>
                <div className="col-md-4 col-6"><img src={require("../images/s1/sale1/brand-vista-b.png")} alt="" />
                <h4>Vista</h4>
                </div>
                <div className="col-md-4 col-6 "><img src={require("../images/s1/sale1/brand-bolt-b.png")} alt="" />
                <h4>Bolt</h4>
                </div>
                <div className="col-md-4 col-6"><img src={require("../images/s1/sale1/brand-guerilla-b.png")} alt="" />
                <h4>Guerilla</h4>
                </div>
                <div className="col-md-4 col-6"><img src={require("../images/s1/sale1/brand-python-b.png")} alt="" />
                <h4>Python</h4>
                </div>
                <div className="col-md-4 col-6"><img src={require("../images/s1/sale1/brand-zeta-b.png")} alt="" />
                <h4>Zeta</h4>
                </div>
            </div>
          </div>
          <div className="notice-bottom mt-auto">Learn more about our car brands <a href="https://medium.com/battle-racers/sneak-peek-cars-f43d5762121a" target="_blank" rel="noopener noreferrer">here.</a></div>
        </section>

        <section className="card-array s1 container-fluid">
          <div className="container mb-5">
            <p></p>
            <div className="row">
              <div className="col-md-4 p-3">
                <div className="col-md-12 d-flex flex-column legendary">
                  <h2>Find the new Legendary car!</h2>
                  <div className="mt-auto">
                    <p>Each crate has a chance to get a BONUS Legendary part, in addition to parts it already contains. This season’s featured Legendary is the raging red Python Oni!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-3">
                <div className="col-md-12 d-flex flex-column chances">
                  <h2>Bonus legendary part chance differs per crate:</h2>
                  <div className="mt-auto">
                    <p>1.4423% - Gold crates<br />
                      0.2083% - Silver crates<br />
                      0.0353% - Bronze crates<br />
                      0.0043% - Wood crates<br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-3">
                <div className="col-md-12 d-flex flex-column exclusive">
                  <h2>Plus three exclusive cars!</h2>
                  <div className="mt-auto">
                    <p>The Epic Guerilla Cyber’s supposedly one really tough design The Rare Bolt Binance was made with our partners from <a href="https://www.binance.com/en" target="_blank" rel="noopener noreferrer">Binance.com</a>. The Vista Cartel is smokin’ hot, too, fresh from our friends at <a href="https://www.metacartel.org/" target="_blank" rel="noopener noreferrer">MetaCartel.</a> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-5">
          <MetamaskInst></MetamaskInst>
        </section>
      </div>
    );
  }
}
