import React, {Component} from 'react';
import Web3 from 'web3';
import $ from 'jquery';
import axios from 'axios';
import AlpacaTxnPane from './AlpacaTxnPane';
import withWeb3Alert from './../Web3Alert';

import CrateWood from '../../contracts/CrateWood.json';
import CrateBronze from '../../contracts/CrateBronze.json';
import CrateSilver from '../../contracts/CrateSilver.json';
import CrateGold from '../../contracts/CrateGold.json';
import CrateAxie from '../../contracts/CrateAxie.json';
import S1CrateWood from '../../contracts/S1CrateWood.json';
import S1CrateBronze from '../../contracts/S1CrateBronze.json';
import S1CrateSilver from '../../contracts/S1CrateSilver.json';
import S1CrateGold from '../../contracts/S1CrateGold.json';
import CKCrate from '../../contracts/CKCrate.json';

// import '../styles/scss/Alpaca.scss';

const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || "localhost";
const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;


class Alpaca extends Component {
  constructor(props) {
    super(props);

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
    this.state = {
      userWallet: "",
      pending: {
        pendingTimestamp: null,
        pendingTxnHash: null,
        crateType: null,
        doneMinting: null
      },
      isUnlocking: false,
      isLoading: false,
      isResolving: false
    };

    this.onChangeUserWallet = this.onChangeUserWallet.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onUnlock = this.onUnlock.bind(this);
    this.onResolve = this.onResolve.bind(this);
    this.onDryRun = this.onDryRun.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    $(".nav-link")[0].click();
  }

  getEtherScanURL() {
    if (!this.state.pending.pendingTxnHash)
      return null;

    var baseUrl;
    var networkId = this.props.web3state.networkId;

    if (networkId) {
      switch (networkId) {
        case 1:
          baseUrl = "https://etherscan.io";
          break;
        case 3:
          baseUrl = "https://ropsten.etherscan.io"
          break;
        case 4:
          baseUrl = "https://rinkeby.etherscan.io"
          break;
        default:
      }

      if (!baseUrl) {
        return null;
      }

      return baseUrl + "/tx/" + this.state.pending.pendingTxnHash;
    }
    return null;
  }

  getTimestamp() {
    var ts = this.state.pending.pendingTimestamp;
    if (!ts) {
      return "-";
    }

    return (new Date(Number(ts))).toString();
  }

  getPending(userWallet) {
    var requestUrl = this.apiUrl + "/getPending";
    axios.get(requestUrl, {params: {address: userWallet}})
    .then((r) => {
      this.setState({pending: r.data});
    });
  }

  unsetPending(userWallet) {
    var requestUrl = this.apiUrl + "/unsetPending";
    axios.get(requestUrl, {params: {address: userWallet}})
    .then((r) => {
      this.setState({isUnlocking: false});
      window.alert("Account unlocked!");
      this.getPending(userWallet);
    })
    .catch((e) => {
      this.setState({isUnlocking: false});
    });
  }

  resolvePending(userWallet, doResolve="") {
    var requestUrl = this.apiUrl + "/resolvePending";
    axios.get(requestUrl, {params: {
      address: userWallet,
      doResolve: doResolve
    }})
    .then((r) => {
      this.setState({isResolving: false, resolverTrace: r.data.trace});
      window.alert("Account resolved!" + (!Boolean(doResolve) ? " (dryRun)" : ""));
      this.getPending(userWallet);
    })
    .catch((e) => {
      this.setState({isResolving: false});
    });
  }

  getRequestAge() {
    var timestamp = this.state.pending.pendingTimestamp;

    if (!timestamp) {
      return "-";
    }

    var now = Date.now();
    var delta = now - timestamp;

    return this.verboseTimeDelta(delta);
  }

  verboseTimeDelta(delta) {
    var millis = delta % 1000;

    delta = Math.floor(delta / 1000)
    var seconds = delta % 60;

    delta = (delta - seconds) / 60;
    var minutes = delta % 60;

    delta = (delta - minutes) / 60;
    var hours = delta % 24;

    delta = (delta - hours) / 24;
    var days = delta;

    return days + "d " + hours + "h " + minutes + "m " + seconds + "." + millis + "s";
  }

  getTxnHash() {
    var txnHash = this.state.pending.pendingTxnHash || "";
    return (
      (txnHash.startsWith("0x") && txnHash) || "-"
    );
  }

  onChangeUserWallet(e) {
    this.setState({userWallet: Web3.utils.toChecksumAddress(e.target.value)});
  }

  onClickTab(e) {
    e.preventDefault();

    $(".nav-link.active, .tab-pane.active").removeClass("active");
    $(e.target).addClass("active");
    $(e.target.hash).addClass("active");
  }

  onSubmit(e) {
    e.preventDefault();

    // this.setState({userWallet: e.target.value});
    this.getPending(this.state.userWallet);
  }

  async onUnlock(e) {
    if (this.state.isUnlocking) {
      return;
    }

    this.setState({isUnlocking: true});

    var response = await window.confirm("Are you sure you want to unlock?");

    if (!response) {
      this.setState({isUnlocking: false});
    }

    this.unsetPending(this.state.userWallet);
  }

  async onResolve(e) {
    if (this.state.isResolving) {
      return;
    }

    this.setState({isResolving: true});

    var response = await window.confirm("Are you sure you want to try and resolve?");

    if (!response) {
      this.setState({isResolving: false});
    }

    this.resolvePending(this.state.userWallet, true);
  }

  async onDryRun(e) {
    if (this.state.isResolving) {
      return;
    }

    this.setState({isResolving: true});

    var response = await window.confirm("Are you sure you want to do a dry run?");

    if (!response) {
      this.setState({isResolving: false});
    }

    this.resolvePending(this.state.userWallet);
  }

  renderRecommendedActions() {
    if (this.state.pending.doneMinting || !this.state.pending.pendingTimestamp) {
      return <div>
        <button className="btn" disabled>None</button>
      </div>
    }

    return (
      <div>
        <button className="btn btn-success" onClick={this.onUnlock} disabled={this.state.isUnlocking || this.state.isResolving || this.state.isLoading}>Unlock</button>
        <button className="btn btn-warning" onClick={this.onDryRun} disabled={this.state.isUnlocking || this.state.isResolving || this.state.isLoading}>Dry Run</button>
        <button className="btn btn-danger" onClick={this.onResolve} disabled={this.state.isUnlocking || this.state.isResolving || this.state.isLoading}>Resolve</button>
      </div>
    );
  }

  renderTabPanes() {
    var paneData = [
      {id:"wood",contractJson: CrateWood},
      {id:"bronze",contractJson: CrateBronze},
      {id:"silver",contractJson: CrateSilver},
      {id:"gold",contractJson: CrateGold},
      {id:"axie",contractJson: CrateAxie},
      {id:"s1wood",contractJson: S1CrateWood},
      {id:"s1bronze",contractJson: S1CrateBronze},
      {id:"s1silver",contractJson: S1CrateSilver},
      {id:"s1gold",contractJson: S1CrateGold},
      {id:"s1ck",contractJson: CKCrate}
    ]

    return paneData.map((data) => {
      return <AlpacaTxnPane key={data.id} {...data} web3state={this.props.web3state} userWallet={this.state.userWallet} />;
    });
  }
  toggle(e) {
    console.log(e.target.name)
  }
  render() {
    return (

      <div className="Alpaca container">

        <h5>Hello, {this.props.web3state.metamaskAccount}!</h5>
        <form className="d-block" onSubmit={this.onSubmit}>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">User Wallet</span>
            </div>
            <input type="text" className="form-control" value={this.state.account} onChange={this.onChangeUserWallet} />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary"
                type="submit">
                Load
              </button>
            </div>
          </div>
        </form>

        <div className="row">
          <div className="col-8">
            <div className="card">
              <div className="card-body">
                <div>
                  Request Age: {this.getTimestamp()} ({this.getRequestAge()})
                </div>
                <div>
                  Pending Txn Hash: {
                    this.state.pending.pendingTxnHash ?
                    <a href={this.getEtherScanURL()} target="_blank" rel="noopener noreferrer" title={this.state.pending.pendingTxnHash}>{this.state.pending.pendingTxnHash.slice(0,20)}...</a>
                    : "-"
                  }
                </div>
                <div>
                  Crate Type: {this.state.pending.crateType || "-"}
                </div>
                <div>
                  Done Minting? {this.state.pending.doneMinting ? "Yes" : "No"}
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <div>
                  Recommended Action: (Unlock / Resolve)
                </div>

                {this.renderRecommendedActions()}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>Trace:</label>
              <textarea className="form-control" value={this.state.resolverTrace} disabled rows="8"/>
            </div>
          </div>
        </div>

        <ul id="crateTabs" className="nav nav-pills nav-justified mt-5">
          <li className="nav-item">
            <a className="nav-link" href="#wood" onClick={this.onClickTab}>Wood</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#bronze" onClick={this.onClickTab}>Bronze</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#silver" onClick={this.onClickTab}>Silver</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#gold" onClick={this.onClickTab}>Gold</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#axie" onClick={this.onClickTab}>Axie</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#s1wood" onClick={this.onClickTab}>S1 Wood</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#s1bronze" onClick={this.onClickTab}>S1 Bronze</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#s1silver" onClick={this.onClickTab}>S1 Silver</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#s1gold" onClick={this.onClickTab}>S1 Gold</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#s1ck" onClick={this.onClickTab}>CK</a>
          </li>
        </ul>

        <div className="tab-content mt-3">
          {this.renderTabPanes()}
        </div>
      </div>
    );
  }
}

export default withWeb3Alert(Alpaca);
