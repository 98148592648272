const path = require('path');

const CONFIGS = {
  stg: {
    env: "stg",
    rpcUrl: "https://rpc-mumbai.matic.today",
    wsUrl: "wss://ws-mumbai.matic.today",
    networkId: "80001"
  },
  prd: {
    env: "prd",
    rpcUrl: "https://rpc-mainnet.matic.network",
    wsUrl: "wss://ws-mainnet.matic.network",
    networkId: "137"
  },
  "fake-matic": {
    env: "fake-matic",
    rpcUrl: "http://localhost:8545",
    wsUrl: "ws://localhost:8545",
    networkId: "721"
  },
  "test-matic": {
    env: "test-matic",
    rpcUrl: "http://test-matic:8545",
    wsUrl: "ws://test-matic:8545",
    networkId: "9721"
  }
}

const getSideChainConfig = (dotEnvPath = null) => {
  if(dotEnvPath) require('dotenv').config({ path: dotEnvPath });
  else require('dotenv').config({ path: path.resolve(process.cwd(), ((process.env.NODE_ENV === "test" || process.env.ENVIRONMENT === "test") ? '.env.test' : '.env' ))});

  console.log(process.env);
  const SIDECHAIN_ENV = process.env.REACT_APP_SIDE_NETWORK || "stg";
  console.log("SIDECHAIN_ENV", SIDECHAIN_ENV);

  if (SIDECHAIN_ENV in CONFIGS) {
    return CONFIGS[SIDECHAIN_ENV];
  }

  throw Error("Side chain config not found.");
}

module.exports = { getSideChainConfig }
