import { Component } from 'react';
import '../styles/scss/Web3.scss';
import $ from 'jquery';

class ComponentWithAlert extends Component {
    constructor(props) {
      super(props);
      this._alert = this._alert.bind(this);
      this.attach_close_button = this.attach_close_button.bind(this);
      this.alertMessage = this.alertMessage.bind(this);
      this.closeButton = this.closeButton.bind(this);
    }

    attach_close_button() {
      $('.Web3').delegate('.alert .closebtn', 'click', function(e) {
        $(this).parents('.alert').remove();
        $('.alert').each(function(index) {
          if (index === 0) $(this).css('top', 0);
          else {
            var new_top = (index * 66) + 'px';

            $(this).css('top', new_top);
          }
        });
      });
    };

    alertMessage(msg) {
      var html = `<strong>${typeof msg['title'] !== 'undefined' ? msg['title'] : ''}</strong>${msg['message']}`;

      return html;
    };

    closeButton(msg) {
      var html = (msg['closeButton'] === false ? '' : '<span class="closebtn" >&times;</span>');

      return html;
    };

    _removeAlerts() {
      $('.alert:visible').remove();
    }

    _alert(msg, _class) {
      if (typeof msg == 'string') {
        msg = {
          'message': msg
        };
      }

      $('.alert:visible').remove();

      var numAlertsAlready = $('.alert:visible').length;
      var top = numAlertsAlready * 66;

      $('.Web3').append(
        `<div class="alert ${_class} g-font-muli" style="top: ${top}px">
          <div class="message">
            <div class="content">
              ${this.alertMessage(msg)}
            </div>
          </div>
          ${this.closeButton(msg)}
        </div>`
      );
    };

    componentDidMount() {
      // bind buttons
      this.attach_close_button();
    }


    render() {
      return null;
    }
  }

export default ComponentWithAlert;
