import React, { Component } from 'react';
import '../styles/scss/Web3.scss';
import Web3 from "web3";
import Web3Modal from "web3modal";

import { Arkane } from "@arkane-network/web3-arkane-provider"

import EthereumPartJson from '../contracts/Part.json';
import SideChainPartJson from '../contracts/BRPart.json';
import BridgeJson from '../contracts/BRNftBridge.json';
import VenlyLogo from '../images/web3modal/venly.svg'

function withWeb3Alert(WrappedComponent) {
  return class extends Component {

    constructor(props) {
      super(props);
      this.state = {
          is_metamask_approved: false,
          is_metamask_unlocked: false,
          info: "",
          networkId: "",
          networkType: "",
          metamaskAccount: "",
          ready: false,
          isRenderReady: false,
        }

      this.web3Modal = new Web3Modal({
        network: 'rinkeby',
        cacheProvider: true,
        providerOptions: this.getWeb3ModalProviderOptions()
      });
    }

    isInitialized = false;

    getWeb3ModalProviderOptions = () => {
      const arkaneOptions = {
        clientId: 'BattleRacers',
        signMethod: 'POPUP'
      };

      if (process.env.REACT_APP_ETH_NETWORK !== "mainnet") {
        arkaneOptions.environment = 'staging'

        if(process.env.REACT_APP_ETH_NETWORK === "fake-mainnet"){
          arkaneOptions.network = {
            name : "Local",
            nodeUrl : "http://localhost:9545",
            chainId : null
          }
        } else if(process.env.REACT_APP_ETH_NETWORK === "ropsten"){
          arkaneOptions.network = {
            name : "Ropsten",
            nodeUrl : "https://ropsten.arkane.network",
            chainId : 3
          }
        } else {
          arkaneOptions.network = {
            name : "Rinkeby",
            nodeUrl : "https://rinkeby.arkane.network",
            chainId : 4
          }
        }

        /* arkaneOptions.network = {
          name : "Rinkeby",
          nodeUrl : "https://rinkeby.arkane.network",
          chainId : 4
        } */
      }

      const providerOptions = {
        arkane: {
          package: Arkane,
          display: {
            logo: VenlyLogo,
            name: "Venly",
            description: "Connect with your Venly account"
          },
          options: arkaneOptions
        },
      };
      return providerOptions;
    };

    popup = (e) => {
      e.preventDefault();
      //console.log('asdasd')
    }

    sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    componentDidMount() {
      this.onRouteChanged();
    }

    componentWillUnmount() {
      clearInterval(this.web3Interval);
    }

    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        this.onRouteChanged();
      }
    }

    onRouteChanged = () => {
      if (this.props.location.pathname.indexOf('inventory') !== -1
      || this.props.location.pathname.indexOf('parts') !== -1
      || this.props.location.pathname.indexOf('presale') !== -1
      || this.props.location.pathname.indexOf('login') !== -1
      || this.props.location.pathname.indexOf('season1') !== -1
      || this.props.location.pathname.indexOf('scrap') !== -1) {
        this.tryWeb3ModalConnect();
      }
    }

    web3ModalInitialCheck = async () => {
//      if (this.web3Modal.cachedProvider) {
//        this.tryWeb3ModalConnect();
//      } else {
//        this.promptWeb3Modal("Web3 Connect");
//      }
    }

    tryWeb3ModalConnect = async () => {
      try {
        await this.web3ModalConnect();
      } catch(err) {
        // popup was dismissed
        console.error(err);
        if (this.props.location.pathname.indexOf('login') === -1) {
          this.props.history.push('/login')
          this.web3Modal.toggleModal()
        }
      }
    }

    web3ModalConnect = async () => {
      const provider = await this.web3Modal.connect();
      await this.subscribeProvider(provider);

      const web3 = new Web3(provider);
      web3.eth.extend({
        methods: [
          {
            name: "chainId",
            call: "eth_chainId",
            outputFormatter: web3.utils.hexToNumber
          }
        ]
      });
      
      window.web3 = web3;

      if (!window.web3.currentProvider.close) {
        window.web3.currentProvider.close = () => {
          this.web3ModalReset()
        }
      }

      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      const networkId = await web3.eth.net.getId();

      const EthContract = new web3.eth.Contract(EthereumPartJson.abi, EthereumPartJson.networks[networkId] && EthereumPartJson.networks[networkId].address);
      const EthBridgeContract = new web3.eth.Contract(BridgeJson.abi, BridgeJson.networks[networkId] && BridgeJson.networks[networkId].address);
      const SCContract = new web3.eth.Contract(SideChainPartJson.abi);
      const SCBridgeContract = new web3.eth.Contract(BridgeJson.abi);

      this.isInitialized = true
      this.setState({
        ready: true,
        is_metamask_approved: true,
        is_metamask_unlocked: true,
        metamaskAccount: address,
        networkId: networkId,

        ethPartContract: EthContract,
        ethBridgeContract: EthBridgeContract,
        scPartContract: SCContract,
        scBridgeContract: SCBridgeContract,
        bridgeUnmapped: !BridgeJson.networks[networkId],
        partUnmapped: !EthereumPartJson.networks[networkId] //handle unsupported networks, gracefully
      })
    }

    subscribeProvider = async (provider) => {
      if (!provider.on) {
        return;
      }
      provider.on("disconnect", () => this.web3ModalReset());
      provider.on("accountsChanged", async (accounts) => {
        window.location.reload();
      });
      provider.on("chainChanged", async (chainId) => {
        window.location.reload();
      });
    };

    web3ModalReset = async () => {
      await this.web3Modal.clearCachedProvider();
      window.location.reload();
    }

    //  {this.state.ready ? <WrappedComponent web3state={this.state} {...this.props} />: ""}
    //<WrappedComponent web3state={this.state} {...this.props} />
    render() {

      return (
        <div className="Web3">
          <WrappedComponent web3state={this.state} {...this.props} />
        </div>
      );
    }
  }
};

export default withWeb3Alert;
