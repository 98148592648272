import React, { Component } from 'react'
import '../styles/scss/FAQ.scss'

import Web3 from 'web3'
// import axios from 'axios'

require('@arkane-network/web3-arkane-provider')

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost'
// const INFURA_KEY = process.env.REACT_APP_INFURA_KEY

export default class ArkaneTest extends Component {

  constructor(props) {
    super(props)

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST
    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api"
    }

    this.state = {
      arkaneProfile: {},
      arkaneWallets: [],
      arkaneTestTokenBalance: null,
      testTokenAddress: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
      transferAmount: 0.01,
      transferDestination: '',
    }
  }

  componentDidMount = () => {
    this.initArkane()
  }

  initArkane = async () => {
    const options = {
      clientId: 'BattleRacers',
      environment: 'staging',
      signMethod: 'POPUP',
      //rpcUrl: `https://ropsten.infura.io/v3/${INFURA_KEY}`
    };
    this.arkane = window.Arkane
    const provider = await this.arkane.createArkaneProviderEngine(options)
    this.web3js = new Web3(provider)

    await this.getArkaneProfile()
    await this.getArkaneWallets()
  }

  getArkaneProfile = async () => {
    try {
      const arkaneProfile = await this.arkane.arkaneConnect().api.getProfile()
      this.setState({
        arkaneProfile
      })
    } catch (error) {
      console.error(error)
    }
  }

  getArkaneWallets = async () => {
    try {
      const arkaneWallets = await this.arkane.arkaneConnect().api.getWallets()
      await this.setState({
        arkaneWallets
      })
    } catch (error) {
      console.error(error)
    }
  }

  getTestTokenBalance = async () => {
    const { arkaneWallets, testTokenAddress } = this.state
    if (arkaneWallets.length === 0) {
      return
    }

    const tokenAddress = testTokenAddress.toLowerCase()
    try {
      const arkaneTestTokenBalance = await this.arkane.arkaneConnect().api
        .getTokenBalance(arkaneWallets[0].id, tokenAddress)
      this.setState({
        arkaneTestTokenBalance
      })
    } catch(error) {
      console.error(error)
      this.setState({
        arkaneTestTokenBalance: null
      })
    }
  }

  onTokenAddressChange = (e) => {
    const testTokenAddress = e.target.value
    this.setState({ testTokenAddress })
  }

  onTransferAmountChange = (e) => {
    const transferAmount = e.target.value
    this.setState({ transferAmount })
  }

  onTransferDestinationChange = (e) => {
    const transferDestination = e.target.value
    this.setState({ transferDestination })
  }

  onMakeTransfer = () => {
    const { testTokenAddress, transferAmount, transferDestination,
      arkaneWallets } = this.state

    if (arkaneWallets.length === 0) {
      return
    }

    const signer = this.arkane.arkaneConnect().createSigner()

    const walletId = arkaneWallets[0].id
    const secretType = arkaneWallets[0].secretType

    signer.executeTokenTransfer({
      walletId: walletId,
      to: transferDestination,
      value: transferAmount,
      tokenAddress: testTokenAddress,
      secretType: secretType,
    })
  }

  render() {
    const { arkaneProfile, arkaneWallets, arkaneTestTokenBalance,
      testTokenAddress, transferAmount, transferDestination } = this.state

    const wallets = arkaneWallets.map(w => (
      <div style={{ padding: '10px'}} key={w.id}>
        <div>Wallet {w.id}:</div>
        <div>address: {w.address}</div>
        <div>walletType: {w.walletType}</div>
        <div>secretType: {w.secretType}</div>
        <div>alias: {w.alias}</div>
        <div>description: {w.description}</div>
        <div>balance available: {w.balance.available.toString()}</div>
        <div>balance balance: {w.balance.balance}</div>
        <div>balance gasBalance: {w.balance.gasBalance}</div>
        <div>balance rawBalance: {w.balance.rawBalance}</div>
        <div>balance rawGasBalance: {w.balance.rawGasBalance}</div>
        <div>balance symbol: {w.balance.symbol}</div>
      </div>
    ))

    return (
      <div className="FAQ">
        <title>Arkane Test page</title>
        <section className="section-header header-main container-fluid"
          style={{height: '1000px'}}>
          <div className="container d-flex flex-column align-items-left justify-content-left h-200">
            <div style={{ padding: '10px 0px'}}>Arkane test page</div>
            <div>Account:</div>
            <div style={{ padding: '10px'}}>
              <div>userId: {arkaneProfile.userId}</div>
              <div>hasMasterPin: {arkaneProfile.hasMasterPin && arkaneProfile.hasMasterPin.toString()}</div>
              <div>username: {arkaneProfile.username}</div>
              <div>email: {arkaneProfile.email}</div>
              <div>firstName: {arkaneProfile.firstName}</div>
              <div>lastName: {arkaneProfile.lastName}</div>
            </div>
            <div>Wallets:</div>
            <div style={{ padding: '10px', display: 'flex', flexDirection: 'row'}}>
              {wallets}
            </div>
            <div>Token balance:</div>
            <div style={{ padding: '10px', display: 'flex', flexDirection: 'row'}}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '0.5em' }}>Token address:</div>
                <input
                  style={{ marginRight: '0.5em' }}
                  type="text"
                  onChange={this.onTokenAddressChange}
                  value={testTokenAddress}
                />
                <button onClick={this.getTestTokenBalance}>Get balance</button>
              </div>
            </div>
            {!!arkaneTestTokenBalance && (
              <>
                <div>balance: {arkaneTestTokenBalance.balance}</div>
                <div>rawBalance: {arkaneTestTokenBalance.rawBalance}</div>
                <div>symbol: {arkaneTestTokenBalance.symbol}</div>
                <div>transferable: {arkaneTestTokenBalance.transferable.toString()}</div>
                <div>type: {arkaneTestTokenBalance.type.toString()}</div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>Transfer:</div>
                  <input
                    style={{ margin: '0 0.5em', width: '64px' }}
                    type="text"
                    onChange={this.onTransferAmountChange}
                    value={transferAmount}
                  />
                  <div>to:</div>
                  <input
                    style={{ margin: '0 0.5em' }}
                    type="text"
                    onChange={this.onTransferDestinationChange}
                    value={transferDestination}
                  />
                  <button onClick={this.onMakeTransfer}>Go</button>
                </div>
              </>
            )}
          </div>
        </section>
        <button className="back-to-top" type="button" />
      </div>
    );
  }
}
