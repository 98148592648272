import React, { Component } from 'react'

export default class CrateAllowance extends Component {
  constructor(props) {
    super(props);

    this.defaultGrant = 1000;

    this.state = {
      isClosed: true,
      grantAmt: this.defaultGrant
    }

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  show(extras) {
    var grant = this.defaultGrant;
    this.grantAllowance = extras.grantAllowance;
    if (extras.grantAmount) grant = extras.grantAmount;

    this.setState(
      {
        isClosed: false,
        grantAmt: grant,
        crateName: extras.crateName
      });
  }

  hide() {
    this.setState({isClosed: true});
  }

  onChange(e) {
    this.setState({grantAmt: e.target.value});
  }

  handleSubmit(e) {
    e.preventDefault();
    this.grantAllowance(this.state.grantAmt);
    this.setState({grantAmt: this.defaultGrant});
    this.hide();
  }

  render() {
    return (
			<div onClick={this.hide} className={"row blur-modal " + (this.state.isClosed ? "d-none" : "d-flex flex-column align-items-center justify-content-center")}>
				<div className="modal-container vertical text-center" onClick={(e) => {e.stopPropagation();}}>
					<h3 className="h-blue mt-3">{this.state.crateName} Allowance</h3>
					<p>You'll need to grant us permission to open your crates and mint your parts. When your allowance runs out, we'll ask for your permission again.</p>
					<form>
  					<div className="d-flex flex-row align-items-center justify-content-center mt-5">
    					<p className="text-center fsize-1p5 ">Allow Altitude Games to open {this.state.grantAmt} {this.state.crateName}s</p>
  					</div>
  					<p className="mt-5">This will start a {window.web3.currentProvider.isMetaMask ? 'Metamask' : 'Wallet'} transaction.</p>
  					<button type="submit" onClick={this.handleSubmit} className="button-common mb-5 mt-3">Confirm</button>
					</form>
				</div>
			</div>
			);
  	}
}
