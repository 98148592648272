import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Loader from './Loader.js'

import '../styles/scss/InventoryPart.scss';

export default class ItemInventoryParts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metadata: props.part
    }
  }

  getName() {
    return this.state.metadata ? this.state.metadata.name : "";
  }

  getRarityClass() {
    if (!("rarity" in this.state.metadata.details)) {
      return "";
    }

    return this.state.metadata.details.rarity.toLowerCase();
  }

  render() {

    var metadata = this.state.metadata;
    var isElite = metadata.details && metadata.details.isElite;
    var renderElite;
    var renderImage;

    if (isElite) {
      renderElite = <div className="text-center d-flex parts-tag elite"><span>ELITE</span></div>;
    }

    if (metadata.image) {
      renderImage = <img className="card-img-top" id="partsImage" src={metadata.image} alt={metadata.name || ""} />
    }
    else {
      renderImage = <Loader />
    }
    return (
			<Link to={"/parts/" + metadata.id } className="InventoryPart col-sm-6 col-md-3 p-5px text-deco-none">
        <div className={"card list-inventory-item w-100 p-2 " + this.getRarityClass()} id="">
          <div className="d-flex flex-row flex-wrap">
            {/*}<h5 id="partNumber" className="mb-0">#{metadata.id}</h5>*/}
          </div>
          {/* TEST <img src={require("../images/test/BeetleCasing00C01.png")} alt="" />*/}
          {renderImage}
          <div className={`w-100${ this.props.status !== "" ? ` status-claiming ${this.props.status}` : '' }`}> 
            <div className="frame">
              <span className="tip"><strong>{ this.props.status === "claiming" ? 'CLAIMING' : 
                (this.props.status === "claim" ? 'CLAIM NOW!' : 
                  (this.props.status === "queued" ? 'QUEUED' : '' ) ) }</strong>
              </span>
              <span className="tip-content-part-status tip-content-part-status-claiming">Refers to the car part that is ready to be withdrawn from the bridge between the Matic and Ethereum networks. Claiming a part counts as a MetaMask transaction with corresponding gas fees.</span>
              <span className="tip-content-part-status tip-content-part-status-queued">Refers to car parts that are still pending transfer until the part that is ready to be claimed is successfully transferred.</span>
              <div className="styling left"></div>
              <div className="styling right"></div>
            </div>
          </div>
          <div className="card-body text-center d-flex flex-column align-items-center">
            <div className="part-name">
              <h5 className="card-title font-30px d-inline mb-2">#{metadata.id} {metadata.name || ""}</h5>
            </div>
            <div className="d-flex flex-row parts-tag-group align-items-center justify-content-center">
              {renderElite}
              <div className="text-center d-flex parts-tag prime"><span>{metadata.details.edition}</span></div>
            </div>
            {/*<div className={"text-center d-flex rarity-tag " + this.getRarityClass() }><span>{metadata.details.rarity}</span></div>*/}
          </div>
        </div>
          
      </Link>
            );
  }
}
