import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import axios from 'axios';
import ethUtil from 'ethereumjs-util';
import Web3 from 'web3';

require('@arkane-network/web3-arkane-provider')

const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

const rawMessage = 'Some message';
var msg = ethUtil.bufferToHex(new Buffer(rawMessage, 'utf8'));
var address;
var signParams
var msgParams;
var web3JS = window.web3;

export default class LoginPrompt extends Component {
  constructor(props) {
    super(props);
    this.handleSignature = this.handleSignature.bind(this);
      this.web3Login = this.web3Login.bind(this);
      this.web3LoginAsync = this.web3LoginAsync.bind(this);
      this.handleSignatureAsync = this.handleSignatureAsync.bind(this);
    this.state = {
      isLogin: false
    }
     this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }

    address = this.props.web3state.metamaskAccount;
  }

  async handleSignature(err, signed) {
     if (!err) {
        var s = signed;
        let bigAddress = this.props.web3state.metamaskAccount
        let result = await axios.post(this.apiUrl + '/login', { address: address, msg: signParams, signed: s, bigAddress: bigAddress});
        localStorage.token = result.data.server_sig;
        //window.location = '/inventory';
        this.props.history.push('/inventory')
      }
  }

  async handleSignatureAsync(err, signedResult, sigType = 'typed') {
    if (!err) {
      let bigAddress = this.props.web3state.metamaskAccount
      const signed = signedResult.result
      console.log(`signed: ${signed}`);
      let result = null
      const web3 = new Web3(window.web3.currentProvider)
      let msg = msgParams

      if (sigType === 'simple') {
        msg = web3.utils.asciiToHex(msgParams)
      }

      try {
        result = await axios.post(this.apiUrl + '/login', {
          address: address,
          msg,
          signed,
          bigAddress: bigAddress,
          type: sigType
        });
      } catch (err) {
        console.error(err)
        return
      }

      localStorage.token = result.data.server_sig;
      this.props.history.push('/inventory')
    }

  }
  async web3Login() {
    msg =   await axios.get(this.apiUrl + '/getHash');
    msg = msg.data.hash;
    if (web3JS) {
      address = web3JS.currentProvider.selectedAddress
      await web3JS.personal.sign(msg, address, this.handleSignature);
    }
  }
  
  async web3LoginAsync() {
    const bigAddress = this.props.web3state.metamaskAccount
    const response = await axios.get(this.apiUrl + '/getHash', {params:{address: bigAddress}});
    address = window.web3.utils.toHex(bigAddress);
    signParams = [{type: 'string', name: 'Message', value: response.data.message},
                      {type: 'string', name: 'Expiration', value: response.data.date},
                      {type: 'string', name: 'Secret', value: response.data.hash}];
    const network = await window.web3.eth.net.getNetworkType();

    msgParams = JSON.stringify({
                    types: {
                            EIP712Domain:[
                              {name:"name",type:"string"},
                              {name:"network",type:"string"}
                            ],
                            Message: [{name:'message', type: 'string'},
                                      {name:'expiration', type: 'string'},
                                      {name:'secret', type: 'string'}]
                            },
                    primaryType: 'Message',
                    domain:{name:"Battle Racers",network:network},

                    message:{message: response.data.message,
                            expiration: response.data.date,
                            secret: response.data.hash}});

    const from = address;
    const params = [from, msgParams];
    if (window.web3.currentProvider.isMetaMask) {
      const method = 'eth_signTypedData_v3';
      await window.web3.currentProvider.sendAsync({
        method,
        params,
        from,
      }, async (err, result) => {
        if(err){
          const errMessage = err.message.toLowerCase();
          const denied = errMessage.includes("user denied message signature") || errMessage.includes("cancelled");

          if(!denied){
            const personalSignParams = [msgParams, from];
            await window.web3.currentProvider.sendAsync({
              method: 'personal_sign',
              params: personalSignParams,
              from,
            }, (err2, result) => {
              this.handleSignatureAsync(err2, result, 'simple')
            });
          } else {
            console.log("Denied Signing: ", err.message);
          }
        } else {
          this.handleSignatureAsync(err, result);
        }
      })
      
    } else {
      const arkaneConnect = window.Arkane.arkaneConnect()
      const signer = arkaneConnect.createSigner()
      const arkaneWallets = await arkaneConnect.api.getWallets()
      const arkaneWallet = arkaneWallets[0]
      let result = null
      try {
        result = await signer.signMessage({
            walletId: arkaneWallet.id,
            secretType: 'ETHEREUM',
            data: msgParams
        })
      } catch (err) {
        console.error(err)
        return
      }
      this.handleSignatureAsync(null, { result: result.result.signature }, 'simple')
    }
  }

  render() {
    return (
      <div>
        <div className="login-prompt container text-center d-flex flex-column align-items-center justify-content-center">
            <h1 className="mb-4">Please login to access Battle Racers with your ETH wallet.</h1>
            <Link to="/login">
              <button className="button-common mb-4" onClick={this.web3LoginAsync}>LOGIN</button>
            </Link>
            <p className="text-gray">You will need to sign a transaction to continue.</p>
          </div>
      </div>
    );
  }
}
