import React, {Component} from 'react';
import Web3 from 'web3';
import axios from 'axios';

import withWeb3Alert from './../Web3Alert';

import CheckBox from './CheckBox';
import '../../styles/scss/AlpacaTokenStatus.scss';
import PartJson from '../../contracts/Part.json'

const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || "localhost";
const SERVER_PORT = process.env.REACT_APP_API_PORT || process.env.REACT_APP_SERVER_PORT || 0;
const REACT_APP_ADMIN_ADDRESS_LIST = process.env.REACT_APP_ADMIN_ADDRESS_LIST || "";
const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;
const DEBUG_MODE = (process.env.REACT_APP_DEBUG_MODE === 'true') ;

class AlpacaTokenStatus extends Component {
  constructor(props) {
    super(props);

    this.apiUrl = window.location.protocol + "//" + SERVER_HOST;

    if (SERVER_PORT) {
      this.apiUrl = this.apiUrl + ":" + SERVER_PORT.toString() + "/api";
    }
    if (DEBUG_MODE) {
      this.apiUrl = window.location.protocol + "//" + SERVER_HOST;
      this.apiUrl = this.apiUrl + "/api";
    }
    this.state = {
      account:'',
      renderListDOM:[],
      itemList:[],
      stateToRender:"all",
      proccessResponseTxt:"",
      disable: '',
      chainIdToRender: "all",
      searchedId: '',
      isDryRunMode: true,
      isLogin: true,
      loginAddress:'',
      tokenListWatingTxt: '',
      mainChecker: '',
    };
    this.checkedItems = [];
    this.forceCheckedItems = [];
    this.partsInReady = [];
    this.partsInTransit = [];
    this.partsInApproval = [];
    this.loadTokenList = this.loadTokenList.bind(this);
    this.onChangeUserWallet = this.onChangeUserWallet.bind(this);
    this.drawList = this.drawList.bind(this);
    this.stateSelectionHandler = this.stateSelectionHandler.bind(this);
    this.chainSelectionHandler = this.chainSelectionHandler.bind(this);
    this.processHandler = this.processHandler.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.dryRunModeHandler = this.dryRunModeHandler.bind(this);
    this.loginHandler = this.loginHandler.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onAllCheckHandler = this.onAllCheckHandler.bind(this);
    this.getAllCheckItems = this.getAllCheckItems.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.checkBoxes = [];

  }
  loginHandler() {
    var listOfAddress = REACT_APP_ADMIN_ADDRESS_LIST.split(',');
    for (var i = 0; i < listOfAddress.length; i += 1) {
      if (listOfAddress[i].toLowerCase() === this.state.loginAddress.toLowerCase()) {
        this.setState({isLogin: true});
        return;
      }
    }
    alert('user not found');
  }
  onChangeAddress(e) {
    this.setState({loginAddress: e.target.value});
  }
  async loadTokenList() {

    this.disableButton();
    this.setState({itemList: [], renderListDOM: [], tokenListWatingTxt: 'please, wait.'});
    this.partsInReady = [];
    this.partsInTransit = [];
    this.partsInApproval = [];
    this.setState({stateToRender: "all"});
    try {
      var address = Web3.utils.toChecksumAddress(this.state.account);
      var requestUrl = this.apiUrl + "/getItemOwned";
      var r = await axios.get(requestUrl, {params: {address: address} });
      for (var x = 0 ; x < r.data.partsInReady.length; x += 1) {
        this.partsInReady.push(r.data.partsInReady[x])
      }
      for (var y = 0 ; y < r.data.partsInTransit.length; y += 1) {
        this.partsInTransit.push(r.data.partsInTransit[y])
      }
      for (var z = 0 ; z < r.data.partsInApproval.length; z += 1) {
        this.partsInApproval.push(r.data.partsInApproval[z])
      }
      this.drawList();
    } catch (e) {
      alert('please enter the correct account address')
      this.enableButton();
    }
    this.setState({tokenListWatingTxt: ''});
  }
  async processHandler() {
    this.checkedItems = this.getAllCheckItems();
    if (this.checkedItems.length < 1) {
      alert("no item to proccess");
      return;
    }
    this.disableButton();
    this.setState({proccessResponseTxt: ""})
    var text = "started \n \n"
    var address = Web3.utils.toChecksumAddress(this.state.account);
    for (var i = 0; i < this.checkedItems.length; i += 1) {
       text += this.state.account + " : token id: " + this.checkedItems[i].id + " proccessing....\n";

       var tokenId = this.checkedItems[i].id;
       this.setState({proccessResponseTxt: text})
       var resp = await axios.get(this.apiUrl + '/processPartTransfer', {params: {
         address,
         tokenId: tokenId,
         dryRunMode: this.state.isDryRunMode
       }});

       text += resp.data.response + ' \n \n';

    }
    text += 'finished';
    this.setState({proccessResponseTxt: text})
    this.enableButton();
    this.setState({itemList: []});
    this.setState({renderListDOM: []});
    if (!this.state.isDryRunMode) {
      await this.loadTokenList();
    }
    setTimeout(() => {
      this.drawList();
      this.checkedItems = [];
      this.setState({itemSelectedCount: this.checkedItems.length, isDryRunMode: true})
    }, 50)

  }
  enableButton() {
    this.setState({disable: ''})
  }
  disableButton() {
    this.setState({disable: 'disabled'})
  }
  stateSelectionHandler(e) {
    var value = e.target.value;
    if (value === "ready") {
      this.setState({stateToRender: "READY"});
    }
    if (value === "transit") {
      this.setState({stateToRender: "TRANSIT"});
    }
     if (value === "approval") {
      this.setState({stateToRender: "APPROVAL"});
    }
    if (value === "all") {
      this.setState({stateToRender: "all"});
    }
    setTimeout(() => {
      this.drawList();
    }, 100)
  }
  chainSelectionHandler(e) {
    var value = e.target.value;
    if (value === "loom") {
      this.setState({chainIdToRender: "LOOM"});
    }
     if (value === "eth") {
      this.setState({chainIdToRender: "ETH"});
    }
    if (value === "all") {
      this.setState({chainIdToRender: "all"});
    }
    setTimeout(() => {
      this.drawList();
    }, 100)
  }
  dryRunModeHandler(e) {
    this.setState({isDryRunMode: e.target.checked});
  }
  onChangeUserWallet(e) {
    this.setState({account: e.target.value});
  }
  componentDidMount() {
   // $(".nav-link")[0].click();
  }
  onAllCheckHandler(e) {
   for (var i = 0; i < this.checkBoxes.length; i += 1) {
      if (e.target.checked) {
        this.setState({mainChecker: 'checked'})
        this.checkBoxes[i].setState({isChecked: 'checked'})
        //this.checkedItems.push(this.checkBoxes[i].state.value);
      } else {
        this.setState({mainChecker: ''})
        this.checkBoxes[i].setState({isChecked: ''})
        //this.checkedItems = [];
      }
   }
  }
  onCheckChange() {
    var isCheckedAll = false;
    setTimeout(() => {
      for (var i = 0; i < this.checkBoxes.length; i += 1) {
        if (this.checkBoxes[i].state.isChecked === 'checked') {
          isCheckedAll = true;
        } else {
          isCheckedAll = false;
          break;
        }
      }
      if (isCheckedAll) {
        this.setState({mainChecker: 'checked'})
      } else {
       this.setState({mainChecker: ''})
      }
    }, 50);

  }
  getAllCheckItems() {
    var listOfItems = [];
    var filteredCheckedBox = this.checkBoxes.filter(function(i) {
      return (i['state']['isChecked'] !== '');
    });
    for (var i = 0; i < filteredCheckedBox.length; i += 1) {
      listOfItems.push(JSON.parse(filteredCheckedBox[i].state.value));
    }
    return listOfItems;
  }
  onChangeSearch(e) {
    this.setState({searchedId: e.target.value});
    setTimeout(() => {
      this.drawList();
    }, 100)
  }

  getOpenseaUrl(id) {
    var netId = window.web3.currentProvider.networkVersion;
    var contractAddress = PartJson.networks[netId].address;
    var url = OPENSEA_URL + contractAddress.toLowerCase() + "/" + id;
    return url;
  }
  drawList() {
    var cont = []
    var items = []
    var allItems = this.partsInReady.concat(this.partsInTransit).concat(this.partsInApproval);
    var filterByState = allItems.filter((function(i) {
      if (this.state.stateToRender === 'all') return i;
      return i['state'] === this.state.stateToRender;
    }).bind(this));
    var filterByChain = filterByState.filter((function(i) {
      if (this.state.chainIdToRender === 'all') return i;
      return i['chain'] === this.state.chainIdToRender;
    }).bind(this));
    var filterById = filterByChain.filter((function(i) {
      if (this.state.searchedId === '') return i;
      return  i['id'].toString().indexOf(this.state.searchedId) > -1;  // i['id'] === this.state.searchedId;
    }).bind(this));
    this.checkBoxes = [];
    for (var i = 0; i < filterById.length; i += 1) {
      items.push(filterById[i]);
      cont.push(<div className='divTableRow' key={filterById[i].id}>
        <div className="divTableCell" > <CheckBox props={this.props} onChange={this.onCheckChange} index={i} value={JSON.stringify(filterById[i])} ref={(c) => {this.checkBoxes.push(c) }}/> </div>
        <div className='divTableCell'>
          <a className='tooltiplink' target='popup'  href={this.getOpenseaUrl(filterById[i].id)} > {filterById[i].id}
            <span className="tooltiptext">View on OpenSea</span>
          </a>
        </div>
        <div className='divTableCell'>{filterById[i].name}</div>
        <div className='divTableCell'>{filterById[i].state}</div>
        <div className='divTableCell'>{filterById[i].chain}</div>
      </div>)
    }
    this.setState({itemList: items});
    this.setState({renderListDOM: cont})
    this.enableButton();
  }

  renderLogin() {

  }
  render() {
    return (
      <div className="Alpaca container">
        <h5>Hello, {this.props.web3state.metamaskAccount}!</h5>
        <form className="d-block" onSubmit={this.onSubmit}>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">User Wallet</span>
            </div>
            <input type="text" className="form-control" value={this.state.account} onChange={this.onChangeUserWallet} />
            <div className="input-group-append">
              <button disabled={this.state.disable} onClick={this.loadTokenList} className="btn btn-outline-secondary"
                type="button">
                Load
              </button>
            </div>
          </div>
        </form>

        <div id="options-cont">
          <div className="options-cont-child-1">
            <span>state: </span>
            <select disabled={this.state.disable} onChange={this.stateSelectionHandler}>
              <option value="all">All</option>
              <option value="ready">ready</option>
              <option value="transit">transit</option>
              <option value="approval">approval</option>
            </select>
          </div>
          <div className="options-cont-child-2">
            <span>chain: </span>
            <select disabled={this.state.disable} onChange={this.chainSelectionHandler}>
              <option value="all">All</option>
              <option value="eth">eth</option>
              <option value="loom">loom</option>
            </select>
          </div>
          <div className="options-cont-child-3">
            <span>token id: </span>
            <input disabled={this.state.disable} value={this.state.searchedId} onChange={this.onChangeSearch}/>
          </div>
          <div className="options-cont-child-4">
          <input disabled={this.state.disable} onChange={this.dryRunModeHandler} checked={this.state.isDryRunMode} type="checkbox"/>
          <span>dry run mode</span>
          </div>
        </div>

        <div className="divTable label">
          <div className="divTableBody">
            <div className="divTableRow">
              <div className="divTableCell"><input checked={this.state.mainChecker} onChange={this.onAllCheckHandler} type="checkbox" /></div>
              <div className="divTableCell">token id</div>
              <div className="divTableCell">item name</div>
              <div className="divTableCell">state</div>
              <div className="divTableCell">chain</div>
            </div>
          </div>
        </div>
       <div className='divTb'>
          <div className="divTable">
            <div className="divTableBody">
            {this.state.itemList.length > 0  || this.state.disable? this.state.renderListDOM: <div className="empty"> <span> Empty</span></div>}
            <div className="empty"> <span> {this.state.tokenListWatingTxt}</span></div>
            </div>
          </div>
        </div>
        <button disabled={this.state.disable } className="defaultBtn" onClick={this.processHandler}> PROCESS </button>

      <textarea  style={{width: "100%", height: "300px"}} value={this.state.proccessResponseTxt} onChange={()=>{}}>
      </textarea>

      </div>

    );

  }
}

export default withWeb3Alert(AlpacaTokenStatus);
